import Vue from "vue";
import router from "./router.js";
import axios from "axios";
import App from "./App.vue";
import ElementUI from "element-ui";
import { Message } from "element-ui";
import querystring from "querystring";
import moment from "moment";
import QrcodeV2 from "qrcode-v2";
import "element-ui/lib/theme-chalk/index.css";
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(QrcodeV2);
Vue.prototype.isLoginPage = false;
Vue.config.productionTip = false;
Vue.prototype.$querystring = querystring;
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;
Vue.prototype.$imgBaseUrl = process.env.NODE_ENV == 'production' ? 'https://qidiantec.com/www/imgs/' : 'http://uat-test.qidiantec.com:90/www/imgs/'
console.log(process.env.NODE_ENV)
Vue.prototype.$headers = {
  token: window.localStorage.getItem("token"),
};
let smFlag = false;
if (window.innerWidth < 1000) {
  smFlag = true;
}
window.addEventListener('resize', handleResize)
function handleResize() {
  console.log('窗口变')
  smFlag = false;
  if (window.innerWidth < 1000) {
    smFlag = true;
  }
}
Vue.prototype.smFlag = smFlag; // 小屏幕标识
axios.defaults.baseURL = "/omp";
axios.withCredentials = true;
axios.timeout = 180000;
/**拦截器统*/
axios.interceptors.request.use(
  (config) => {
    let token = window.localStorage.getItem("token");
    if (token) {
      // Bearer
      config.headers["token"] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
/**拦截器验证token失效以及接口错误信息 */
axios.interceptors.response.use(
  (response) => {
    if (response.status == 500) {
      Message.closeAll();
      Message.error("服务错误");
    }
    // if (response.status == 401) {
    //   Message.closeAll();
    //   Message.error("令牌过期");
    //   router.push("/login");
    // }
    // if (
    //   response.status == 200 &&
    //   response.data.code &&
    //   response.data.code != 200
    // ) {
    //   Message.closeAll();
    //   // 接口错误给出错误提示
    //   Message.error(response.data.msg);
    //   if (response.data.code == 401) {
    //     router.push("/login");
    //   }
    // }
    return response;
  },
  (error) => {
    // debugger
    console.log("error" + error);
    return Promise.reject(error); // 返回接口返回的错误信息
  }
);
var navBarIndex = 1;
var pagesArr = [
  "首页",
  "产品",
  "解决方案",
  "服务支持",
  "下载",
  "关于我们",
  "登录网页版",
];
Vue.prototype.$platformType = "Android"
const userAgent = navigator.userAgent;
if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
  Vue.prototype.$platformType = "IOS";
}
// 全局前置守卫
router.beforeEach((to, from, next) => {
  console.log(to.path);
  if (to.path.includes("index")) {
    navBarIndex = 1;
  }
  if (to.path.includes("productPage")) {
    navBarIndex = 2;
  }
  if (to.path.includes("solutionPage")) {
    navBarIndex = 3;
  }
  if (to.path.includes("servicePage")) {
    navBarIndex = 4;
  }
  if (to.path.includes("downloadPage")) {
    navBarIndex = 5;
  }
  if (to.path.includes("aboutPage")) {
    navBarIndex = 6;
  }
  if (to.path.includes("loginWebPage")) {
    navBarIndex = 7;
  }
  console.log('菜单下标1：', navBarIndex)
  Vue.prototype.navBarIndex = navBarIndex;
  Vue.prototype.currentPage = pagesArr[navBarIndex - 1];
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
  localStorage.setItem('currentPage', pagesArr[navBarIndex - 1])
  next();
});
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
