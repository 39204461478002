<template>
  <div>
    <div
      ref="productIndex"
      @scroll="handleScroll"
      class="product-background product-index"
      :class="
        smFlag
          ? 'product-background-mini' + ' product-background' + activeIndex
          : 'product-background' + activeIndex
      "
    >
      <h1></h1>
      <h2
        class="title"
        style="display: flex; justify-content: center; align-items: center"
      >
        <span
          style="margin-left: 10px; letter-spacing: 6px; font-weight: bold"
        ></span>
      </h2>
      <template v-if="activeIndex != 0 && activeIndex != 3">
        <h1>{{ banner.title }}</h1>
        <h2
          class="title"
          style="display: flex; justify-content: center; align-items: center"
        >
          <span
            style="margin-left: 10px; letter-spacing: 6px; font-weight: bold"
            >{{ banner.decs }}</span
          >
        </h2>
      </template>
    </div>
    <div class="scroll-head" :class="headFixed ? 'fixedhead' : ''">
      <div class="tabs" :class="smFlag ? 'tabs-mini' : ''">
        <a
          href="javascript:void(0)"
          v-for="(item, i) in tabs"
          :key="i"
          class="tabs-item"
          :class="activeIndex == i ? 'active' : ''"
          @click="tabChange(item, i)"
        >
          {{ item.name }}
        </a>
      </div>
    </div>
    <div>
      <div id="a1" ref="a1" v-if="activeIndex == 0">
        <div
          class="introduction qd-conent introduction-index"
          :class="smFlag ? 'introduction-mini' : ''"
        >
          <div class="introduction-left">
            <div class="introduction-left-list">
              <div
                :class="
                  i == 0
                    ? 'introduction-features-title'
                    : 'introduction-features-list'
                "
                v-for="(item, i) in textList"
                :key="i"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="introduction-right">
            <div class="introduction-title">系统简介</div>
            <ul class="introduction-list">
              <li class="introduction-list-item">
                一款为<i>建筑类企业</i>量身打造的
                <u class="item-u">PO链式全时自动化</u>管控系统；
              </li>
              <li class="introduction-list-item">
                集数十项轻型管理ERP系统于一身，轻松自如；
              </li>
              <li class="introduction-list-item">
                精准把控建筑行业的需求和核心痛点，想你所想，替你出招；
              </li>
              <li class="introduction-list-item">
                满足行业大部分生产场景的管理需求，小体积，大能量；
              </li>
              <li class="introduction-list-item">
                预设建筑行业应用场景标准流程，拿来即用；
              </li>
            </ul>
          </div>
        </div>
        <div
          class="function index-function"
          :class="smFlag ? 'index-function-mini' : ''"
        >
          <div class="qd-conent">
            <div class="qd-title">核心功能</div>
            <div class="function-conent">
              <ul class="function-conent-list">
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-0.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">云端管控</div>
                    <p class="item-conent-description">
                      将老板从管理实务中解放出来解除时间和空间的限制
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-1.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">自动管理</div>
                    <p class="item-conent-description">
                      实现企业和类企业组织全时自动化管理管控
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-2.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">内外监管</div>
                    <p class="item-conent-description">
                      全面实现企业对内对外无纸化办公和一体化管控
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-3.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">成本在线</div>
                    <p class="item-conent-description">
                      企业各项生产经营和项目成本实时动态账本在线管理
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-4.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">实时报表</div>
                    <p class="item-conent-description">
                      企业的各项生产经营数据实时动态报表在线管理
                    </p>
                  </div>
                </li>
              </ul>
              <ul class="function-conent-list">
                <li class="function-conent-list-item">
                  <img :src="`${$imgBaseUrl}function-icon-5.png`" class="item-icon" />
                  <div class="item-conent">
                    <div class="item-conent-name">消除猫腻</div>
                    <p class="item-conent-description">
                      杜绝所有岗位的权利寻租和贪腐等损害企业利益的行为
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img :src="`${$imgBaseUrl}function-icon-6.png`" class="item-icon" />
                  <div class="item-conent">
                    <div class="item-conent-name">资源供给</div>
                    <p class="item-conent-description">
                      为企业提供充足的生产经营所需的各类优质资源信息
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img :src="`${$imgBaseUrl}function-icon-7.png`" class="item-icon" />
                  <div class="item-conent">
                    <div class="item-conent-name">风采展示</div>
                    <p class="item-conent-description">
                      为企业和类企业组织提供展示的平台获取更多的业务订单
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img :src="`${$imgBaseUrl}function-icon-8.png`" class="item-icon" />
                  <div class="item-conent">
                    <div class="item-conent-name">权责清晰</div>
                    <p class="item-conent-description">
                      自动化链式工作路径，责任传递清晰，执行力大幅提升
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img :src="`${$imgBaseUrl}function-icon-9.png`" class="item-icon" />
                  <div class="item-conent">
                    <div class="item-conent-name">开疆辟土</div>
                    <p class="item-conent-description">
                      消除建筑类企业因资源瓶颈而形成的区域限制，轻松走向全国
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="worth" :class="smFlag ? 'worth-mini' : ''">
          <div data-v-5fec10f4="" class="qd-conent">
            <div data-v-5fec10f4="" class="qd-title">产品价值</div>
            <ul data-v-5fec10f4="" class="worth-list">
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">管理升级</div>
                <div data-v-5fec10f4="" class="item-description">
                  让中小微企业轻松实现以云计算为基础的数字化管理管控模式
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">性价比</div>
                <div data-v-5fec10f4="" class="item-description">
                  实现企业数字化管理的成本仅相当于一名基层普通员工的工资
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">系统部署</div>
                <div data-v-5fec10f4="" class="item-description">
                  即开即用，无需任何硬件设备和运维人员的投入
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">操作应用</div>
                <div data-v-5fec10f4="" class="item-description">
                  保证只有小学文化程度的施工管理人员也能轻松学会操作
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">团队协同</div>
                <div data-v-5fec10f4="" class="item-description">
                  以人为中心和系统完美融合，实现管理敏捷化
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top no-active">
                <div data-v-5fec10f4="" class="item-title"></div>
                <div data-v-5fec10f4="" class="item-description"></div>
              </li>
              <li
                data-v-5fec10f4=""
                class="worth-list-item item-bottom no-active"
              >
                <div data-v-5fec10f4="" class="item-title"></div>
                <div data-v-5fec10f4="" class="item-description"></div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">生产管理</div>
                <div data-v-5fec10f4="" class="item-description">
                  以数据信息和成本管控为重心，实现生产敏捷化
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">管理转变</div>
                <div data-v-5fec10f4="" class="item-description">
                  由系统全时自动化管理取代传统的人力管理，不受时间和空间的限制
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">企业资源</div>
                <div data-v-5fec10f4="" class="item-description">
                  将企业所有分散的线下资源集中到线上实时动态管理
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">成本管控</div>
                <div data-v-5fec10f4="" class="item-description">
                  系统能够对建筑产品成本费用散、乱、漏、耗等痛点进行全周期的管控
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">结果导向</div>
                <div data-v-5fec10f4="" class="item-description">
                  管理平台具有公开、公平公正的特点，且执行均以结果为导向。
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="features index-features-work"
          :class="smFlag ? 'index-features-work-mini' : ''"
        >
          <div data-v-370cb5f0="" class="qd-conent">
            <div data-v-370cb5f0="" class="qd-title">
              <span data-v-370cb5f0="">"办公"</span>
              功能介绍
            </div>
            <div data-v-370cb5f0="" class="features-tab">
              <div class="features-tab-head">
                <ul class="tab-list">
                  <li
                    class="tab-item"
                    :class="features_tabs_index == i ? 'active' : ''"
                    v-for="(item, i) in features_tabs"
                    :key="i"
                    @click="features_tabs_change(item, i)"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
              <div class="features-tab-cen">
                <div class="features-tab-conent" v-if="smFlag">
                  <img class="features-tab-conent-bg" :src="`${$imgBaseUrl}features-work-title1.png`" />
                  <div class="features-tab-conent-main">
                    <div class="features-tab-name">
                      {{ features_tabs_obj.name }}
                    </div>
                    <p class="features-tab-description">
                      {{ features_tabs_obj.decs }}
                    </p>
                  </div>
                </div>
                <img
                  :src="features_tabs_obj.imgUrl1"
                  class="features-tab-img"
                />
                <img
                  :src="features_tabs_obj.imgUrl2"
                  class="features-tab-img"
                />
                <div class="features-tab-conent" v-if="!smFlag">
                  <div class="features-tab-name">
                    {{ features_tabs_obj.name }}
                  </div>
                  <p class="features-tab-description">
                    {{ features_tabs_obj.decs }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="smFlag ? 'index-features-work-mini' : ''" class="features">
          <div data-v-370cb5f0="" class="qd-conent">
            <div data-v-370cb5f0="" class="qd-title">
              <span data-v-370cb5f0="">"管控"</span>
              功能介绍
            </div>
            <div data-v-370cb5f0="" class="features-tab is-control">
              <div class="features-tab-head">
                <ul class="tab-list">
                  <li
                    class="tab-item"
                    :class="features_tabs_index2 == i ? 'active' : ''"
                    v-for="(item, i) in features_tabs2"
                    :key="i"
                    @click="features_tabs_change2(item, i)"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
              <div class="features-tab-cen">
                <!---->
                <!---->
                <template v-if="smFlag">
                  <div class="features-tab-conent">
                    <img class="features-tab-conent-bg" :src="`${$imgBaseUrl}features-tab-control1.png`" />
                    <div class="features-tab-conent-main">
                      <div class="features-tab-name">
                        {{ features_tabs_obj2.name }}
                      </div>
                      <p class="features-tab-description">
                        {{ features_tabs_obj2.decs }}
                      </p>
                    </div>
\
                  </div>
                </template>
                <template v-else>
                  <div class="features-tab-conent">
                    <div class="features-tab-name">
                      {{ features_tabs_obj2.name }}
                    </div>
                    <p class="features-tab-description">
                      {{ features_tabs_obj2.decs }}
                    </p>
                  </div>
                </template>
                <img
                  :src="features_tabs_obj2.imgUrl1"
                  class="features-tab-img"
                />
                <img
                  :src="features_tabs_obj2.imgUrl2"
                  class="features-tab-img"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="slogan is-index" :class="smFlag ? 'slogan-mini' : ''">
          <div data-v-2623df46="" class="slogan-title">
            让云计算和大数据在管理中的应用不再只是大企业的专利
          </div>
          <a href="javascript:void(0)" class="" @click="toPath('/solutionPage', 3, '解决方案')"
            ><button
              data-v-2623df46=""
              type="button"
              class="el-button el-button--default is-round"
            >
              <span>解决方案</span>
            </button></a
          >
        </div>
      </div>
      <div id="a2" ref="a2" v-if="activeIndex == 1">
        <div
          class="introduction qd-conent introduction-index introduction-serve"
          :class="smFlag ? 'introduction-mini' : ''"
        >
          <div class="introduction-left introduction-left2">
            <div class="introduction-left-list">
              <div
                :class="
                  i == 0
                    ? 'introduction-features-title'
                    : 'introduction-features-list'
                "
                v-for="(item, i) in textList2"
                :key="i"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="introduction-right">
            <div class="introduction-title">系统简介</div>
            <ul class="introduction-list">
              <li class="introduction-list-item">
                一款为<i>建筑类企业</i>量身打造的
                <u class="item-u">PO链式全时自动化</u>管控系统；
              </li>
              <li class="introduction-list-item">
                集数十项轻型管理ERP系统于一身，轻松自如；
              </li>
              <li class="introduction-list-item">
                精准把控建筑行业的需求和核心痛点，想你所想，替你出招；
              </li>
              <li class="introduction-list-item">
                满足行业大部分生产场景的管理需求，小体积，大能量；
              </li>
              <li class="introduction-list-item">
                预设建筑行业应用场景标准流程，拿来即用；
              </li>
            </ul>
          </div>
        </div>

        <div class="worth" :class="smFlag ? 'worth-mini' : ''">
          <div data-v-5fec10f4="" class="qd-conent">
            <div data-v-5fec10f4="" class="qd-title">产品价值</div>
            <ul data-v-5fec10f4="" class="worth-list">
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">管理升级</div>
                <div data-v-5fec10f4="" class="item-description">
                  让中小微企业轻松实现以云计算为基础的数字化管理管控模式
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">性价比</div>
                <div data-v-5fec10f4="" class="item-description">
                  实现企业数字化管理的成本仅相当于一名基层普通员工的工资
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">系统部署</div>
                <div data-v-5fec10f4="" class="item-description">
                  即开即用，无需任何硬件设备和运维人员的投入
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">操作应用</div>
                <div data-v-5fec10f4="" class="item-description">
                  保证只有小学文化程度的施工管理人员也能轻松学会操作
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top">
                <div data-v-5fec10f4="" class="item-title">团队协同</div>
                <div data-v-5fec10f4="" class="item-description">
                  以人为中心和系统完美融合，实现管理敏捷化
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-top no-active">
                <div data-v-5fec10f4="" class="item-title"></div>
                <div data-v-5fec10f4="" class="item-description"></div>
              </li>
              <li
                data-v-5fec10f4=""
                class="worth-list-item item-bottom no-active"
              >
                <div data-v-5fec10f4="" class="item-title"></div>
                <div data-v-5fec10f4="" class="item-description"></div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">生产管理</div>
                <div data-v-5fec10f4="" class="item-description">
                  以数据信息和成本管控为重心，实现生产敏捷化
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">管理转变</div>
                <div data-v-5fec10f4="" class="item-description">
                  由系统全时自动化管理取代传统的人力管理，不受时间和空间的限制
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">企业资源</div>
                <div data-v-5fec10f4="" class="item-description">
                  将企业所有分散的线下资源集中到线上实时动态管理
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">成本管控</div>
                <div data-v-5fec10f4="" class="item-description">
                  系统能够对建筑产品成本费用散、乱、漏、耗等痛点进行全周期的管控
                </div>
              </li>
              <li data-v-5fec10f4="" class="worth-list-item item-bottom">
                <div data-v-5fec10f4="" class="item-title">结果导向</div>
                <div data-v-5fec10f4="" class="item-description">
                  管理平台具有公开、公平公正的特点，且执行均以结果为导向。
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="slogan is-index" :class="smFlag ? 'slogan-mini' : ''">
          <div
            data-v-2623df46=""
            class="slogan-title"
            style="letter-spacing: 3px; padding: 0 15px"
          >
            企点帮帮是一款专为建筑从业者量身打造的口袋应用，帮你都在点子上！
          </div>
        </div>
      </div>
      <div id="a3" ref="a3" v-if="activeIndex == 2">
        <div
          class="introduction qd-conent introduction-index introduction-business"
          :class="smFlag ? 'introduction-mini' : ''"
        >
          <div class="introduction-left introduction-left3">
            <div class="introduction-left-list">
              <div
                :class="
                  i == 0
                    ? 'introduction-features-title'
                    : 'introduction-features-list'
                "
                v-for="(item, i) in textList3"
                :key="i"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="introduction-right">
            <div class="introduction-title">系统简介</div>
            <ul data-v-09303b08="" class="introduction-list">
              <li data-v-09303b08="" class="introduction-list-item">
                <u class="item-u">建造产业链的资源一体化共享平台，</u
                >集信息展示、信息发布、在线 查找、在线洽谈和交易等功能；
              </li>
              <li data-v-09303b08="" class="introduction-list-item">
                涵盖建筑行业所有的资源信息，包括材料、机械、工具、劳务、租
                赁、物流、培训、咨询和金融服务等；
              </li>
              <li data-v-09303b08="" class="introduction-list-item">
                作为企业和类企业组织展示、项目招标和物料采购等功能性平台使用；
              </li>
              <li data-v-09303b08="" class="introduction-list-item">
                具有优质对象和资源集中的优点；
              </li>
              <li data-v-09303b08="" class="introduction-list-item">
                彻底改变建筑行业的资源获取方式；
              </li>
              <li data-v-09303b08="" class="introduction-list-item">
                有效促进建筑产业的变革升级；
              </li>
            </ul>
          </div>
        </div>
        <div
          class="function index-function business-function"
          :class="smFlag ? 'index-function-mini' : ''"
        >
          <div class="qd-conent">
            <div class="qd-title">建筑资源</div>
            <div class="function-conent">
              <ul class="function-conent-list">
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-10.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">建筑材料</div>
                    <p class="item-conent-description">
                      建筑行业所需的各种材料发布、展示和交易平台
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-11.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">产品配套</div>
                    <p class="item-conent-description">
                      建筑行业所需的机械、设备、仪器和工具的发布、展示和交易平台
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-12.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">机械设备</div>
                    <p class="item-conent-description">
                      建筑行业所需的各种产品配套发布、展示和交易平台
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-13.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">物流运输</div>
                    <p class="item-conent-description">
                      建筑行业的材料、机械和转场运输等服务的发布、展示和交易平台
                    </p>
                  </div>
                </li>
              </ul>
              <ul class="function-conent-list">
                <li class="function-conent-list-item">
                  <img
                    :src="`${$imgBaseUrl}function-icon-14.png`"
                    class="item-icon"
                  />
                  <div class="item-conent">
                    <div class="item-conent-name">二手市场</div>
                    <p class="item-conent-description">
                      建筑行业的二手材料、机械等物料的发布、展示和交易置换平台
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img :src="`${$imgBaseUrl}function-icon-15.png`" class="item-icon" />
                  <div class="item-conent">
                    <div class="item-conent-name">租赁市场</div>
                    <p class="item-conent-description">
                      建筑行业的模板脚手架、机械设备等物料的发布、展示和交易平台
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img :src="`${$imgBaseUrl}function-icon-16.png`" class="item-icon" />
                  <div class="item-conent">
                    <div class="item-conent-name">劳务市场</div>
                    <p class="item-conent-description">
                      建筑行业所需的施工机械服务的发布、展示和交易平台
                    </p>
                  </div>
                </li>
                <li class="function-conent-list-item">
                  <img :src="`${$imgBaseUrl}function-icon-17.png`" class="item-icon" />
                  <div class="item-conent">
                    <div class="item-conent-name">机械服务</div>
                    <p class="item-conent-description">
                      建筑行业所需的施工机械服务的发布、展示和交易平台
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div :class="smFlag ? 'advisory-mini' : ''" class="advisory">
          <div data-v-0a84e9ca="" class="qd-conent">
            <div data-v-0a84e9ca="" class="qd-title">咨询服务</div>
            <div data-v-0a84e9ca="" class="advisory-conent">
              <div data-v-0a84e9ca="" class="advisory-list">
                <img
                  data-v-0a84e9ca=""
                  :src="`${$imgBaseUrl}advisory-icon-0.png`"
                  class="advisory-list-icon"
                />
                <div data-v-0a84e9ca="" class="advisory-list-title">
                  人才市场
                </div>
                <div data-v-0a84e9ca="" class="advisory-list-text">
                  建筑行业的人才展示发布和招聘平台
                </div>
              </div>
              <div data-v-0a84e9ca="" class="advisory-list">
                <img
                  data-v-0a84e9ca=""
                  :src="`${$imgBaseUrl}advisory-icon-1.png`"
                  class="advisory-list-icon"
                />
                <div data-v-0a84e9ca="" class="advisory-list-title">
                  咨询服务
                </div>
                <div data-v-0a84e9ca="" class="advisory-list-text">
                  建筑行业的设计、监理、账务和造价等服务的发布展示和交易平台
                </div>
              </div>
              <div data-v-0a84e9ca="" class="advisory-list">
                <img
                  data-v-0a84e9ca=""
                  :src="`${$imgBaseUrl}advisory-icon-2.png`"
                  class="advisory-list-icon"
                />
                <div data-v-0a84e9ca="" class="advisory-list-title">
                  专业培训
                </div>
                <div data-v-0a84e9ca="" class="advisory-list-text">
                  建筑行业所需的各类管理、专业技能和考试培训服务提供平台
                </div>
              </div>
              <div data-v-0a84e9ca="" class="advisory-list">
                <img
                  data-v-0a84e9ca=""
                  :src="`${$imgBaseUrl}advisory-icon-3.png`"
                  class="advisory-list-icon"
                />
                <div data-v-0a84e9ca="" class="advisory-list-title">
                  金融服务
                </div>
                <div data-v-0a84e9ca="" class="advisory-list-text">
                  建筑行业的配套的金融类服务的发布、展示和交易平台
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="smFlag ? 'background-mini' : ''" class="background">
          <span data-v-5800fffa="" class="background-title"
            >构建建筑行业各类资源快速流转的高速路网</span
          >
        </div>
        <div :class="smFlag ? 'platform-mini' : ''" class="platform">
          <div data-v-5010bc92="" class="platform-list">
            <div data-v-5010bc92="" class="platform-list-title">
              建筑行业的专业招标采购服务平台
            </div>
            <div data-v-5010bc92="" class="platform-list-conent">
              <div class="platform-list-conent-box">
                <div class="platform-list-conent-info">
                  <div data-v-5010bc92="" class="platform-item-title">
                    项目招标
                  </div>
                  <div data-v-5010bc92="" class="platform-item-text">
                    建筑行业工程项目的勘探、设计、监理、审计和施工等招标信息的发布和招标服务平台
                  </div>
                </div>
                <div data-v-5010bc92="" class="platform-item platform-item1"></div>
              </div>
              <div class="platform-list-conent-box">
                <div class="platform-list-conent-info">
                  <div data-v-5010bc92="" class="platform-item-title">
                    采购招标
                  </div>
                  <div data-v-5010bc92="" class="platform-item-text">
                    建筑行业工程项目的材料、设备、机械、成品配套等招标信息的发布和招标服务平台
                  </div>
                </div>
                <div data-v-5010bc92="" class="platform-item platform-item2"></div>
              </div>
            </div>
          </div>
          <div data-v-5010bc92="" class="platform-list">
            <div data-v-5010bc92="" class="platform-list-title">
              建筑行业的企业和类企业组织的风采展示平台
            </div>
            <div data-v-5010bc92="" class="platform-list-conent">
              <div class="platform-list-conent-box">
                <div class="platform-list-conent-info">
                  <div data-v-5010bc92="" class="platform-item-title">
                    企业展示
                  </div>
                  <div data-v-5010bc92="" class="platform-item-text">
                    建筑施工类企业的人员、技术和工程业绩等展示企业实力的综合信息的发布和展示服务平台
                  </div>
                </div>
                <div data-v-5010bc92="" class="platform-item platform-item3"></div>
              </div>
              <div class="platform-list-conent-box">
                <div class="platform-list-conent-info">
                  <div data-v-5010bc92="" class="platform-item-title">
                    项目部展示
                  </div>
                  <div data-v-5010bc92="" class="platform-item-text">
                    建筑施工类个体项目部的人员、技术和工程业绩等展示组织实力的综合信息的发布和展示服务平台
                  </div>
                </div>
                <div data-v-5010bc92="" class="platform-item platform-item4"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="slogan is-index" :class="smFlag ? 'slogan-mini' : ''">
          <div data-v-2623df46="" class="slogan-title">
            企点市场为您提供稀缺的优质资源信息
            <p>您找到的这里有，您找不到的这里也有！</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "productPage",
  props: {},
  data() {
    return {
      activeIndex: 0,
      headFixed: false,
      banner: {},
      tabs: [
        {
          name: "企点通",
          href: "#a1",
          ref: 'a1'
        },
        {
          name: "企点帮帮",
          href: "#a2",
          banner: {
            title: "建筑从业者的口袋助理",
            decs: "企点帮帮 让你工作更轻松",
          },
          ref: 'a2'
        },
        {
          name: "企点市场",
          href: "#a3",
          banner: {
            title: "联通建造产业链信息孤岛",
            decs: "打造一体化资源共享平台",
          },
          ref: 'a3'
        },
        {
          name: "平台概述",
          href: "#a3",
          banner: {
            title: "联通建造产业链信息孤岛",
            decs: "打造一体化资源共享平台",
          },
        },
      ],
      textList: [
        "建筑管控",
        "投标管理",
        "成本四项",
        "清单三控",
        "资料管理",
        "苗圃管理",
      ],
      textList2: [
        "标准库规范大全",
        "国家规范",
        "国家图集",
        "省标图集",
        "地区图集",
        "施工验收",
      ],
      textList3: [
        "企点市场",
        "建筑材料",
        "产品配套",
        "机械设备",
        "二手市场",
        "人才市场",
      ],
      features_tabs_index: 0,
      features_tabs_obj: {},
      features_tabs: [
        {
          name: "即时通讯",
          imgUrl1:
            `${this.$imgBaseUrl}work-0-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-0-right.png`,
          decs: "告别传统排队等待签字的低效报销方式，实时在线提交报销申请，在线签字，在线审批，轻松定，更支持电子发票录入、报销单排版打印等创新功能，未来还将集成线上报销付款功能，审批通过即刻转账，转账记录实时录入财务统计报表。员工不用等，财务不用愁。",
        },
        {
          name: "公告",
          imgUrl1:
            `${this.$imgBaseUrl}work-1-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-1-right.png`,
          decs: "重要公告在线一键通知，企业所有员工同步接收，未读通知主页滚动展示，支持手机和PC端发布公告，历史通知完整保存，随时可查。",
        },
        {
          name: "一键打卡",
          imgUrl1:
            `${this.$imgBaseUrl}work-2-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-2-right.png`,
          decs: "一键精准打卡，记录位置、时间，可按部门设置有效打卡范围，一个账号绑定一部手机、彻底告别代打卡现象，打卡记录实时可查。",
        },
        {
          name: "考勤",
          imgUrl1:
            `${this.$imgBaseUrl}work-3-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-3-right.png`,
          decs: "企业日常考勤业务全覆盖，这一次更行业首推“销假”“调休”功能，轻松满足日常考勤场景。",
        },
        {
          name: "日报",
          imgUrl1:
            `${this.$imgBaseUrl}work-4-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-4-right.png`,
          decs: "将企业员工每日工作内容和次日工作计划相结合，让管理者实时掌握员工工作状态和计划安排。",
        },
        {
          name: "审批",
          imgUrl1:
            `${this.$imgBaseUrl}work-5-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-5-right.png`,
          decs: "丰富的审批业务场景，及时的审批消息推送，可个性化配置审批流程，支持按条件配置审批节点，审批节点更可配置单人、角色、组负责人等，可视化操作流程，轻松自如随时随地。",
        },
        {
          name: "盖章管理",
          imgUrl1:
            `${this.$imgBaseUrl}work-6-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-6-right.png`,
          decs: "解决企业用章安全痛点，实现从盖章申请到盖章过程完整跟踪。",
        },
        {
          name: "合同归档",
          imgUrl1:
            `${this.$imgBaseUrl}work-7-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-7-right.png`,
          decs: "专业的合同管理工具，支持上传电子合同附件，并可区分收款合同或付款合同，对应类型的合同数据将进入合同收付款模块，可再对其进行收付款操作，实现合同的完整周期跟踪。",
        },
        {
          name: "合同收款",
          imgUrl1:
            `${this.$imgBaseUrl}work-8-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-8-right.png`,
          decs: "收款合同统一管理跟踪，收款过程完整记录，收款结果随时可查。管理者轻松掌握合同回款情况，及时安排回款任务。",
        },
        {
          name: "合同付款",
          imgUrl1:
            `${this.$imgBaseUrl}work-9-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-9-right.png`,
          decs: "付款合同统一管理跟踪，付款过程完整记录，付款结果随时可查。管理者轻松掌握合同付款情况，及时安排付款任务。",
        },
        {
          name: "即时报销",
          imgUrl1:
            `${this.$imgBaseUrl}work-10-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-10-right.png`,
          decs: "告别传统排队等待签字的低效报销方式，实时在线提交报销申请，在线签字，在线审批，轻松搞定，更支持电子发票录入、报销单排版打印等创新功能，未来还将集成线上报销付款功能，审批通过即刻转账，转账记录实时录入财务统计报表。员工不用等，财务不用愁。",
        },
        {
          name: "小额预报",
          imgUrl1:
            `${this.$imgBaseUrl}work-11-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-11-right.png`,
          decs: "针对金额较小不易一次一报的情况特推出此功能，每消费一次，就记一笔消费记录，集中一次性提交报销， 历史小账本功能更完整记录员工历史报销明细，随时查阅。",
        },
        {
          name: "备用金管理",
          imgUrl1:
            `${this.$imgBaseUrl}work-12-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-12-right.png`,
          decs: "企业备用金业务在线管理，申请、使用、报销全程记录，管理者可掌握每一笔备用金的实时使用情况，让备用金透明可控。",
        },
        {
          name: "名录管理",
          imgUrl1:
            `${this.$imgBaseUrl}work-13-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-13-right.png`,
          decs: "创建项目和仓库，可配置项目负责人和仓库管理员，支持负责人的权限转移。",
        },
        {
          name: "标准库",
          imgUrl1:
            `${this.$imgBaseUrl}work-14-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-14-right.png`,
          decs: "万篇建筑行业最全最新的规范标准资料库，8大分类做你的掌上的图书馆，随时随地手机查阅，最新标准实时推送，一键收藏。",
        },
        {
          name: "企点云盘",
          imgUrl1:
            `${this.$imgBaseUrl}work-15-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}work-15-right.png`,
          decs: "完全免费的个人大容量文件加密存储，可一键从云盘提取文件发送给同事，可选取云盘附件上传到审批业务中发送给领导，审批人查阅时可自动下载附件，轻松实现文件的传递。",
        },
      ],
      features_tabs_index2: 0,
      features_tabs_obj2: {},
      features_tabs2: [
        {
          name: "招标信息",
          imgUrl1:
            `${this.$imgBaseUrl}control-0-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-0-right.png`,
          decs: "随时随地记录意向招标信息，全员跟踪记录，管理者轻松掌握每个招标信息最新动向和进展。",
        },
        {
          name: "投标管理",
          imgUrl1:
            `${this.$imgBaseUrl}control-1-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-1-right.png`,
          decs: "企业投标项目全流程跟踪管理，投标保证金、履约保证金实时回收跟踪，管理者轻松把控每一个投标项目的最新进展。",
        },
        {
          name: "成本四项",
          imgUrl1:
            `${this.$imgBaseUrl}control-2-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-2-right.png`,
          decs: "人力、机械、材料、费用四项成本在线记录，数据实时智能报表统计分析，项目成本一目了然。",
        },
        {
          name: "施工日志",
          imgUrl1:
            `${this.$imgBaseUrl}control-3-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-3-right.png`,
          decs: "工程项目成果每日一记，轻松查阅往期每日记录，项目问题即时反馈，管理者随时了解项目进展和问题。",
        },
        {
          name: "清单三控",
          imgUrl1:
            `${this.$imgBaseUrl}control-4-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-4-right.png`,
          decs: "项目进度动态管控，通过(形象、清单、成控)三个进度实时掌握项目工程量进展、目标成本进展，实际成本进展。成本数据从成本四项模块实时获取，并可设置成本告警线，超过告警线后系统实时提示。",
        },
        {
          name: "资料管理",
          imgUrl1:
            `${this.$imgBaseUrl}control-5-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-5-right.png`,
          decs: "安全加密的项目资料电子库，告别纸质资料丢失无从查阅，资料借阅线上审批记录，谁借阅谁负责。提供多套完整项目施工资料库模板，项目资料管理更专业。资料办理功能更能全程记录资料办理过程，管理者随时查阅相关资料的办理进展。",
        },
        {
          name: "施工图",
          imgUrl1:
            `${this.$imgBaseUrl}control-6-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-6-right.png`,
          decs: "手机看图要收费吗？不！这一次我们免费，超快、超简单。",
        },
        {
          name: "竣工管理",
          imgUrl1:
            `${this.$imgBaseUrl}control-7-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-7-right.png`,
          decs: "对工程竣工后的验收文件，决算文件，移交文件做全程电子记录，实时跟踪补充至归档，防止文件丢失后影响工程进度。",
        },
        {
          name: "维保管理",
          imgUrl1:
            `${this.$imgBaseUrl}control-8-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-8-right.png`,
          decs: "维保任务在线指派到人，完成后在线反馈，在线审核完成情况，还可设置维保到期提醒，到期后系统将及时提醒到相关责任人，做好跟踪工作。",
        },
        {
          name: "绿化养护",
          imgUrl1:
            `${this.$imgBaseUrl}control-9-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-9-right.png`,
          decs: "跟踪记录各项目绿化养护日志，养护日志每日在线记录，病虫害防治、苗木补植可持续跟进，处理方案在线审批，结果在线汇报。管理者不用去现场，项目绿化工程一目了然。",
        },
        {
          name: "供应商管理",
          imgUrl1:
            `${this.$imgBaseUrl}control-10-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-10-right.png`,
          decs: "在线搜索一键加入供应商，按人力、材料、机械分类集中管理，一键联系。",
        },
        {
          name: "无约定价",
          imgUrl1:
            `${this.$imgBaseUrl}control-11-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-11-right.png`,
          decs: "在没有合同的前提下对临时采购物品的价格进行在线申请核定。 历史小账本功能更完整记录员工历史报销明细，随时查阅。",
        },
        {
          name: "苗圃管理",
          imgUrl1:
            `${this.$imgBaseUrl}control-12-left.png`,
          imgUrl2:
            `${this.$imgBaseUrl}control-12-right.png`,
          decs: "工程项目施工日志记录。",
        },
      ],
    };
  },
  methods: {
    toPath(path, index, text) {
      this.$emit("changeTab", index, text);
      this.$router.push(path);
    },
    tabChange(item, i) {
      this.activeIndex = i;
      if (i == 3) {
        window.open("http://qidiantec.com/files/overview.pdf#view=FitV");
      }
      this.banner = item.banner;
      console.log(item);
      this.setInitScroll()
    },
    setInitScroll() {
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0

      // if (this.activeIndex == 0) {
      //   document.documentElement.scrollTop = 0
      //   document.body.scrollTop = 0
      // } else {
      //   document.documentElement.scrollTop = 80
      //   document.body.scrollTop = 80
      // }
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
      let small = 390;
      if (this.smFlag) {
        small = 180;
      }
      if (scrollTop >= small) {
        this.headFixed = true;
      } else {
        this.headFixed = false;
      }
    },
    features_tabs_change(item, i) {
      this.features_tabs_obj = { ...item };
      this.features_tabs_index = i;
    },
    features_tabs_change2(item, i) {
      this.features_tabs_obj2 = { ...item };
      this.features_tabs_index2 = i;
    },
    handleResize() {
      console.log(window.innerWidth)
    }
  },
  created() {
    this.activeIndex = this.$route.query.index || 0;
    this.banner = this.tabs[this.activeIndex].banner;
    console.log(this.activeIndex);
    this.setInitScroll()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    // 添加事件监听
    window.addEventListener("scroll", this.handleScroll);
    this.features_tabs_obj = this.features_tabs[0];
    this.features_tabs_obj2 = this.features_tabs2[0];
    this.$nextTick(() => {
      if (this.$route.query.index) {
        let scroll = 480;
        if (this.smFlag) {
          scroll = 80;
          if (this.$route.query.index == 0) {
            scroll = 0
          }
        }
        this.$refs.productIndex.scrollTop = scroll;
        document.body.scrollTop = scroll;
        document.documentElement.scrollTop = scroll;
      }
    });
    this.$emit("changeTab", 2, "产品");
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style scoped>
.qd-title {
  font-size: 36px;
  text-align: center;
  font-weight: 700;
}
.product {
  position: relative;
}
.product-index {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 100px 0 0 12%;
}
.product-background {
  height: 365px;
  background-image: url(https://qidiantec.com/www/imgs/product.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.3) 2px 2px 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 75px;
}
.product-background1 {
  background-image: url(https://qidiantec.com/www/imgs/product-serve.png);
  padding: 0 !important;
  display: flex;
  align-items: center;
}
.product-background2 {
  background-image: url(https://qidiantec.com/www/imgs/product-business.png);
  padding: 0 !important;
  display: flex;
  align-items: center;
}
.product-background h1 {
  font-size: 60px;
  margin-bottom: 20px;
  letter-spacing: 5px;
}
.product-background h2 {
  font-size: 30px;
  font-weight: 100;
  letter-spacing: 3px;
}
.product-background-mini {
  height: 120px;
  background-position: 30%;
  display: flex;
  align-items: center;
  margin-top: 0;
}
.product-background-mini h1 {
  font-size: 18px;
  margin-bottom: 0;
  letter-spacing: 3px;
}
.product-background-mini h2 {
  font-size: 14px;
  margin-bottom: 0;
  letter-spacing: 0;
}
.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}
.tabs-item {
  height: 74px;
  font-size: 24px;
  width: 15%;
  color: #006feb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 3px solid #fff;
}
.tabs-item.active {
  color: #333;
  border-bottom: 3px solid #333;
}
.fixedhead.scroll-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  background: #fff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}
.tabs-mini {
  justify-content: space-around;
}
.tabs-mini .tabs-item {
  font-size: 14px;
}
.introduction {
  color: #404040;
  display: flex;
  justify-content: space-around;
  padding-top: 160px;
}
.introduction-left {
  width: 750px;
  height: 500px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 100% 0;
  position: relative;
  background-image: url(https://qidiantec.com/www/imgs/introduction-index.png);
}
.introduction-left2 {
  background-image: url(https://qidiantec.com/www/imgs/introduction-serve.png);
}
.introduction-left3 {
  background-image: url(https://qidiantec.com/www/imgs/introduction-business.png);
}
.introduction-right {
  width: 540px;
  padding-top: 20px;
}
.introduction-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #000;
}
.introduction-list {
  font-size: 18px;
}
.introduction-list-item {
  line-height: 27px;
  margin-bottom: 15px;
  word-wrap: break-word;
  letter-spacing: 2px;
}
.introduction .item-u {
  text-decoration: none;
  border-bottom: 4px solid #fdd27c;
  font-style: italic;
  color: #006feb;
}
.introduction-left-list {
  position: absolute;
  right: 150px;
  top: 190px;
  color: #fff;
}
.introduction-left-list .introduction-features-list:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  margin: 0 5px;
  display: inline-block;
}
.introduction-serve
  .introduction-left.introduction-left2
  .introduction-left-list {
  right: 570px;
  top: 190px;
}
.introduction-business .introduction-left .introduction-left-list {
  right: 62px;
  top: 185px;
}
.introduction-mini {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.introduction-mini .introduction-left {
  width: 100%;
  height: 300px;
  background-size: contain;
}
.introduction-mini .introduction-left-list {
  left: 20px;
  top: 7px !important;
  color: #333;
  font-size: 13px;
  line-height: 20px;
  right: 0 !important;
}
.introduction-serve.introduction-mini .introduction-left-list {
  right: 10px !important;
  left: auto;
}
.introduction-mini .introduction-features-list:before {
  background: #333;
}
.introduction-mini .introduction-right {
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  padding-top: 0;
}
.introduction-mini .introduction-title {
  font-size: 20px;
  margin-bottom: 20px;
}
.introduction-mini .introduction-list-item {
  line-height: 22px;
  font-size: 16px;
}
.introduction-features-title {
  padding-left: 22px;
  margin-bottom: 5px;
}
.introduction-mini .introduction-features-title {
  position: relative;
  left: -22px;
}
.index-features-work,
.index-function {
  color: #fff;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url(https://qidiantec.com/www/imgs/index-function.png);
}
.business-function {
  background-image: url(https://qidiantec.com/www/imgs/function-business.png);
}
.index-function.function {
  padding-top: 70px;
  padding-bottom: 115px;
}
.function-conent {
  color: #404040;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.function-conent-list {
  margin: 0 20px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  width: 560px;
  height: 560px;
}
.function-conent-list {
  margin: 0 20px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  width: 560px;
  height: 560px;
}
.function-conent-list-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  height: 90px;
  margin: 10px auto auto;
}
.function-conent-list-item .item-conent-name {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.function-conent-list-item .item-conent-description {
  line-height: 24px;
  font-weight: 100;
}
.item-conent {
  width: calc(100% - 150px);
}
.index-function.index-function-mini {
  padding-top: 20px;
  padding-bottom: 20px;
}
.index-function.index-function-mini .qd-title {
  font-size: 20px;
}
.index-function.index-function-mini .function-conent {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.index-function.index-function-mini .function-conent-list {
  width: calc(100% - 50px);
  margin-top: 15px;
  padding: 15px;
  height: auto;
}
.index-function.index-function-mini .function-conent-list:first-child {
  margin-top: 0;
}
.index-function.index-function-mini .function-conent-list-item {
  align-items: center;
  height: auto;
}
.index-function.index-function-mini .function-conent-list-item > img {
  width: 30px;
}
.index-function.index-function-mini .item-conent {
  width: calc(100% - 80px);
}
.index-function.index-function-mini .item-conent .item-conent-name {
  font-size: 18px;
  margin-bottom: 5px;
}
.index-function.index-function-mini .item-conent .item-conent-description {
  font-size: 14px;
  line-height: 20px;
}
.worth {
  padding: 75px 0;
  height: 692px;
  background-image: url(https://qidiantec.com/www/imgs/bg-left.png), url(https://qidiantec.com/www/imgs/bg-right.png);
  background-repeat: no-repeat;
  background-position: 0 0, 100% 100%;
}
.worth-list {
  display: flex;
  flex-wrap: wrap;
  width: 1224px;
  margin: 59px auto auto;
  justify-content: center;
}
.worth-list .item-top {
  left: 51px;
}
.worth-list-item {
  width: 200px;
  height: 231px;
  display: flex;
  flex-direction: column;
  background-image: url(https://qidiantec.com/www/imgs/bg-no.png);
  text-align: center;
  padding: 58px 18px 0;
  margin-right: 4px;
  position: relative;
}
.worth-list-item:hover:not(.no-active) {
  background-image: url(https://qidiantec.com/www/imgs/bg-blue.png);
  color: #fff;
}
.worth-list-item .item-title {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 700;
}
.worth-list-item .item-description {
  color: #666;
  font-size: 15px;
  line-height: 24px;
}
.worth-list-item:hover:not(.no-active) .item-description {
  color: #fff;
}
.worth-list .no-active {
  background-image: url(https://qidiantec.com/www/imgs/bg.png);
  opacity: 0.6;
}
.worth-list .item-bottom {
  top: -54px;
  right: 51px;
}
.worth-mini {
  padding-top: 20px;
  height: auto;
}
.worth.worth-mini .qd-title {
  font-size: 20px;
}
.worth-mini .worth-list {
  margin-top: 20px;
  width: 100%;
}
.worth-mini .worth-list .item-top,
.worth-mini .worth-list .item-bottom {
  left: 0;
  width: 150px;
  height: 185px;
  background-size: contain;
  padding: 0;
  display: flex;
  align-items: center;
  padding-top: 30px;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
}
.worth-mini .worth-list-item .item-title {
  font-size: 18px;
}
.worth-mini .worth-list-item .item-description {
  font-size: 13px;
  padding: 0 10px;
  line-height: 20px;
}
.worth-mini .worth-list .no-active {
  display: none;
}
.features {
  padding-top: 75px;
}
.index-features-work {
  background-image: url(https://qidiantec.com/www/imgs/features-work.png);
}
.index-features-work,
.index-function {
  color: #fff;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.features-tab {
  position: relative;
}
.features-tab-head {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}
.features-tab-cen {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.features-tab-head .tab-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1000px;
  font-size: 18px;
}
.features-tab-head .tab-list .tab-item {
  margin: 5px 10px;
  padding: 8px 14px;
  cursor: pointer;
}
.features-tab-head .tab-list .active,
.features-tab-head .tab-list .tab-item:hover {
  background: #fb9776;
}
.features-tab-img {
  height: 550px;
  margin-right: 20px;
}
.features-tab-img + img {
  margin-right: 60px;
}
.features-tab-conent {
  width: 400px;
  padding: 20px;
  margin-top: 80px;
  height: 292px;
  background-image: url(https://qidiantec.com/www/imgs/features-work-title.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.features-tab-name {
  font-size: 24px;
  font-weight: 700;
  margin-top: 55px;
  margin-bottom: 6px;
}
.features-tab-description {
  height: 125px;
  overflow: hidden;
  font-size: 15px;
}
.is-control .active,
.is-control .tab-item:hover {
  color: #fff;
  background: #4ba0ff !important;
}
.is-control .features-tab-conent {
  color: #fff;
  height: 292px;
  background-image: url(https://qidiantec.com/www/imgs/features-tab-control.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.is-control .features-tab-img {
  margin-left: 60px;
  margin-right: 0;
  box-shadow: 0 0 10px 0 rgba(93, 93, 93, 0.3);
}
.is-control .features-tab-img + img {
  margin-left: 20px;
  margin-right: 0;
}
.index-features-work-mini {
  padding-top: 20px;
}
.index-features-work-mini .qd-title {
  font-size: 20px;
}
.index-features-work-mini .features-tab-head {
  margin-top: 10px;
}
.index-features-work-mini .tab-list {
  width: 100%;
  justify-content: flex-start;
  padding: 0 10px;
}
.index-features-work-mini .tab-list .tab-item {
  font-size: 13px;
  line-height: 26px;
  height: 26px;
  padding: 0 5px;
  margin: 5px;
  margin-top: 0;
  min-width: 78px;
  text-align: center;
}
.index-features-work-mini .features-tab-cen {
  margin-top: -10px;
  flex-direction: column;
  align-items: center;
}
.index-features-work-mini .features-tab-img {
  margin: 0 !important;
  margin-bottom: 5px !important;
}
.index-features-work-mini .features-tab-conent {
  margin-top: 0;
  width: calc(100% - 30px);
  background-size: contain;
  background-position: top;
  background-image: none;
  position: relative;
  height: auto;
}
.index-features-work-mini .features-tab-conent .features-tab-conent-bg {
  width: 100%;
  height: auto;
}
.index-features-work-mini .features-tab-conent .features-tab-conent-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
}
.index-features-work-mini .features-tab-conent .features-tab-name {
  margin-top: 0;
  font-size: 18px;
}
.index-features-work-mini .is-control .features-tab-conent .features-tab-name {
  margin-top: 0;
}
.index-features-work-mini .features-tab-description {
  font-size: 12px;
}
.slogan {
  height: 230px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.is-index,
.is-serve {
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.is-index {
  background-image: url(https://qidiantec.com/www/imgs/slogan.png);
}
.slogan-title {
  font-size: 36px;
  letter-spacing: 10px;
  color: #fff;
  font-weight: 700;
}
.slogan .el-button {
  color: #815e00;
  font-size: 24px;
  font-weight: 700;
  border-radius: 24px;
  margin-top: 40px;
  border: 0;
  background: linear-gradient(180deg, #ffc780, #fc4);
}
.slogan-mini.slogan {
  height: 100px !important;
}
.slogan-mini.slogan .slogan-title {
  font-size: 14px;
  letter-spacing: 0;
}
.slogan-mini.slogan .el-button {
  margin-top: 5px;
  font-size: 13px;
  height: 30px;
  display: flex;
  align-items: center;
}
.advisory {
  height: 750px;
  padding: 75px 0;
}
.advisory-conent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 35px 0;
}
.advisory-conent .advisory-list {
  width: 40%;
  height: 277px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #404040;
  border-radius: 12px;
}
.advisory-conent .advisory-list-title {
  font-size: 20px;
  margin: 15px;
  font-weight: 700;
}
.advisory-conent .advisory-list-text {
  font-size: 16px;
  font-weight: 500;
}
.advisory-mini.advisory {
  padding-top: 20px;
  height: auto;
  padding-bottom: 0;
}
.advisory-mini.advisory .qd-title {
  font-size: 20px;
}
.advisory-mini.advisory .advisory-conent {
  padding-top: 10px;
}
.advisory-mini.advisory .advisory-list {
  height: auto;
  width: calc(50% - 10px);
}
.advisory-mini.advisory .advisory-list > img.advisory-list-icon {
  width: 60px;
}
.advisory-mini.advisory .advisory-list-title {
  margin: 8px 0;
  font-size: 14px;
}
.advisory-mini.advisory .advisory-list-text {
  font-size: 13px;
  min-height: 70px;
  padding: 0 10px;
}
.background {
  height: 260px;
  background-image: url(https://qidiantec.com/www/imgs/background.png);
  background-size: cover;
  background-position: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.background-title {
  font-size: 36px;
  letter-spacing: 10px;
  color: #fff;
  z-index: 1;
  font-weight: 700;
}
.background.background-mini {
  height: 80px;
}
.background.background-mini .background-title {
  font-size: 16px;
  letter-spacing: 0;
}
.platform {
  /*
  height: 1250px;
  */
  margin-bottom: 20px;
}
.platform-list-title {
  font-weight: 700;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}
@media only screen and (max-width: 1600px) {
  .platform-list-conent {
    width: 1200px;
  }
}
.platform-list-conent {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-around;
}
.platform-list-conent .platform-item {
  width: 560px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: contain;
}
.platform-list-conent .platform-item::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #def0ff;
  opacity: 0.6;
  display: none;
}
.platform-item.platform-item1 {
  background-image: url(https://qidiantec.com/www/imgs/platform-0-0.png);
}
.platform-item.platform-item2 {
  background-image: url(https://qidiantec.com/www/imgs/platform-0-1.png);
}
.platform-item.platform-item3 {
  background-image: url(https://qidiantec.com/www/imgs/platform-1-0.png);
}
.platform-item.platform-item4 {
  background-image: url(https://qidiantec.com/www/imgs/platform-1-1.png);
}
.platform-list-conent .platform-item-title {
  color: #333;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 3px;
  /* 标准语法（非标准属性） */
   /* -webkit-text-stroke: 1px #000;
  text-stroke: 1px #000; */
  z-index: 2;
}
.platform-list-conent .platform-item-text {
  color: #333;
  text-align: center;
  font-size: 18px;
  width: 360px;
  z-index: 2;
}
.platform.platform-mini {
  height: auto;
}
.platform.platform-mini .platform-list-title {
  padding: 20px;
  font-size: 18px;
}
.platform.platform-mini .platform-list-conent {
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
}
.platform.platform-mini .platform-item {
  width: 100%;
  min-height: 150px;
  height: auto;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  background-size: cover;
}
.platform.platform-mini .platform-item-title {
  font-size: 20px;
}
.platform.platform-mini .platform-item-text {
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
}
.slogan-title p {
  font-weight: 500;
  font-size: 18px;
  margin-top: 35px;
  letter-spacing: 3px;
}
.slogan-mini .slogan-title p {
  font-size: 13px;
  margin-top: 4px;
}
@media screen and (min-width: 500px) and (max-width: 1000px) {
  .product-background-mini {
    height: 230px;
  }
}
.platform-list-conent-info {
  display: flex;
  justify-content: space-between;
}
.platform.platform-mini .platform-list-conent-info {
  margin-bottom: 8px;
}
.platform-list-conent-info .platform-item-title {
  background: #CAE5FC;
  padding: 10px 5px;
  font-size: 22px;
  border-radius: 2px;
  width: 140px;
}
.platform-list-conent-info .platform-item-text {
  width: calc(560px - 150px);
  text-align: left;
}
.platform.platform-mini .platform-list-conent-info .platform-item-title {
  font-size: 14px;
  height: 40px;
  margin-bottom: 0;
}
.platform.platform-mini .platform-list-conent-info .platform-item-text {
  font-size: 12px;
  padding-right: 0;
}
.platform.platform-mini .platform-item {
  margin-bottom: 15px;
}
</style>
