<template>
  <div class="download-swipe">
    <el-carousel :autoplay="false" indicator-position="none" arrow="always">
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <div class="content">
          <h1>{{ item.title }}</h1>
          <p>{{ item.subtitle }}</p>
          <!-- <img :src="`@/assets/download/mobile/qdb-${index}.png`"> -->
          <img :src="item.img" class="image">
        </div>

        </el-carousel-item>
      </el-carousel>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: 'QdbSwipe',
  components: {
    // eslint-disable-next-line
    swiper,
    // eslint-disable-next-line
    swiperSlide
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    const that = this
    return {
      activeIndex: 0,
      swiperOption: {
      
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        on: {
          // eslint-disable-next-line
          click: (e) =>  {
            console.log(11111)
            that.activeIndex = this.activeIndex
          }
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {},
  methods: {
    prev() {
            this.swiper.slidePrev();
        },
        next() {
             this.swiper.slideNext()
        }
  }
}
</script>

<style lang="scss">
.download-swipe {
  color: #4aa5eb;
  text-align: center;
  background: url('../../assets/download/mobile/bg-0.png'),
    url('../../assets/download/mobile/bg-1.png');
  background-repeat: no-repeat;
  background-size: 16%, 10%;
  background-position: right 6em, left 30em;
  .download-bg {
    position: absolute;
    bottom: 0;
    height: calc(100vw / 2.67);
    width: 100%;
    background: url('../../assets/download/mobile/bg-2.png') no-repeat;
    background-size: 100%;
    z-index: 10;
  }
  .flip-x {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH();
  }
  .el-carousel__container {
    height: 100vh;
    
}
.content{
  height: 100%;
  
}
.image{
  height: calc(100vh - 100px);
  padding-bottom: 5em;
}
  h1 {
    font-size: 2.2em;
    margin-top: 1em;
    margin-bottom: 0.2em;
    letter-spacing: 0.1em;
  }
  p {
    font-size: 1.5em;
    margin-bottom: 0.4em;
    letter-spacing: 0.1em;
  }
  img {
    width: 80%;
  }
}
</style>
