<template>
  <transition name="el-fade-in">
    <div v-show="isShow" class="qd-cover" @touchmove.prevent @click="isShow = false">
      <slot/>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'QdCover',
  data() {
    return {
      isShow: false
    }
  },
  mounted() {},
  methods: {
    show() {
      this.isShow = true
    },
    close() {
      this.isShow = false
    }
  }
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.qd-cover {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}
</style>
