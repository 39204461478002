<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    document.title = "企点网";
  },
  data() {
    return {
      isLoginPage: this.isLoginPage,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 700) {
        this.smFlag = 0;
      } else {
        this.smFlag = 1;
      }
    },
  },
  watch: {
    isLoginPage(val, oldVal) {
      console.log(val, oldVal);
    },
  },
};
</script>

<style>
@import "./assets/common.css";
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}
ol,
ul {
  list-style: none;
}
</style>
