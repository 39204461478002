<template>
  <section :class="smFlag ? 'about-mini' : ''" class="about qd-cneter">
    <div data-v-6451079c="" class="about-banner">
      <div data-v-6451079c="" class="about-content-vision">
        <p1 data-v-6451079c="">利国·强企·富民</p1>
        <p data-v-6451079c="">肩负社会责任，创造品质生活</p>
      </div>
    </div>
    <div data-v-6451079c="" class="about-content">
      <div
        data-v-6451079c=""
        class="about-content-brief qd-conent about-content-brief1"
      >
        <div data-v-6451079c="" class="about-content-brief-title">
          <span data-v-6451079c="">公司简介</span>
        </div>
        <div data-v-6451079c="" class="about-content-brief-conent">
          <p data-v-6451079c="">
            企点科技有限公司于2016年4月在国家工商总局设立，注册资本5000万元。公司专注于建造领域，是以提供专业级应用为核心，以产业大数据、产业生态等为基础的数字化平台服务商。
          </p>
          <p data-v-6451079c="">
            公司致力于行业云+端的数据集中应用平台研发和运营，以最优的标准化和流程化的轻型ERP系统进行组织痛点管理管控，两大产品工具是以“PO链式全时自动化管控系统”为核心的组织版“企点通”和以《中国建筑规范标准库》为核心的个人版“企点帮帮”，均已进入成熟运行阶段。
          </p>
          <p data-v-6451079c="">
            公司目前在职员工80余人，拥有多项专利和自主知识产权，已初步形成了以软件系统开发为基础，以衍生资源共享和商业交易平台为突破的产业链生态化商业结构。助力中国建造，致力企业创新，企点科技正在为建造产业的转型升级而努力前行！
          </p>
        </div>
      </div>
      <div
        data-v-6451079c=""
        class="about-content-brief qd-conent about-content-brief1"
      >
        <div data-v-6451079c="" class="about-content-brief-title">
          <span data-v-6451079c="">科创成果</span>
        </div>
        <div data-v-6451079c="" class="about-content-brief-btns">
          <button
            data-v-6451079c=""
            type="primary"
            round=""
            class="banner-button"
            @click="link('http://qidiantec.com/files/report.pdf#view=FitV')"
          >
            查新报告</button
          ><button
            data-v-6451079c=""
            type="primary"
            round=""
            class="banner-button"
            @click="
              link('http://qidiantec.com/files/certificate.pdf#view=FitV')
            "
          >
            科创成果</button
          ><button
            data-v-6451079c=""
            type="primary"
            round=""
            class="banner-button"
            @click="link('http://qidiantec.com/files/promotion.pdf#view=FitV')"
          >
            国家推广</button
          ><button
            data-v-6451079c=""
            type="primary"
            round=""
            class="banner-button"
            @click="link('http://qidiantec.com/files/group.pdf#view=FitV')"
          >
            领导合影
          </button>
        </div>
      </div>
      <div data-v-6451079c="" class="about-content-deve">
        <div data-v-6451079c="" class="qd-conent">
          <div data-v-6451079c="" class="about-content-brief-title">
            <span data-v-6451079c="">发展历程</span>
          </div>
          <div
            data-v-6451079c=""
            class="about-content-brief-conent about-steps"
          >
            <div data-v-67256ad3="" data-v-6451079c="" class="steps">
              <div data-v-67256ad3="" class="steps-div">
                <div data-v-67256ad3="" class="steps-div-time">
                  <span data-v-67256ad3="">2023</span>
                </div>
                <div data-v-67256ad3="" class="steps-div-step">
                  <img
                    data-v-67256ad3=""
                    :src="`${$imgBaseUrl}dian.png`"
                  />
                </div>
                <div data-v-67256ad3="" class="steps-div-conent">
                  <div data-v-67256ad3="" class="steps-div-conent-item">
                    <p data-v-67256ad3="">
                      2023年3月
                      企点通平台获得安徽省科技厅颁发的拥有六大创新点的《科技查新报告》
                    </p>
                    <p data-v-67256ad3="">
                      2023年3月 企点通平台通过住建部科创成果评估初审
                    </p>
                    <p data-v-67256ad3="">
                      2023年9月
                      企点通平台通过住建部评估认证并取得建科评[2023]066号科技成果证书
                    </p>
                    <p data-v-67256ad3="">
                      2023年11月 企点通获得住建部颁发的国家级推广证书
                    </p>
                    <!---->
                  </div>
                </div>
              </div>
              <div data-v-67256ad3="" class="steps-div">
                <div data-v-67256ad3="" class="steps-div-time">
                  <span data-v-67256ad3="">2022</span>
                </div>
                <div data-v-67256ad3="" class="steps-div-step">
                  <img
                    data-v-67256ad3=""
                    :src="`${$imgBaseUrl}dian.png`"
                  />
                </div>
                <div data-v-67256ad3="" class="steps-div-conent">
                  <div data-v-67256ad3="" class="steps-div-conent-item">
                    <p data-v-67256ad3="">
                      2022年9月企点通平台报住建部科创成果评估初审
                    </p>
                    <!---->
                  </div>
                </div>
              </div>
              <div data-v-67256ad3="" class="steps-div">
                <div data-v-67256ad3="" class="steps-div-time">
                  <span data-v-67256ad3="">2019</span>
                </div>
                <div data-v-67256ad3="" class="steps-div-step">
                  <img
                    data-v-67256ad3=""
                    :src="`${$imgBaseUrl}dian.png`"
                  />
                </div>
                <div data-v-67256ad3="" class="steps-div-conent">
                  <div data-v-67256ad3="" class="steps-div-conent-item">
                    <p data-v-67256ad3="">
                      2019年1月 整个产品系进入为期一年的实践测试
                    </p>
                    <p data-v-67256ad3="">
                      2019年12月 整个产品系完成测试并正式上线发布
                    </p>
                    <!---->
                  </div>
                </div>
              </div>
              <div data-v-67256ad3="" class="steps-div">
                <div data-v-67256ad3="" class="steps-div-time">
                  <span data-v-67256ad3="">2018</span>
                </div>
                <div data-v-67256ad3="" class="steps-div-step">
                  <img
                    data-v-67256ad3=""
                    :src="`${$imgBaseUrl}dian.png`"
                  />
                </div>
                <div data-v-67256ad3="" class="steps-div-conent">
                  <div data-v-67256ad3="" class="steps-div-conent-item">
                    <p data-v-67256ad3="">
                      2018年12月
                      企点网上线下辖三款产品：“OA版企点通”“ERP版企点通”“个人版企点帮帮”
                    </p>
                    <!---->
                  </div>
                </div>
              </div>
              <div data-v-67256ad3="" class="steps-div">
                <div data-v-67256ad3="" class="steps-div-time">
                  <span data-v-67256ad3="">2017</span>
                </div>
                <div data-v-67256ad3="" class="steps-div-step">
                  <img
                    data-v-67256ad3=""
                    :src="`${$imgBaseUrl}dian.png`"
                  />
                </div>
                <div data-v-67256ad3="" class="steps-div-conent">
                  <div data-v-67256ad3="" class="steps-div-conent-item">
                    <p data-v-67256ad3="">2017年1月 启动”企点通“2.0版本研发</p>
                    <p data-v-67256ad3="">
                      2017年8月 启动五大辅助系统的开发，“企点网”产品体系形成
                    </p>
                    <!---->
                  </div>
                </div>
              </div>
              <div data-v-67256ad3="" class="steps-div">
                <div data-v-67256ad3="" class="steps-div-time">
                  <span data-v-67256ad3="">2016</span>
                </div>
                <div data-v-67256ad3="" class="steps-div-step">
                  <img
                    data-v-67256ad3=""
                    :src="`${$imgBaseUrl}dian.png`"
                  />
                </div>
                <div data-v-67256ad3="" class="steps-div-conent">
                  <div data-v-67256ad3="" class="steps-div-conent-item">
                    <p data-v-67256ad3="">
                      2016年4月 企点科技有限公司注册成立启动专利申请及商标注册
                    </p>
                    <p data-v-67256ad3="">
                      2016年10月-12月
                      企点通1.0工具版本正式上线，线下推广实验取得成功。为企点通2.0工具版本的开发和营销推广积累了丰富的经验
                    </p>
                    <!---->
                  </div>
                </div>
              </div>
              <div data-v-67256ad3="" class="steps-div">
                <div data-v-67256ad3="" class="steps-div-time">
                  <span data-v-67256ad3="">2014</span>
                </div>
                <div data-v-67256ad3="" class="steps-div-step">
                  <img
                    data-v-67256ad3=""
                    :src="`${$imgBaseUrl}dian.png`"
                  />
                </div>
                <div data-v-67256ad3="" class="steps-div-conent">
                  <div data-v-67256ad3="" class="steps-div-conent-item">
                    <p data-v-67256ad3="">
                      2014年10月 启动《中国建设规范标准库》项目开发
                    </p>
                    <!---->
                  </div>
                </div>
              </div>
              <div data-v-67256ad3="" class="steps-div">
                <div data-v-67256ad3="" class="steps-div-time">
                  <span data-v-67256ad3="">2013</span>
                </div>
                <div data-v-67256ad3="" class="steps-div-step">
                  <img
                    data-v-67256ad3=""
                    :src="`${$imgBaseUrl}dian.png`"
                  />
                </div>
                <div data-v-67256ad3="" class="steps-div-conent">
                  <div data-v-67256ad3="" class="steps-div-conent-item">
                    <p data-v-67256ad3="">
                      2013年10月 启动企点通1.0工具版本需求整理和单模块研发测试
                    </p>
                    <!---->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    link(url) {
      window.open(url);
    },
  },
};
</script>
<style scoped>
.qd-cneter {
  min-height: calc(100vh - 200px);
}
.about-banner {
  height: 700px;
  background-image: url(https://qidiantec.com/www/imgs/banner1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.qd-conent {
  padding: 0 15px;
}
.about-content-brief {
  padding: 15px 0 45px;
}

@media only screen and (max-width: 1600px) {
  .qd-conent {
    width: 1400px;
  }
}
.about-content-brief-title {
  padding-bottom: 32px;
  border-bottom: 1px solid #e0e0e0;
}
.about-content-brief-title span {
  color: #3f3f3f;
  font-size: 30px;
  padding-left: 20px;
  border-left: 4px solid #006feb;
}
.about-content-brief-conent {
  position: relative;
  margin-top: 50px;
}
.about-content-brief-conent p {
  color: #3f3f3f;
  font-size: 16px;
  text-indent: 35px;
  letter-spacing: 2px;
  line-height: 30px;
  margin-bottom: 16px;
}
.about-content-deve {
  background: #f7f7f7;
  padding-top: 65px;
}
.steps {
  height: auto;
  padding-bottom: 35px;
}
.steps-div {
  display: flex;
  width: 100%;
}
.steps-div-time {
  width: 10%;
  display: flex;
  justify-content: center;
  padding-top: 63px;
}
.steps-div:first-child .steps-div-time {
  padding-top: 90px;
}
.steps-div-time span {
  font-size: 30px;
}
.steps-div-step {
  width: 8%;
  position: relative;
  text-align: center;
  padding-top: 75px;
  z-index: 5;
}
.steps-div:first-child .steps-div-step {
  padding-top: 100px;
}
.steps-div-step img {
  width: 18px;
  height: 18px;
}
.steps-div-conent {
  width: 75%;
  height: auto;
  margin-left: -4%;
  border-left: 1px solid #e0e0e0;
  position: relative;
  padding: 40px 0 0 4.5%;
}
.steps-div:first-child .steps-div-conent {
  padding: 66px 0 0 4.5%;
}
.steps-div-conent-item {
  position: relative;
  display: block;
  height: auto;
  background: #fff;
  padding: 30px 35px;
  border-radius: 5px;
}
.steps-div-conent-item p {
  color: #3f3f3f;
  line-height: 30px;
  font-size: 14px;
}
.about-content-brief-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-button {
  margin-right: 20px;
  margin-top: 30px;
  width: 200px;
  height: 52px;
  border-radius: 26px;
  border: none;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  text-shadow: 0 1px 1px rgba(14, 26, 34, 0.33);
  background: linear-gradient(180deg, #7ac1ff, #0078db);
}

.about-content-vision {
  padding-top: 170px;
  padding-left: 17%;
}
.about-content-vision p1 {
  font-size: 70px;
  color: #fff;
}
.about-content-vision p {
  font-size: 35px;
  color: #fff;
  margin-top: 10px;
}
.about-content-brief1 {
  padding-left: 15px;
  padding-right: 15px;
}
.about-mini {
  width: 100%;
  height: auto;
}
.about-mini .about-banner {
  width: 100%;
  /* height: auto; */
  min-height: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}
.about-mini .about-content-vision {
  padding: 0;
  position: relative;
  top: -20%;
  left: -10%;
}
.about-mini .about-content-vision p1 {
  font-size: 22px;
}
.about-mini .about-content-vision p {
  font-size: 18px;
}
.about-mini .about-content-brief {
  width: 100%;
  padding: 15px;
}
.about-mini .about-content-brief-title {
  padding-bottom: 10px;
}
.about-mini .about-content-brief-title span {
  font-size: 20px;
}
.about-mini .about-content-brief-conent {
  margin-top: 10px;
}
.about-mini .about-content-brief-conent p {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}
.about-mini .about-content-brief-btns {
  width: 100%;
  flex-wrap: wrap;
}
.about-mini .banner-button {
  width: calc(50% - 30px);
  font-size: 18px;
  margin: 0;
  margin: 10px;
  line-height: 40px;
  height: 40px;
}
.about-mini .about-steps {
  margin-top: 20px;
}
.about-mini .steps-div-time {
  padding-top: 20px !important;
  width: 50px;
}
.about-mini .steps-div-time span {
  font-size: 20px;
}
.about-mini .steps-div:first-child .steps-div-step {
  padding-top: 25px;
}
.about-mini .steps-div:first-child .steps-div-conent {
  padding-top: 20px;
}
.about-mini .steps-div-conent-item {
  padding: 15px;
}
.about-mini .steps-div-conent-item p {
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 5px;
}
.about-mini .qd-conent {
  width: 100%;
}
.about-mini .steps-div-step {
  padding-top: 25px;
}
.about-mini .steps-div:first-child .steps-div-time {
  padding-top: 10px !important;
}
.about-mini .steps-div:first-child .steps-div-step {
  padding-top: 15px !important;
}
</style>
