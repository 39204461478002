<template>
  <section :class="smFlag ? 'download-mini' : ''" class="download qd-cneter">
    <div data-v-016eb0e5="" class="download-div">
      <img
        data-v-016eb0e5=""
        :src="content[tabindex].img1"
        class="download-div-pic"
        style=""
      />
      <div data-v-016eb0e5="" :class="content[tabindex].class1" style="">
        <img data-v-016eb0e5="" :src="content[tabindex].img2" />
      </div>
      <div data-v-016eb0e5="" class="download-div-concent">
        <img
          data-v-016eb0e5=""
          :src="content[tabindex].img3"
          class="download-div-concent-top"
          style=""
        />

        <div data-v-016eb0e5="" class="download-div-concent-code">
          <span data-v-016eb0e5="">{{ content[tabindex].name }}</span>
          <div class="qrcode-img">
            <QrCode
              :width="smFlag ? 150 : 220"
              :value="content[tabindex].links"
              :margin="5"
            ></QrCode>
            <!-- <img :src="content[tabindex].qrcode" /> -->
          </div>
          <img data-v-016eb0e5="" :src="content[tabindex].img4" style="" />
        </div>
        <!-- <div data-v-016eb0e5="" class="download-div-concent-title">
          <div class="text">
            移动客户端下载
            <div class="platformType-box">
              <span
                class="platformType"
                :class="platformType == 'IOS' ? 'active' : ''"
                @click="platformTypeChange('IOS')"
                >IOS</span
              >
              <span
                class="platformType"
                :class="platformType == 'Android' ? 'active' : ''"
                @click="platformTypeChange('Android')"
                >Android</span
              >
            </div>
          </div>
        </div> -->
        <div data-v-016eb0e5="" class="download-div-concent-bottom">
          <div
            data-v-016eb0e5=""
            :class="tabindex == 0 ? 'is-focus' : ''"
            @click="tabClick(0)"
          >
            下载企点通
          </div>
          <div
            data-v-016eb0e5=""
            :class="tabindex == 1 ? 'is-focus' : ''"
            @click="tabClick(1)"
          >
            下载企点帮帮
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      tabindex: 0,
      platformType: "IOS",
      link2: [
        "https://itunes.apple.com/cn/app/id1132842378",
        "https://itunes.apple.com/cn/app/id1446873551",
      ],
      link1: [
        "https://qidiantec.com/download/qdt",
        "https://qidiantec.com/download/qdb",
      ],
      content: [
        {
          img1: `${this.$imgBaseUrl}back-tong.png`,
          img2: `${this.$imgBaseUrl}download-dir.png`,
          class1: "download-div-dir",
          img3: `${this.$imgBaseUrl}content-tong.png`,
          img4: `${this.$imgBaseUrl}qdt-logo.png`,
          name: "企点通",
          qrcode: `${this.$imgBaseUrl}qrcode2.png`,
          links: "",
        },
        {
          img1: `${this.$imgBaseUrl}back-bang.png`,
          img2: `${this.$imgBaseUrl}download-qdb.png`,
          class1: "download-div-qdb",
          img3: `${this.$imgBaseUrl}content-bang.png`,
          img4: `${this.$imgBaseUrl}qdb-logo.png`,
          name: "企点帮帮",
          qrcode: `${this.$imgBaseUrl}qrcode2.png`,
          links: "",
        },
      ],
    };
  },
  mounted() {
    this.platformTypeInit();
  },
  methods: {
    tabClick(index) {
      this.tabindex = index;
    },
    // platformTypeChange(type) {
    //   this.platformType = type;
    //   this.platformTypeInit();
    // },
    platformTypeInit() {
      if (this.smFlag) {
        this.platformType = this.$platformType;
      }
      if (this.platformType == "IOS") {
        this.content[0].links = this.link1[0];
        this.content[1].links = this.link1[1];
      }
      if (this.platformType == "Android") {
        this.content[0].links = this.link2[0];
        this.content[1].links = this.link2[1];
      }
    },
  },
};
</script>
<style scoped>
.download {
  height: 900px;
  background: url(https://qidiantec.com/www/imgs/back.png) no-repeat,
    linear-gradient(0deg, #006feb, #3594ff);
  background-position: bottom;
  display: flex;
  position: relative;
  z-index: 5;
}
.download-div {
  width: 950px;
  height: 583px;
  z-index: 999;
  margin: 100px auto auto;
  position: relative;
}
.download-div-pic {
  position: absolute;
  left: 0;
  width: 280px;
}
.download-div-dir {
  margin-top: 150px;
  margin-left: 350px;
  position: absolute;
}
.download-div-qdb {
  margin-top: 250px;
  margin-left: 350px;
  position: absolute;
}
.download-div-concent {
  width: 400px;
  height: 100%;
  position: absolute;
  right: 0;
}
.download-div-concent-top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 90px;
}
.download-div-concent-code {
  width: 220px;
  height: 285px;
  position: absolute;
  top: 130px;
  left: 90px;
}
.download-div-concent-code .qrcode-img {
  width: 220px;
  height: 220px;
}
.download-div-concent-code .qrcode-img > img {
  width: 100%;
  height: 100%;
}
.download-div-concent-code span {
  width: 220px;
  height: 65px;
  display: block;
  color: #fff;
  text-align: center;
  line-height: 65px;
  font-size: 36px;
  font-weight: 700;
  background: #f58b76;
}
.download-div-concent-code > img {
  position: absolute;
  width: 50px;
  left: 85px;
  top: 150px;
}
.download-div-concent-title {
  position: absolute;
  top: 430px;
  left: 60px;
}
.download-div-concent-title .text {
  font-size: 18px;
  color: #fff;
  margin: 15px auto 0;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}
.download-div-concent-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}
.download-div-concent-bottom div {
  width: 180px;
  height: 60px;
  color: #fff;
  font-size: 22px;
  text-align: center;
  line-height: 60px;
  opacity: 0.5;
  background: hsla(0, 0%, 100%, 0.1);
}
.download-div-concent-bottom .is-focus {
  opacity: 1;
  background: hsla(0, 0%, 100%, 0.5);
}
.download-mini {
  width: 100%;
  height: 500px;
}
.download-mini .download-div {
  width: 100%;
  height: auto;
  margin: 0;
  display: flex;
  justify-content: space-around;
  padding: 0 15px;
  margin-top: 100px;
}
.download-mini .download-div-pic {
  position: static;
  width: 150px;
  height: 310px;
}
.download-mini .download-div-dir,
.download-mini .download-div-qdb {
  display: none;
}
.download-mini .download-div-concent {
  position: static;
  display: inline-block;
  width: calc(100% - 180px);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.download-mini .download-div-concent-top {
  position: static;
  width: 100%;
  height: auto;
  display: none;
}
.download-mini .download-div-concent-code {
  position: relative;
  top: 0;
  left: 0;
  /* width: calc(100% - 170px); */
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.download-mini .download-div-concent-code .qrcode-img {
  width: 150px;
  height: 150px;
}
.download-mini .download-div-concent-code span {
  width: 150px;
  height: 35px;
  line-height: 35px;
  font-size: 18px;
}
.download-mini .download-div-concent-code > img {
  width: 30px;
  height: 30px;
  top: calc(50% + 3px);
  left: calc(50% - 13px);
}
.download-mini .download-div-concent-title {
  position: static;
}
.download-mini .download-div-concent-title .text {
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
}
.download-mini .download-div-concent-bottom {
  position: static;
  margin-top: 10px;
  width: 100%;
}
.download-mini .download-div-concent-bottom div {
  width: 50%;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
}
.platformType-box {
  margin-left: 10px;
}
.platformType {
  display: inline-block;
  padding: 2px 10px;
  background-color: #3594ff;
  color: #fff;
  cursor: pointer;
  opacity: 0.6;
  margin: 0 6px;
}
.platformType.active {
  opacity: 1;
  background: hsla(0, 0%, 100%, 0.5);
}
.download-mini .platformType-box {
  display: none;
}
</style>
