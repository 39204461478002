import axios from 'axios'

// 创建axios实例
const service = axios.create({
  baseURL: "http://47.101.18.103", // api的base_url
  timeout: 15000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // Do something with request error// for debug
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非1是抛错 可结合自己业务进行修改
     */
    const res = response.data
    if (res.code !== '1') {
      return Promise.reject(response)
    } else {
      return res
    }
  },
  error => {
    // for debug
    return Promise.reject(error)
  }
)

export default service
