<template>
  <div>
    <div
      ref="productIndex"
      @scroll="handleScroll"
      class="product-background product-index solution-back"
      :class="
        smFlag
          ? 'product-background-mini' + ' product-background' + activeIndex
          : 'product-background' + activeIndex
      "
    ></div>
    <div class="scroll-head" :class="headFixed ? 'fixedhead' : ''">
      <div class="tabs" :class="smFlag ? 'tabs-mini' : ''">
        <template v-if="!smFlag">
          <a
            :href="item.href"
            v-for="(item, i) in tabs"
            :key="i"
            class="tabs-item"
            :class="activeIndex == i ? 'active' : ''"
            @click="tabChange(item, i)"
          >
            {{ item.name }}
          </a>
        </template>
        <template v-else>
          <van-tabs 
          title-active-color="#006feb"
          color="#006feb"
          :ellipsis="false"
          @click="tabChange2"
          v-model="activeIndex">
            <van-tab 
              v-for="(item, i) in tabs" 
              :key="i" 
              :title="item.name">
            </van-tab>
          </van-tabs>
        </template>
      </div>
    </div>
    <div>
      <div id="a1" ref="a1">
        <div
          class="production solution-conent d_jump"
          :class="smFlag ? 'production-mini' : ''"
        >
          <div class="production-title" data-v-a685e764="">项目生产之痛</div>
          <div class="production-content" data-v-a685e764="">
            <div
              class="production-content-div"
              v-for="(item, i) in list1"
              :key="i"
              @click="openDetail(item)"
            >
              <img
                data-v-a685e764=""
                data-src="/images/solution/production/production-0.png"
                :src="item.imgUrl"
                lazy="loaded"
              />
              <span class="production-content-div-label" data-v-a685e764="">{{
                item.name
              }}</span>
              <div class="production-content-div-text" data-v-a685e764="">
                <span data-v-a685e764="">
                  {{ item.decs }}
                  <span data-v-a685e764="">查看更多</span></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="a2" ref="a2">
        <div
          class="istration solution-conent d_jump back"
          :class="smFlag ? 'istration-mini' : ''"
        >
          <div class="istration-title" data-v-19ea4032="">企业发展之痛</div>
          <div class="istration-content">
            <div
              class="istration-content-div"
              v-for="(item, i) in list2"
              :key="i"
              @click="openDetail(item)"
            >
              <div class="istration-content-div-back" data-v-19ea4032="">
                <div data-v-19ea4032="">
                  <span data-v-19ea4032="">{{ item.name }}</span>
                  <span data-v-19ea4032="">{{ item.decs }}</span>
                </div>
              </div>
              <div class="istration-content-div-bottom" data-v-19ea4032="">
                <div data-v-19ea4032="">
                  {{ item.decs2 }}
                </div>
                <div data-v-19ea4032="">查看详情</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="a3" ref="a3">
        <div
          class="istration solution-conent d_jump"
          :class="smFlag ? 'istration-mini' : ''"
        >
          <div class="istration-title" data-v-19ea4032="">企业管理之痛</div>
          <div class="istration-content" data-v-19ea4032="">
            <div
              class="istration-content-div"
              v-for="(item, i) in list3"
              :key="i"
              @click="openDetail(item)"
            >
              <div class="istration-content-div-back" data-v-19ea4032="">
                <div data-v-19ea4032="">
                  <span data-v-19ea4032="">{{ item.name }}</span>
                  <span data-v-19ea4032="">{{ item.decs }}</span>
                </div>
              </div>
              <div class="istration-content-div-bottom" data-v-19ea4032="">
                <div data-v-19ea4032="">
                  {{ item.decs2 }}
                </div>
                <div data-v-19ea4032="">查看详情</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="a4" ref="a4">
        <div
          class="solution-conent d_jump"
          :class="smFlag ? 'solution-conent-mini' : ''"
        >
          <div class="culture" data-v-c0d82f38="">
            <div class="culture-center" data-v-c0d82f38="">
              <div class="culture-center-title" data-v-c0d82f38="">
                企业文化之痛
              </div>
              <img
                v-if="!smFlag"
                :src="`${$imgBaseUrl}yuan.png`"
                class="culture-center-logo"
                data-v-c0d82f38=""
              />
            </div>
            <ul class="culture-list" data-v-c0d82f38="">
              <li
                class="culture-list-item"
                :style="smFlag ? '' : item.style"
                v-for="(item, i) in list4"
                :key="i"
                @click="openDetail(item)"
              >
                <h1 data-v-c0d82f38="">{{ item.name }}</h1>
                <div class="culture-text" data-v-c0d82f38="">
                  {{ item.decs }}
                </div>
                <div class="culture-bottom-text" data-v-c0d82f38="">
                  {{ item.decs2 }}
                </div>
                <button data-v-c0d82f38="">查看详情</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogTableVisible"
      :width="smFlag ? '90%' : '65%'"
    >
      <template slot="title">
        <div
          data-v-0918b272=""
          class="details-title"
          :class="smFlag ? 'details-title-mini' : ''"
        >
          行业痛点解决方案
        </div>
      </template>
      <div ref="details" class="details" :class="smFlag ? 'details-mini' : ''">
        <div data-v-0918b272="" class="details-top">
          <div data-v-0918b272="">{{ dialogItem.name }}</div>
          <div data-v-0918b272="" class="details-top-text">
            {{ dialogItem.content1 }}
          </div>
        </div>
        <div data-v-0918b272="" class="details-center">
          <span data-v-0918b272="" class="details-center-label">常见问题</span>
          <div data-v-0918b272="" class="details-center-div">
            <span v-for="(t, index) in dialogItem.content2" :key="index">{{
              t
            }}</span>
          </div>
        </div>
        <div data-v-0918b272="" class="details-bottom">
          <div data-v-0918b272="" class="details-bottom-label">
            <img
              data-v-0918b272=""
              src="https://qidiantec.com/www/imgs/d61edae.png"
            />
            <span data-v-0918b272="">点点支招 • 解决方案</span>
          </div>
          <div data-v-0918b272="" class="details-bottom-div">
            <div data-v-0918b272="" class="div-top">
              <div data-v-0918b272="">
                {{ dialogItem.content3 }}
              </div>
            </div>
            <div data-v-0918b272="" class="div-center">
              <div data-v-0918b272="" class="div-center-label">点点对策</div>
              <div data-v-0918b272="" class="div-center-div">
                <span v-for="(t, index) in dialogItem.content4" :key="index">{{
                  t
                }}</span>
              </div>
            </div>
            <div data-v-0918b272="" class="div-bottom">
              <div data-v-0918b272="">点点寄语</div>
              <div data-v-0918b272="" class="div-bottom-text">
                {{ dialogItem.content5 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "productPage",
  props: {},
  data() {
    return {
      activeIndex: 1,
      headFixed: false,
      banner: {},
      tabs: [
        {
          name: "数字化企业方案",
          href: "javascript:void(0)",
        },
        {
          name: "项目生产之痛",
          href: "#a1",
          banner: {
            title: "建筑从业者的口袋助理",
            decs: "企点帮帮 让你工作更轻松",
          },
        },
        {
          name: "企业发展之痛",
          href: "#a2",
          banner: {
            title: "联通建造产业链信息孤岛",
            decs: "打造一体化资源共享平台",
          },
        },
        {
          name: "企业管理之痛",
          href: "#a3",
          banner: {
            title: "联通建造产业链信息孤岛",
            decs: "打造一体化资源共享平台",
          },
        },
        {
          name: "企业文化之痛",
          href: "#a4",
          banner: {
            title: "联通建造产业链信息孤岛",
            decs: "打造一体化资源共享平台",
          },
        },
      ],
      textList: [
        "建筑管控",
        "投标管理",
        "成本四项",
        "清单三控",
        "资料管理",
        "苗圃管理",
      ],
      textList2: [
        "标准库规范大全",
        "国家规范",
        "国家图集",
        "省标图集",
        "地区图集",
        "施工验收",
      ],
      textList3: [
        "企点市场",
        "建筑材料",
        "产品配套",
        "机械设备",
        "二手市场",
        "人才市场",
      ],
      dialogTableVisible: false,
      dialogItem: {},
      list1: [
        {
          name: "人员难管",
          decs: "建筑行业因其具有项目分散、周期长、地点多变、人员调动频繁、人员和工作需保持步调高度一致等特点，导致现在的管理方式无法保证有效执行和结果导向，给企业带来极大的内耗。... ... ",
          imgUrl:
            `${this.$imgBaseUrl}production-0.png`,
          content1:
            "建筑行业因其具有项目分散、周期长、地点多变、人员调动频繁、人员和工作需保持步调高度一致等特点，导致现在的管理方式无法保证有效执行和结果导向，给企业带来极大的内耗。",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率。",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统。",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          name: "成本难控",
          decs: "建筑业管理中最大的痛点就是成本的控制问题，企业材料，人工，机械和费用开支易出现失控、失真、和权利寻租等问题是常态化，从而导致成本急剧攀升，是利润的主要杀手，可以说控制好成本... ... ",
          imgUrl:
            `${this.$imgBaseUrl}production-1.png`,
          content1:
            "建筑行业因其具有承接项目需要强关系、施工门槛不高、资金密集、组团投标和企业人资矛盾等特性，造成分包和挂靠行为成为行业普遍现象，但在实际操作中，此类项目基本属于失控状态，经常会给企业带来灾难性的后果和巨大损失。",
          content2: [
            "一、挂靠人或分包人因故卷款跑路，将严重后果留给企业承担;",
            "二、所挂靠或分包的项目出现重大的安全质量事故，实际承包人耍赖推责;",
            "三、项目因管理不善或严重低于成本价中标，造成巨额亏损；实际承包人无力承担或耍赖推责;",
            "四、挂靠人或分包商勾结施工班组、材料商或服务商，以虚假账目敲诈企业;",
            "五、因实际承包人合同履约不力，质量和进度等均不满足业主要求而带来的严重后果;",
            "六、因实际承包人偷工减料、和下游供应商账务纠纷、拖欠人工费或货款等行为所带来的后果;",
            "七、所挂靠的项目因实际承包人原因造成工程烂尾或被清退出场等带来的后果;",
            "八、因业主单位原因导致工程款无法回收所带来的失控风险;",
            "九、实际承包人将项目再次转包或分包带来的潜在风险;",
            "十、实际承包人因利益而不会注重项目所属企业声誉所带来的损失;",
          ],
          content3:
            "以上大部分问题的核心在于企业缺乏有效管控机制和管理方法，其次是员工的执行力、态度、愿景、能力和人性使然等因素共同造成的； 所以首先企业要有行之有效的管控工具和健全的配套制度，利用标准化控制流程和结果导向工具，指导和监管员工如何正确靠谱的工作， 实现让员工自主控制和保证重要工作节点，培养员工良好的工作和学习习惯，打造企业公平、公正、公开的阳光工作环境， 塑造向上、积极、自主、承担、和谐、责权利对等和能者上、平者让、庸者下的企业文化，以上问题自会迎刃而解。企点市场会为企业提供展示的机会，让承接业务更轻松；同时提供大量靠谱且性价比高的人材机和服务供应商资源，突破企业向全国发展的资源瓶颈， 有效缓解企业的业务、团队和资金之间的运营矛盾，将运营成本降到最低，使企业可以健康、有序和强劲的长远发展。",
          content4: [
            "一、企业方主控系统：企业版企点通分包监管模块;",
            "二、业主等第三方主控系统：企业版企点通分包监管模块或个人版企点帮帮分包监管模块;",
            "三、承包方应用系统：企业ERP版企点通系统;",
          ],
          content5:
            "企业作为商业组织，盈利是企业的天职；股东和员工的生存均来源于此，企业的发展更需要盈利来支撑，所以任何损伤利润的行为都是耍流氓， 特别是在恶性竞争和市场混乱的今天，成本控制在企业管理中是重中之重，能否守住企业的生命线，就看如何预防控制住啃噬净利润的群狼，同时突破企业发展的道道屏障， 才能立于不败在商海茫茫！",
        },
        {
          name: "分包失控",
          decs: "建筑行业因其具有承接项目需要强关系、施工门槛不高、资金密集、组团投标和企业人资矛盾等特性，造成分包和挂靠行为成为行业普遍现象，但在实际操作中，此类项目基本属于失控状态，经常... ... ",
          imgUrl:
            `${this.$imgBaseUrl}production-2.png`,
          content1:
            "建筑行业因其具有项目分散、周期长、地点多变、人员调动频繁、人员和工作需保持步调高度一致等特点，导致现在的管理方式无法保证有效执行和结果导向，给企业带来极大的内耗。",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率。",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统。",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          name: "资料之痛",
          decs: "建筑行业的工程资料素有资料等于质量和资料等于现金之说，可见其重要性非同一般，也可以说干得好不如算的好，但在实际操作中，由于相关技术人员的能力不够或责任心不强等原因，经常出现.... ... ",
          imgUrl:
            `${this.$imgBaseUrl}production-3.png`,
          content1:
            "建筑行业的工程资料素有资料等于质量和资料等于现金之说，可见其重要性非同一般，也可以说干得好不如算的好，但在实际操作中，由于相关技术人员的能力不够或责任心不强等原因，经常出现工程资料丢失、未能及时或未能办理，导致项目无法验收移交、工程索赔失效和决算无法办理等严重后果，给企业带来巨大的损失。",
          content2: [
            "一、由于相关经办人员的失职，导致忘记办理或逾期无法办理;",
            "二、资料原稿提交办理后，没人跟进，最后不了了之;",
            "三、因管理人员粗心大意，资料在保管或移交过程中丢失;",
            "四、现场技术人员没有运作经济索赔或对己方有利的方案优化的能力水平，从而错失良机;",
            "五、因经济技术类资料具有很强的时效性和谈判筹码的可转移性，经常因为延误而变得被动贬值;",
            "六、因经办人员未能及时跟进办理，业主或其他第三方单位人员发生变动，造成资料办理困难;",
            "七、施工单位员工调动、离职或心态问题造成的工程资料被带走、毁坏或资料办理半途而废等情况;",
            "八、隐蔽检查等资料不能及时让各方代表签字闭环，后期集中补签带来的风险;",
            "九、工程检验保证类资料未能及时收集、检测、复试和归档带来的潜在风险;",
            "十、经济签证或设计变更及其附件等经济类资料编写的缺乏预决算专业性，不利于项目决算;",
            "十一、资料办理人员水平低下或没有责任心，造成所编资料错误百出，不能使用;",
            "十二、其他各种资料不能及时做到位，给工程的验收、移交和决算等带来的隐患;",
          ],
          content3:
            "工程项目资料因其办理收集的周期较长、分散、琐碎、量大和时效性很强等特点，且涉及的专业较多，要具有一定的专业技术水平的人才能胜任， 就现在的中小微企业的人员配备很难达到预期的水平；再者资料办理的过程需要耗费大量的人力，在跟进、保管和移交的过程中也极易丢失， 所以企业根本无法做到全程监控和指导，造成资料管理难成了所有企业的通病。要解决上述问题，首先要保证资料能及时办理、跟进、收集、归档和预防损坏丢失，对资料的质量和办理落地进行全程闭环指导和监控，保证每一份资料都能锁定责任人， 实时在线监管资料的编写、指导、办理、归档和移交等重要环节的落实情况，这样所有问题才能够彻底解决.",
          content4: [
            "一、资料监管闭环：资料管理系统;",
            "二、资料防丢管理：资料管理系统;",
            "三、现场记录管理：施工日志模块;",
            "四、技术支持管理：市场等其他综合模块组",
          ],
          content5:
            "对于建筑行业来讲，工程资料办理的质量水平高低，将直接影响项目的运营周期、质量和利润等重要考核指标，如此重要的事情，却普遍因为企业自身条件所限制， 而不得不选择听之任之或偶尔重视一下的管理方式，经常出现因工程资料给企业带来的巨大经济损失的严重后果；所以“干得好不如算的好”永远适用于古老的建筑行业， 试问，开公司干项目最终的目的不就是为了盈利嘛！",
        },
        {
          name: "资源难觅",
          decs: "建筑业属于资源密集型行业，对内所涉及到的劳务班组、材料供应、机械队伍、物料租赁和各种服务提供等生产资源；另外还有对外的业务承接的资源，可以说企业拥有资源的多少，就决定了企业... ... ",
          imgUrl:
            `${this.$imgBaseUrl}production-4.png`,
          content1:
            "建筑业属于资源密集型行业，对内所涉及到的劳务班组、材料供应、机械队伍、物料租赁和各种服务提供等生产资源；另外还有对外的业务承接的资源，可以说企业拥有资源的多少，就决定了企业发展的好坏，但是建筑行业的资源分布状况却是相对分散、良莠不齐且严重受区域限制等特点，造成企业发展、生产和运营均受到严重的影响。",
          content2: [
            "一、外地项目不敢接，因为受资源的制约，导致利润大幅下降甚至亏本;",
            "二、经常用到不靠谱的各类资源供应商，直接影响生产经营并带来损失;",
            "三、寻找各类生产资源费时费力，浪费大量人力和时间，却很难找到靠谱的;",
            "四、稀缺、冷门和优质专业的供应商很难找到;",
            "五、性价比高的优质守信供应商只有靠长期磨合才能获得;",
            "六、无法借助新技术、新工艺和新工具来提升生产力和降低生产成本;",
            "七、企业全职养着不划算的设计、预算和资料等兼职人员难找，头部资源无法向中低层外溢;",
            "八、在赶工等非常规施工时无法获得各类优质资源的支持;",
            "九、企业承接业务渠道单一，基本靠网站信息和朋友介绍;",
            "十、企业没有自我展示的机会来吸引多样化的业务资源和生产资源;",
          ],
          content3:
            "因为中国建筑业信息化程度低，属几十年未变的古老产业，各类资源基本都呈孤岛式分布，底层供应链更是一盘散沙，均靠人脉关系小范围聚拢， 且都受区域限制，所以建筑业的资源获取基本都是靠人际关系转介绍得来的，其局限性可想而知；如果要想打破这种局限， 就必须将全国和建筑业相关的全产业链资源形成一体化供需平台，使其自由流动，就能彻底突破资源瓶颈。",
          content4: ["全国一体化资源共享平台：企点市场"],
          content5:
            "企点网助力建筑产业变革升级，构建资源一体化信息共享平台；让企业发展彻底突破资源瓶颈，轻松实现全国化布局发展！",
        },
        {
          name: "后期失控",
          decs: "建设工程项目周期共分为三个阶段，即前期、施工期和质保期，尤以施工期过度至质保期阶段，期间工作较多且重要；以及漫长的质保期间，问题也是接连而来，必须及时予以闭环处理，到期后才... ... ",
          imgUrl:
            `${this.$imgBaseUrl}production-5.png`,
          content1:
            "建设工程项目周期共分为三个阶段，即前期、施工期和质保期，尤以施工期过度至质保期阶段，期间工作较多且重要；以及漫长的质保期间，问题也是接连而来，必须及时予以闭环处理，到期后才能顺利拿到全额质保金，可以说项目的利润都在施工期结束后这个时间段收回，否则也只是账面利润，但多数企业却在这个阶段效率低下，不能及时进行验收移交和办理决算，导致质保期和回款周期延长，甚至后期因故不能全额收回质保金。",
          content2: [
            "一、项目收尾时施工人员相对工作散漫、心境放松和资料不齐等原因造成验收移交工作延后;",
            "二、项目决算因资料不齐或未能及时安排编制上报，延后对账和回款期;",
            "三、因项目部人员变动影响工程竣工移交和导致决算资料编制脱节;",
            "四、项目决算上报后没有及时跟进或对账中工作拖拉造成账期延长;",
            "五、质保期间解决出现的维修问题不及时或不到位，到期后质保金回收易产生纠纷;",
            "六、因质保期时间跨度较大，容易遗忘而导致回款账期延长和产生超期质保费用;",
          ],
          content3:
            "开工容易收尾难的现象主要是因为后期工作时间跨度较大、流程长、随意性强和时效性低等原因，加上企业关注度不够，同时缺少行之有效的管理方法或工具， 从而导致后期工作效率低下，监管缺位；只有采取系统性的管理方法及工具，实现工作交接清晰、责任到人、工作节点监管到位、保证各部门实时联动、 有求必应和必要的预警提示机制，才能保证收尾工作有条不紊的按预期进行，实现决算精细化、账期缩短和服务及时到位避免纠纷，从而达到项目利润最大化。",
          content4: [
            "一、系统化管控工具：竣工管理模块组;",
            "二、辅助支持工具：施工管理模块组;",
          ],
          content5:
            "因为项目前期和施工期投入的均是成本，甚至连成本都不够，后期回收的款项才逐渐接近利润，如果账期延长，就会增加财务成本，慢慢吃掉利润； 再者后期因为决算对账结果和质保期间的维修成本均存在很大变数；所以，后期管理同样是降低成本和增加利润非常重要的一环，切不可等闲视之 ， 工程竣工验收并不代表利润目标已经实现，如果后期管理不善，同样会令已经盈利的项目亏损！",
        },
        {
          name: "动产易失",
          decs: "建设项目因其具有周期长、分散、人员混杂和现场变化大等特点，导致机械设备、电动工具、办公设备和小型工具等极易丢失，常年累月下来，也给企业带来不小的损失... ... ",
          imgUrl:
            `${this.$imgBaseUrl}production-6.png`,
          content1:
            "建设项目因其具有周期长、分散、人员混杂和现场变化大等特点，导致机械设备、电动工具、办公设备和小型工具等极易丢失，常年累月下来，也给企业带来不小的损失。",
          content2: [
            "一、施工现场的小型工具、电动工具、小型机械和办公生活设施极易丢失;",
            "二、库存机械工具等因长期闲置而缺乏必要的维修保养，人为造成报废;",
            "三、在工地转场和被借用过程中丢失;",
          ],
          content3:
            "造成以上后果的主要原因是缺乏有效的动产监管方法和让员工匹配相对应的保管责任。首先要将企业现有和新购的动产盘点登记在册，再明确对应的保管人，确定保管责任，详细记录动产的保管责任因工作需要而发生改变的传递节点和路径， 做到不管动产发生怎样的转移，始终都有明确的保管人，实行谁丢谁负责的问责制度，就能实现动产在使用、转移和库存时，不会发生丢失和损坏的情况。",
          content4: [
            "一、动产新购入册、转移、使用和库存监管：动产管理模块系统;",
          ],
          content5:
            "企业所有的动产因其具有分散、量大和易丢等特点，能够保证动产的使用周转率、提升使用效率、不重复购置和延长使用周期，是确保增加净利润的一种有效方法； 在市场竞争如此激烈的今天，利润都是靠点滴积累而来。因此，应该引起企业在日常管理中的重视。",
        },
        {
          name: "存料浪费",
          decs: "建设项目在施工过程中因采购、设计变更和返工等原因，产生无法再用于本项目的材料，且具有种类规格多样、量少但价值高和存放分散等特点；在转场和存放过程中，经常会造成丢失、失效和过... ... ",
          imgUrl:
            `${this.$imgBaseUrl}production-7.png`,
          content1:
            "建设项目在施工过程中因采购、设计变更和返工等原因，产生无法再用于本项目的材料，且具有种类规格多样、量少但价值高和存放分散等特点；在转场和存放过程中，经常会造成丢失、失效和过期等后果，给企业造成极大的浪费和损失。",
          content2: [
            "一、施工现场剩余材料极易损耗和丢失;",
            "二、库存物料因存放时间过长易过期和失效;",
            "三、在工地转场过程中损耗和丢失;",
          ],
          content3:
            "造成以上后果的主要原因是缺乏有效的存余料监管方法和让员工匹配相对应的保管责任。首先要将企业现有和新增的存余料盘点登记在册，再明确对应的保管人，确定保管责任，详细记录存预料的保管责任因工作需要而发生改变的传递节点和路径， 做到不管存余料发生怎样的转移，始终都有明确的保管人，实行谁丢谁负责的问责制度，就能实现存余料在使用、转移和库存时，不会发生丢失和失效过期的情况。再利用其他项目在采购、设计变更和方案优化时合理消化存余料，或者直接售卖进行存余料的处理。 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、存预料盘点新增入册、展示、转移、使用和库存监管：存料管理模块系统;",
            "二、存预料售卖处理：企点市场平台;",
          ],
          content5:
            "企业所有的存预料因其具有分散、量大和易丢等特点，能够保证存余料的有效、不重复购置和不丢失，是确保增加净利润的一种有效方法； 在市场竞争如此激烈的今天，利润都是靠点滴积累而来。因此，应该引起企业在日常管理中的重视。",
        },
        {
          name: "记录缺失",
          decs: "建筑工程因其具有时间跨度大、分散、可变因素多、操作随意性强和资金投入密集等特点，导致后期在人工、机械、材料和费用等账务方面易出现虚假、失真和不合理等异常现象，但却又无据可查... ... ",
          imgUrl:
            `${this.$imgBaseUrl}production-8.png`,
          content1:
            "建筑工程因其具有时间跨度大、分散、可变因素多、操作随意性强和资金投入密集等特点，导致后期在人工、机械、材料和费用等账务方面易出现虚假、失真和不合理等异常现象，但却又无据可查，不了了之，经常性使企业蒙受较大损失。",
          content2: [
            "一、人工结算时出现较大用工量出入，且极易引发民工上访讨薪;",
            "二、材料用量在竣工成本核算时超出预算含量，却无从查证;",
            "三、机械费用在结算时超出预算，无法得知耗于何处;",
            "四、各类报销出现真假存疑或明显不合理，造成损失的同时又易引发内部矛盾;",
          ],
          content3:
            "造成以上漏洞的基本原因是缺乏有力的监管和行之有效的管理方法，只有从源头进行控制，实时对发生的各项支出的真实性和合理性给予有效管控， 让每一笔支出都能做到实时、实地和实物的展示，接受企业有效的即时管理监督，而且每一笔支出都能溯源查证，这样就能从根本上杜绝以上问题的发生。",
          content4: [
            "一、企业支出：即时报销、小额预报和备用金管理模块;",
            "二、项目支出：成本四项、清单三控和项目备用金管理模块;",
          ],
          content5:
            "建筑类企业的利润基本都是来自工程项目，如果不能有效的进行成本管控，从源头堵住管理漏洞，利润都将在施工过程中消耗殆尽， 其根源就在于管理缺位和留有较大的灰色操作空间",
        },
      ],
      list2: [
        {
          name: "老板之累",
          decs: "企业领袖，社会精英，却身陷企业管理实务，没有时间学习、社交和健身，企业痛失运筹帷幄的战略舵手！",
          decs2: "企点通：把老板从管理实务中解放出来。",
          content1:
            "企业领袖，社会精英，却身陷企业管理实务，没有时间学习、社交和健身，企业痛失运筹帷幄的战略舵手！",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          name: "区域瓶颈",
          decs: "因建筑产品是多种资源的集成，离开企业属地的优势，将大幅提升项目成本和增大不利风险因素，并最终导致项目严重亏本！",
          decs2: "企点通：向企业异地提供各类优质建筑资源。",
          content1:
            "因建筑产品是多种资源的集成，离开企业属地的优势，将大幅提升项目成本和增大不利风险因素，并最终导致项目严重亏本！",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率;",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统;",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          name: "用人之惑",
          decs: "建筑企业经营发展中的业务、人才和资金的对立矛盾，经常造成企业闲时养不起，忙时无人用的尴尬境地！",
          decs2: "企点通：提供更多行业头部兼职人才给企业。",
          content1:
            "建筑企业经营发展中的业务、人才和资金的对立矛盾，经常造成企业闲时养不起，忙时无人用的尴尬境地！",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统。",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          name: "管理落后",
          decs: "建筑企业自市场经济以来，运营管理仍然停留在初级阶段，管理被时间和空间所奴役，成为遏制企 业发展的无形之手！",
          decs2: "企点通：实现企业全时自动化数控智能管理。",
          content1:
            "建筑企业自市场经济以来，运营管理仍然停留在初级阶段，管理被时间和空间所奴役，成为遏制企 业发展的无形之手！",
          content2: [
            "一、由于相关经办人员的失职，导致忘记办理或逾期无法办理;",
            "二、资料原稿提交办理后，没人跟进，最后不了了之;",
            "三、因管理人员粗心大意，资料在保管或移交过程中丢失;",
            "四、现场技术人员没有运作经济索赔或对己方有利的方案优化的能力水平，从而错失良机;",
            "五、因经济技术类资料具有很强的时效性和谈判筹码的可转移性，经常因为延误而变得被动贬值;",
            "六、因经办人员未能及时跟进办理，业主或其他第三方单位人员发生变动，造成资料办理困难;",
            "七、施工单位员工调动、离职或心态问题造成的工程资料被带走、毁坏或资料办理半途而废等情况;",
            "八、隐蔽检查等资料不能及时让各方代表签字闭环，后期集中补签带来的风险;",
            "九、工程检验保证类资料未能及时收集、检测、复试和归档带来的潜在风险;",
            "十、经济签证或设计变更及其附件等经济类资料编写的缺乏预决算专业性，不利于项目决算;",
            "十一、资料办理人员水平低下或没有责任心，造成所编资料错误百出，不能使用;",
            "十二、其他各种资料不能及时做到位，给工程的验收、移交和决算等带来的隐患;",
          ],
          content3:
            "工程项目资料因其办理收集的周期较长、分散、琐碎、量大和时效性很强等特点，且涉及的专业较多，要具有一定的专业技术水平的人才能胜任， 就现在的中小微企业的人员配备很难达到预期的水平；再者资料办理的过程需要耗费大量的人力，在跟进、保管和移交的过程中也极易丢失， 所以企业根本无法做到全程监控和指导，造成资料管理难成了所有企业的通病。要解决上述问题，首先要保证资料能及时办理、跟进、收集、归档和预防损坏丢失，对资料的质量和办理落地进行全程闭环指导和监控，保证每一份资料都能锁定责任人， 实时在线监管资料的编写、指导、办理、归档和移交等重要环节的落实情况，这样所有问题才能够彻底解决.",
          content4: [
            "一、资料监管闭环：资料管理系统;",
            "二、资料防丢管理：资料管理系统;",
            "三、现场记录管理：施工日志模块;",
            "四、技术支持管理：市场等其他综合模块组",
          ],
          content5:
            "对于建筑行业来讲，工程资料办理的质量水平高低，将直接影响项目的运营周期、质量和利润等重要考核指标，如此重要的事情，却普遍因为企业自身条件所限制， 而不得不选择听之任之或偶尔重视一下的管理方式，经常出现因工程资料给企业带来的巨大经济损失的严重后果；所以“干得好不如算的好”永远适用于古老的建筑行业， 试问，开公司干项目最终的目的不就是为了盈利嘛！",
        },
        {
          name: "项目难接",
          decs: "建筑市场竞争已趋白热化，传统的业务获取渠道已难满足企业发展需要，多元化承接业务模式已是 大势所趋！",
          decs2: "企点通：将企业向全国市场推荐和展示。",
          content1:
            "建筑市场竞争已趋白热化，传统的业务获取渠道已难满足企业发展需要，多元化承接业务模式已是 大势所趋！",
          content2: [
            "一、外地项目不敢接，因为受资源的制约，导致利润大幅下降甚至亏本;",
            "二、经常用到不靠谱的各类资源供应商，直接影响生产经营并带来损失;",
            "三、寻找各类生产资源费时费力，浪费大量人力和时间，却很难找到靠谱的;",
            "四、稀缺、冷门和优质专业的供应商很难找到;",
            "五、性价比高的优质守信供应商只有靠长期磨合才能获得;",
            "六、无法借助新技术、新工艺和新工具来提升生产力和降低生产成本;",
            "七、企业全职养着不划算的设计、预算和资料等兼职人员难找，头部资源无法向中低层外溢;",
            "八、在赶工等非常规施工时无法获得各类优质资源的支持;",
            "九、企业承接业务渠道单一，基本靠网站信息和朋友介绍;",
            "十、企业没有自我展示的机会来吸引多样化的业务资源和生产资源;",
          ],
          content3:
            "因为中国建筑业信息化程度低，属几十年未变的古老产业，各类资源基本都呈孤岛式分布，底层供应链更是一盘散沙，均靠人脉关系小范围聚拢， 且都受区域限制，所以建筑业的资源获取基本都是靠人际关系转介绍得来的，其局限性可想而知；如果要想打破这种局限， 就必须将全国和建筑业相关的全产业链资源形成一体化供需平台，使其自由流动，就能彻底突破资源瓶颈。",
          content4: ["全国一体化资源共享平台：企点市场"],
          content5:
            "企点网助力建筑产业变革升级，构建资源一体化信息共享平台；让企业发展彻底突破资源瓶颈，轻松实现全国化布局发展！",
        },
        {
          name: "尾款难收",
          decs: "工程尾款回收需经过验收、决算和质保等繁杂漫长的程序，而企业在项目后期普遍疏于管理，从而 导致尾款难收！",
          decs2: "企点通：用先进的管理方法保障尾款及时回收。",
          content1:
            "工程尾款回收需经过验收、决算和质保等繁杂漫长的程序，而企业在项目后期普遍疏于管理，从而 导致尾款难收！",
          content2: [
            "一、项目收尾时施工人员相对工作散漫、心境放松和资料不齐等原因造成验收移交工作延后;",
            "二、项目决算因资料不齐或未能及时安排编制上报，延后对账和回款期;",
            "三、因项目部人员变动影响工程竣工移交和导致决算资料编制脱节;",
            "四、项目决算上报后没有及时跟进或对账中工作拖拉造成账期延长;",
            "五、质保期间解决出现的维修问题不及时或不到位，到期后质保金回收易产生纠纷;",
            "六、因质保期时间跨度较大，容易遗忘而导致回款账期延长和产生超期质保费用;",
          ],
          content3:
            "开工容易收尾难的现象主要是因为后期工作时间跨度较大、流程长、随意性强和时效性低等原因，加上企业关注度不够，同时缺少行之有效的管理方法或工具， 从而导致后期工作效率低下，监管缺位；只有采取系统性的管理方法及工具，实现工作交接清晰、责任到人、工作节点监管到位、保证各部门实时联动、 有求必应和必要的预警提示机制，才能保证收尾工作有条不紊的按预期进行，实现决算精细化、账期缩短和服务及时到位避免纠纷，从而达到项目利润最大化。",
          content4: [
            "一、系统化管控工具：竣工管理模块组;",
            "二、辅助支持工具：施工管理模块组;",
          ],
          content5:
            "因为项目前期和施工期投入的均是成本，甚至连成本都不够，后期回收的款项才逐渐接近利润，如果账期延长，就会增加财务成本，慢慢吃掉利润； 再者后期因为决算对账结果和质保期间的维修成本均存在很大变数；所以，后期管理同样是降低成本和增加利润非常重要的一环，切不可等闲视之 ， 工程竣工验收并不代表利润目标已经实现，如果后期管理不善，同样会令已经盈利的项目亏损！",
        },
      ],
      list3: [
        {
          name: "执行无力",
          decs: "由于企业缺乏有效的跟踪监督和以结果为导向的控制方法，干好干坏一个样，从而导致员工的责任心丧失，造成执行力低下。",
          decs2: "企点通：让员工从被动执行转变为主动执行。",
          content1:
            "由于企业缺乏有效的跟踪监督和以结果为导向的控制方法，干好干坏一个样，从而导致员工的责任心丧失，造成执行力低下。",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          name: "工作耗损",
          decs: "导致工作耗损的原因主要是团队执行力低和缺乏工作协同，工作脱节或停滞引起的整个工作低效，结果不尽人意，带来巨大的隐形损失。",
          decs2: "企点通：可以有效保证重要节点落实到位。",
          content1:
            "导致工作耗损的原因主要是团队执行力低和缺乏工作协同，工作脱节或停滞引起的整个工作低效，结果不尽人意，带来巨大的隐形损失。",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率;",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统;",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          name: "责任难究",
          decs: "在企业日常生产经营活动中，经常会出现责任无人承担，出了问题无人负责，最终只能由企业兜底买单的奇怪现象，其主因是责任界定和传递不清晰，而由此造成的损失只能由企业承担。",
          decs2: "企点通：保证责任的界定和传递清晰明了。",
          content1:
            "在企业日常生产经营活动中，经常会出现责任无人承担，出了问题无人负责，最终只能由企业兜底买单的奇怪现象，其主因是责任界定和传递不清晰，而由此造成的损失只能由企业承担。",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统。",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          name: "推诿拖延",
          decs: "在日常工作中，有相当一部分员工喜欢踢皮球和拖延工作，不以结果为工作导向，给企业带来严重的执行危机、经济损失和损伤企业声誉等严重后果。",
          decs2: "企点通：保证员工再无推诿拖延的空间。",
          content1:
            "在日常工作中，有相当一部分员工喜欢踢皮球和拖延工作，不以结果为工作导向，给企业带来严重的执行危机、经济损失和损伤企业声誉等严重后果。",
          content2: [
            "一、由于相关经办人员的失职，导致忘记办理或逾期无法办理;",
            "二、资料原稿提交办理后，没人跟进，最后不了了之;",
            "三、因管理人员粗心大意，资料在保管或移交过程中丢失;",
            "四、现场技术人员没有运作经济索赔或对己方有利的方案优化的能力水平，从而错失良机;",
            "五、因经济技术类资料具有很强的时效性和谈判筹码的可转移性，经常因为延误而变得被动贬值;",
            "六、因经办人员未能及时跟进办理，业主或其他第三方单位人员发生变动，造成资料办理困难;",
            "七、施工单位员工调动、离职或心态问题造成的工程资料被带走、毁坏或资料办理半途而废等情况;",
            "八、隐蔽检查等资料不能及时让各方代表签字闭环，后期集中补签带来的风险;",
            "九、工程检验保证类资料未能及时收集、检测、复试和归档带来的潜在风险;",
            "十、经济签证或设计变更及其附件等经济类资料编写的缺乏预决算专业性，不利于项目决算;",
            "十一、资料办理人员水平低下或没有责任心，造成所编资料错误百出，不能使用;",
            "十二、其他各种资料不能及时做到位，给工程的验收、移交和决算等带来的隐患;",
          ],
          content3:
            "工程项目资料因其办理收集的周期较长、分散、琐碎、量大和时效性很强等特点，且涉及的专业较多，要具有一定的专业技术水平的人才能胜任， 就现在的中小微企业的人员配备很难达到预期的水平；再者资料办理的过程需要耗费大量的人力，在跟进、保管和移交的过程中也极易丢失， 所以企业根本无法做到全程监控和指导，造成资料管理难成了所有企业的通病。要解决上述问题，首先要保证资料能及时办理、跟进、收集、归档和预防损坏丢失，对资料的质量和办理落地进行全程闭环指导和监控，保证每一份资料都能锁定责任人， 实时在线监管资料的编写、指导、办理、归档和移交等重要环节的落实情况，这样所有问题才能够彻底解决.",
          content4: [
            "一、资料监管闭环：资料管理系统;",
            "二、资料防丢管理：资料管理系统;",
            "三、现场记录管理：施工日志模块;",
            "四、技术支持管理：市场等其他综合模块组",
          ],
          content5:
            "对于建筑行业来讲，工程资料办理的质量水平高低，将直接影响项目的运营周期、质量和利润等重要考核指标，如此重要的事情，却普遍因为企业自身条件所限制， 而不得不选择听之任之或偶尔重视一下的管理方式，经常出现因工程资料给企业带来的巨大经济损失的严重后果；所以“干得好不如算的好”永远适用于古老的建筑行业， 试问，开公司干项目最终的目的不就是为了盈利嘛！",
        },
        {
          name: "怠工翘班",
          decs: "由于缺少有效的监管措施和管理方法，员工在日常工作中消极怠工和溜班翘班现象相当普遍，严重降低了工作效率和形成不良的企业风气。",
          decs2: "企点通：不给员工怠工翘班的机会。",
          content1:
            "由于缺少有效的监管措施和管理方法，员工在日常工作中消极怠工和溜班翘班现象相当普遍，严重降低了工作效率和形成不良的企业风气。",
          content2: [
            "一、外地项目不敢接，因为受资源的制约，导致利润大幅下降甚至亏本;",
            "二、经常用到不靠谱的各类资源供应商，直接影响生产经营并带来损失;",
            "三、寻找各类生产资源费时费力，浪费大量人力和时间，却很难找到靠谱的;",
            "四、稀缺、冷门和优质专业的供应商很难找到;",
            "五、性价比高的优质守信供应商只有靠长期磨合才能获得;",
            "六、无法借助新技术、新工艺和新工具来提升生产力和降低生产成本;",
            "七、企业全职养着不划算的设计、预算和资料等兼职人员难找，头部资源无法向中低层外溢;",
            "八、在赶工等非常规施工时无法获得各类优质资源的支持;",
            "九、企业承接业务渠道单一，基本靠网站信息和朋友介绍;",
            "十、企业没有自我展示的机会来吸引多样化的业务资源和生产资源;",
          ],
          content3:
            "因为中国建筑业信息化程度低，属几十年未变的古老产业，各类资源基本都呈孤岛式分布，底层供应链更是一盘散沙，均靠人脉关系小范围聚拢， 且都受区域限制，所以建筑业的资源获取基本都是靠人际关系转介绍得来的，其局限性可想而知；如果要想打破这种局限， 就必须将全国和建筑业相关的全产业链资源形成一体化供需平台，使其自由流动，就能彻底突破资源瓶颈。",
          content4: ["全国一体化资源共享平台：企点市场"],
          content5:
            "企点网助力建筑产业变革升级，构建资源一体化信息共享平台；让企业发展彻底突破资源瓶颈，轻松实现全国化布局发展！",
        },
        {
          name: "外派失控",
          decs: "建筑企业因其固有的行业特征，员工外派非常频繁，但经常有些员工因为缺乏监管而失控，恣意妄为，严重损害企业利益。",
          decs2: "企点通：监管没有时间和空间的限制。",
          content1:
            "建筑企业因其固有的行业特征，员工外派非常频繁，但经常有些员工因为缺乏监管而失控，恣意妄为，严重损害企业利益。",
          content2: [
            "一、项目收尾时施工人员相对工作散漫、心境放松和资料不齐等原因造成验收移交工作延后;",
            "二、项目决算因资料不齐或未能及时安排编制上报，延后对账和回款期;",
            "三、因项目部人员变动影响工程竣工移交和导致决算资料编制脱节;",
            "四、项目决算上报后没有及时跟进或对账中工作拖拉造成账期延长;",
            "五、质保期间解决出现的维修问题不及时或不到位，到期后质保金回收易产生纠纷;",
            "六、因质保期时间跨度较大，容易遗忘而导致回款账期延长和产生超期质保费用;",
          ],
          content3:
            "开工容易收尾难的现象主要是因为后期工作时间跨度较大、流程长、随意性强和时效性低等原因，加上企业关注度不够，同时缺少行之有效的管理方法或工具， 从而导致后期工作效率低下，监管缺位；只有采取系统性的管理方法及工具，实现工作交接清晰、责任到人、工作节点监管到位、保证各部门实时联动、 有求必应和必要的预警提示机制，才能保证收尾工作有条不紊的按预期进行，实现决算精细化、账期缩短和服务及时到位避免纠纷，从而达到项目利润最大化。",
          content4: [
            "一、系统化管控工具：竣工管理模块组;",
            "二、辅助支持工具：施工管理模块组;",
          ],
          content5:
            "因为项目前期和施工期投入的均是成本，甚至连成本都不够，后期回收的款项才逐渐接近利润，如果账期延长，就会增加财务成本，慢慢吃掉利润； 再者后期因为决算对账结果和质保期间的维修成本均存在很大变数；所以，后期管理同样是降低成本和增加利润非常重要的一环，切不可等闲视之 ， 工程竣工验收并不代表利润目标已经实现，如果后期管理不善，同样会令已经盈利的项目亏损！",
        },
      ],
      list4: [
        {
          style: "top:306.00px;left:-144.00px;",
          name: "管理不公",
          decs: "在日常管理中，因为人管人的局限，再加上制度的弹性，很难做到公平公正的一视同仁，经常因为管理不公造成团队人心涣散，影响企业整体工作配合。",
          decs2: "系统管人实现公平公正。",
          content1:
            "在日常管理中，因为人管人的局限，再加上制度的弹性，很难做到公平公正的一视同仁，经常因为管理不公造成团队人心涣散，影响企业整体工作配合。",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          style: "top:200.72px;left:145.25px;",
          name: "实干遇冷",
          decs: "企业中往往实干的人都不善言语或不会做表面工作，而那些投机取巧的的人却善于钻营和表现，深得领导喜欢，长期以往必然会令实干的人心灰意冷而辞职，造成企业优质人才流失。",
          decs2: "结果导向让每个员工工作成绩一目了然。",
          content1:
            "企业中往往实干的人都不善言语或不会做表面工作，而那些投机取巧的的人却善于钻营和表现，深得领导喜欢，长期以往必然会令实干的人心灰意冷而辞职，造成企业优质人才流失。",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率;",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统;",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          style: "top:-65.86px;left:299.16px;",
          name: "推责邀功",
          decs: "有些员工在日常工作中有一万个理由推卸责任，同时又可以不择手段邀功请赏，这样的员工既能破坏团队稳定性，又能降低团队的战斗力，如果身为要职，则是企业的灾难。",
          decs2: "工作轨迹责权锁定且溯源可查。",
          content1:
            "有些员工在日常工作中有一万个理由推卸责任，同时又可以不择手段邀功请赏，这样的员工既能破坏团队稳定性，又能降低团队的战斗力，如果身为要职，则是企业的灾难。",
          content2: [
            "一、工人上班和机械开工时管理人员不能及时在岗，造成人机无效运行和带来安全风险;",
            "二、材料进场时，验收人员不能及时在岗组织有效验收，造成质量和数量的双重失控;",
            "三、因部分老油条员工不能及时在岗，而造成整个生产流程降效，带来人心和成本的双重损失;",
            "四、因部分员工的效率低下或渎职，造成整个项目生产受阻和中断，带来成本和名誉的双重损失;",
            "五、因部分人员不能及时在岗，整个团队不能形成有效沟通和协调，影响整体工作效率",
          ],
          content3:
            "以上五条重要痛点的核心原因是管理人员的责任心缺失和执行力低，其次是能力和态度问题；形成这种结果的主因是监管缺失、无结果导向控制、 员工打工心态或主管能力不强等多种因素造成的；所以，监管住人员动向和执行结果导向，在辅以标准化和流程化工作路径控制住重要工作节点，采用系统管人的自动化模式，以上问题将会得到彻底解决。",
          content4: [
            "一、员工动向监管：考勤模块组和轨迹查询系统;",
            "二、结果导向监管：成本四项为核心的施工管理模块组、竣工管理模块组和采购管理模块组等;",
            "三、日常工作监管：工作日报、工作计划和日常工作模块组等;",
            "四、工作绩效考核：智能报表和员工链式工作流数据系统。",
          ],
          content5:
            "企业组织的核心就是以人为本，但人性均有惰性，没有有效的监管和培养措施，再优秀的员工也会逐渐沦为不靠谱的庸才； 所以，对员工进行有效的监管和引导培养，21天就会养成良好的工作习惯，使之逐步成为值钱的优秀人才，对企业和员工都是双赢，将是任何一家负责任企业的正确选择！",
        },
        {
          style: "top:-369.00px;left:245.71px;",
          name: "臆断失才",
          decs: "因为人管人的局限性，在日常工作、报销和处理问题等事件中，管理者无法全面、客观和理性的进行分析，而是全凭主管臆断，极易造成误判，甚至误会对方，直接导致和员工对立或人才流失。",
          decs2: "客观真实的数据是最佳裁判。",
          content1:
            "因为人管人的局限性，在日常工作、报销和处理问题等事件中，管理者无法全面、客观和理性的进行分析，而是全凭主管臆断，极易造成误判，甚至误会对方，直接导致和员工对立或人才流失。",
          content2: [
            "一、由于相关经办人员的失职，导致忘记办理或逾期无法办理;",
            "二、资料原稿提交办理后，没人跟进，最后不了了之;",
            "三、因管理人员粗心大意，资料在保管或移交过程中丢失;",
            "四、现场技术人员没有运作经济索赔或对己方有利的方案优化的能力水平，从而错失良机;",
            "五、因经济技术类资料具有很强的时效性和谈判筹码的可转移性，经常因为延误而变得被动贬值;",
            "六、因经办人员未能及时跟进办理，业主或其他第三方单位人员发生变动，造成资料办理困难;",
            "七、施工单位员工调动、离职或心态问题造成的工程资料被带走、毁坏或资料办理半途而废等情况;",
            "八、隐蔽检查等资料不能及时让各方代表签字闭环，后期集中补签带来的风险;",
            "九、工程检验保证类资料未能及时收集、检测、复试和归档带来的潜在风险;",
            "十、经济签证或设计变更及其附件等经济类资料编写的缺乏预决算专业性，不利于项目决算;",
            "十一、资料办理人员水平低下或没有责任心，造成所编资料错误百出，不能使用;",
            "十二、其他各种资料不能及时做到位，给工程的验收、移交和决算等带来的隐患;",
          ],
          content3:
            "工程项目资料因其办理收集的周期较长、分散、琐碎、量大和时效性很强等特点，且涉及的专业较多，要具有一定的专业技术水平的人才能胜任， 就现在的中小微企业的人员配备很难达到预期的水平；再者资料办理的过程需要耗费大量的人力，在跟进、保管和移交的过程中也极易丢失， 所以企业根本无法做到全程监控和指导，造成资料管理难成了所有企业的通病。要解决上述问题，首先要保证资料能及时办理、跟进、收集、归档和预防损坏丢失，对资料的质量和办理落地进行全程闭环指导和监控，保证每一份资料都能锁定责任人， 实时在线监管资料的编写、指导、办理、归档和移交等重要环节的落实情况，这样所有问题才能够彻底解决.",
          content4: [
            "一、资料监管闭环：资料管理系统;",
            "二、资料防丢管理：资料管理系统;",
            "三、现场记录管理：施工日志模块;",
            "四、技术支持管理：市场等其他综合模块组",
          ],
          content5:
            "对于建筑行业来讲，工程资料办理的质量水平高低，将直接影响项目的运营周期、质量和利润等重要考核指标，如此重要的事情，却普遍因为企业自身条件所限制， 而不得不选择听之任之或偶尔重视一下的管理方式，经常出现因工程资料给企业带来的巨大经济损失的严重后果；所以“干得好不如算的好”永远适用于古老的建筑行业， 试问，开公司干项目最终的目的不就是为了盈利嘛！",
        },
        {
          style: "top:-566.86px;left:9.91px;",
          name: "家族诟病",
          decs: "因为国情所致，企业大部分是从家庭作坊发展起来的，但随着企业逐渐做大，原有的家族成员能力水平已不能胜任，但忠诚度却毋庸置疑，往往不得不任人唯贤和差别对待的局面，严重阻碍企业的发展。",
          decs2: "让系统管人杜绝家族因素的干扰。",
          content1:
            "因为国情所致，企业大部分是从家庭作坊发展起来的，但随着企业逐渐做大，原有的家族成员能力水平已不能胜任，但忠诚度却毋庸置疑，往往不得不任人唯贤和差别对待的局面，严重阻碍企业的发展。",
          content2: [
            "一、外地项目不敢接，因为受资源的制约，导致利润大幅下降甚至亏本;",
            "二、经常用到不靠谱的各类资源供应商，直接影响生产经营并带来损失;",
            "三、寻找各类生产资源费时费力，浪费大量人力和时间，却很难找到靠谱的;",
            "四、稀缺、冷门和优质专业的供应商很难找到;",
            "五、性价比高的优质守信供应商只有靠长期磨合才能获得;",
            "六、无法借助新技术、新工艺和新工具来提升生产力和降低生产成本;",
            "七、企业全职养着不划算的设计、预算和资料等兼职人员难找，头部资源无法向中低层外溢;",
            "八、在赶工等非常规施工时无法获得各类优质资源的支持;",
            "九、企业承接业务渠道单一，基本靠网站信息和朋友介绍;",
            "十、企业没有自我展示的机会来吸引多样化的业务资源和生产资源;",
          ],
          content3:
            "因为中国建筑业信息化程度低，属几十年未变的古老产业，各类资源基本都呈孤岛式分布，底层供应链更是一盘散沙，均靠人脉关系小范围聚拢， 且都受区域限制，所以建筑业的资源获取基本都是靠人际关系转介绍得来的，其局限性可想而知；如果要想打破这种局限， 就必须将全国和建筑业相关的全产业链资源形成一体化供需平台，使其自由流动，就能彻底突破资源瓶颈。",
          content4: ["全国一体化资源共享平台：企点市场"],
          content5:
            "企点网助力建筑产业变革升级，构建资源一体化信息共享平台；让企业发展彻底突破资源瓶颈，轻松实现全国化布局发展！",
        },
        {
          style: "top:-566.86px;left:-297.91px;",
          name: "拉帮结派",
          decs: "有人的地方就有江湖，每个企业都有小帮派存在，在工作中互相包庇，利益面前结伙争夺，对团伙以外成员挖坑添堵，给企业日常管理和运营带来极大的危害。",
          decs2: "阳光的工作平台让小帮派失去存在的意义。",
          content1:
            "有人的地方就有江湖，每个企业都有小帮派存在，在工作中互相包庇，利益面前结伙争夺，对团伙以外成员挖坑添堵，给企业日常管理和运营带来极大的危害。",
          content2: [
            "一、项目收尾时施工人员相对工作散漫、心境放松和资料不齐等原因造成验收移交工作延后;",
            "二、项目决算因资料不齐或未能及时安排编制上报，延后对账和回款期;",
            "三、因项目部人员变动影响工程竣工移交和导致决算资料编制脱节;",
            "四、项目决算上报后没有及时跟进或对账中工作拖拉造成账期延长;",
            "五、质保期间解决出现的维修问题不及时或不到位，到期后质保金回收易产生纠纷;",
            "六、因质保期时间跨度较大，容易遗忘而导致回款账期延长和产生超期质保费用;",
          ],
          content3:
            "开工容易收尾难的现象主要是因为后期工作时间跨度较大、流程长、随意性强和时效性低等原因，加上企业关注度不够，同时缺少行之有效的管理方法或工具， 从而导致后期工作效率低下，监管缺位；只有采取系统性的管理方法及工具，实现工作交接清晰、责任到人、工作节点监管到位、保证各部门实时联动、 有求必应和必要的预警提示机制，才能保证收尾工作有条不紊的按预期进行，实现决算精细化、账期缩短和服务及时到位避免纠纷，从而达到项目利润最大化。",
          content4: [
            "一、系统化管控工具：竣工管理模块组;",
            "二、辅助支持工具：施工管理模块组;",
          ],
          content5:
            "因为项目前期和施工期投入的均是成本，甚至连成本都不够，后期回收的款项才逐渐接近利润，如果账期延长，就会增加财务成本，慢慢吃掉利润； 再者后期因为决算对账结果和质保期间的维修成本均存在很大变数；所以，后期管理同样是降低成本和增加利润非常重要的一环，切不可等闲视之 ， 工程竣工验收并不代表利润目标已经实现，如果后期管理不善，同样会令已经盈利的项目亏损！",
        },
        {
          style: "top:-369.00px;left:-533.71px;",
          name: "兔窝文化",
          decs: "随着企业发展年限的增长，传统管理方式的缺位，逐渐塑造一批白兔式员工，他们不思进取，缺乏竞争力和执行力，也从不因工作而得罪任何人，得过且过的安逸度日，令管理者头痛不已却又投鼠忌器，使得企业竞争力急剧下降。",
          decs2: "让没有工作业绩的白兔赤裸裸的展现。",
          content1:
            "随着企业发展年限的增长，传统管理方式的缺位，逐渐塑造一批白兔式员工，他们不思进取，缺乏竞争力和执行力，也从不因工作而得罪任何人，得过且过的安逸度日，令管理者头痛不已却又投鼠忌器，使得企业竞争力急剧下降。",
          content2: [
            "一、项目收尾时施工人员相对工作散漫、心境放松和资料不齐等原因造成验收移交工作延后;",
            "二、项目决算因资料不齐或未能及时安排编制上报，延后对账和回款期;",
            "三、因项目部人员变动影响工程竣工移交和导致决算资料编制脱节;",
            "四、项目决算上报后没有及时跟进或对账中工作拖拉造成账期延长;",
            "五、质保期间解决出现的维修问题不及时或不到位，到期后质保金回收易产生纠纷;",
            "六、因质保期时间跨度较大，容易遗忘而导致回款账期延长和产生超期质保费用;",
          ],
          content3:
            "开工容易收尾难的现象主要是因为后期工作时间跨度较大、流程长、随意性强和时效性低等原因，加上企业关注度不够，同时缺少行之有效的管理方法或工具， 从而导致后期工作效率低下，监管缺位；只有采取系统性的管理方法及工具，实现工作交接清晰、责任到人、工作节点监管到位、保证各部门实时联动、 有求必应和必要的预警提示机制，才能保证收尾工作有条不紊的按预期进行，实现决算精细化、账期缩短和服务及时到位避免纠纷，从而达到项目利润最大化。",
          content4: [
            "一、系统化管控工具：竣工管理模块组;",
            "二、辅助支持工具：施工管理模块组;",
          ],
          content5:
            "因为项目前期和施工期投入的均是成本，甚至连成本都不够，后期回收的款项才逐渐接近利润，如果账期延长，就会增加财务成本，慢慢吃掉利润； 再者后期因为决算对账结果和质保期间的维修成本均存在很大变数；所以，后期管理同样是降低成本和增加利润非常重要的一环，切不可等闲视之 ， 工程竣工验收并不代表利润目标已经实现，如果后期管理不善，同样会令已经盈利的项目亏损！",
        },
        {
          style: "top:-65.86px;left:-587.16px;",
          name: "苦劳导向",
          decs: "苦劳不代表功劳，而苦劳带给企业的经常是损失，但在传统管理中，会叫的孩子有奶吃，一直是有效的不二法则，常常是能干的不如会叫的，虽然管理者心知肚明，却又苦于没有有力证据反驳，只能乖乖就范，长期以往必然令团队人心涣散。",
          decs2: "工作成绩是测算员工贡献的唯一标准。",
          content1:
            "苦劳不代表功劳，而苦劳带给企业的经常是损失，但在传统管理中，会叫的孩子有奶吃，一直是有效的不二法则，常常是能干的不如会叫的，虽然管理者心知肚明，却又苦于没有有力证据反驳，只能乖乖就范，长期以往必然令团队人心涣散。",
          content2: [
            "一、项目收尾时施工人员相对工作散漫、心境放松和资料不齐等原因造成验收移交工作延后;",
            "二、项目决算因资料不齐或未能及时安排编制上报，延后对账和回款期;",
            "三、因项目部人员变动影响工程竣工移交和导致决算资料编制脱节;",
            "四、项目决算上报后没有及时跟进或对账中工作拖拉造成账期延长;",
            "五、质保期间解决出现的维修问题不及时或不到位，到期后质保金回收易产生纠纷;",
            "六、因质保期时间跨度较大，容易遗忘而导致回款账期延长和产生超期质保费用;",
          ],
          content3:
            "开工容易收尾难的现象主要是因为后期工作时间跨度较大、流程长、随意性强和时效性低等原因，加上企业关注度不够，同时缺少行之有效的管理方法或工具， 从而导致后期工作效率低下，监管缺位；只有采取系统性的管理方法及工具，实现工作交接清晰、责任到人、工作节点监管到位、保证各部门实时联动、 有求必应和必要的预警提示机制，才能保证收尾工作有条不紊的按预期进行，实现决算精细化、账期缩短和服务及时到位避免纠纷，从而达到项目利润最大化。",
          content4: [
            "一、系统化管控工具：竣工管理模块组;",
            "二、辅助支持工具：施工管理模块组;",
          ],
          content5:
            "因为项目前期和施工期投入的均是成本，甚至连成本都不够，后期回收的款项才逐渐接近利润，如果账期延长，就会增加财务成本，慢慢吃掉利润； 再者后期因为决算对账结果和质保期间的维修成本均存在很大变数；所以，后期管理同样是降低成本和增加利润非常重要的一环，切不可等闲视之 ， 工程竣工验收并不代表利润目标已经实现，如果后期管理不善，同样会令已经盈利的项目亏损！",
        },
        {
          style: "top:200.72px;left:-433.25px;",
          name: "资辈当道",
          decs: "受中国传统文化的影响，论资排辈属于人之常情，但在企业发展过程中却严重影响人才的引进和发挥作用，而资辈较老的员工往往跟不上企业的发展，如果不能进行合理的调剂使用，将是企业前进的绊脚石。",
          decs2: "让工作成果来安排员工的职位。",
          content1:
            "受中国传统文化的影响，论资排辈属于人之常情，但在企业发展过程中却严重影响人才的引进和发挥作用，而资辈较老的员工往往跟不上企业的发展，如果不能进行合理的调剂使用，将是企业前进的绊脚石。",
          content2: [
            "一、项目收尾时施工人员相对工作散漫、心境放松和资料不齐等原因造成验收移交工作延后;",
            "二、项目决算因资料不齐或未能及时安排编制上报，延后对账和回款期;",
            "三、因项目部人员变动影响工程竣工移交和导致决算资料编制脱节;",
            "四、项目决算上报后没有及时跟进或对账中工作拖拉造成账期延长;",
            "五、质保期间解决出现的维修问题不及时或不到位，到期后质保金回收易产生纠纷;",
            "六、因质保期时间跨度较大，容易遗忘而导致回款账期延长和产生超期质保费用;",
          ],
          content3:
            "开工容易收尾难的现象主要是因为后期工作时间跨度较大、流程长、随意性强和时效性低等原因，加上企业关注度不够，同时缺少行之有效的管理方法或工具， 从而导致后期工作效率低下，监管缺位；只有采取系统性的管理方法及工具，实现工作交接清晰、责任到人、工作节点监管到位、保证各部门实时联动、 有求必应和必要的预警提示机制，才能保证收尾工作有条不紊的按预期进行，实现决算精细化、账期缩短和服务及时到位避免纠纷，从而达到项目利润最大化。",
          content4: [
            "一、系统化管控工具：竣工管理模块组;",
            "二、辅助支持工具：施工管理模块组;",
          ],
          content5:
            "因为项目前期和施工期投入的均是成本，甚至连成本都不够，后期回收的款项才逐渐接近利润，如果账期延长，就会增加财务成本，慢慢吃掉利润； 再者后期因为决算对账结果和质保期间的维修成本均存在很大变数；所以，后期管理同样是降低成本和增加利润非常重要的一环，切不可等闲视之 ， 工程竣工验收并不代表利润目标已经实现，如果后期管理不善，同样会令已经盈利的项目亏损！",
        },
      ],
      firstIn: true,
      oldIndex: '',
      watchIndex: false
    };
  },
  methods: {
    tabChange(item, i) {
      console.log(item)
      if (i == 0) {
        window.open("http://qidiantec.com/files/programme.pdf");
        return;
      }
      this.activeIndex = i;
      console.log(item);
    },
    tabChange2(i) {
      console.log(i)
      this.watchIndex = true
      // if (i == 0) {
      //   window.open("http://qidiantec.com/files/programme.pdf");
      //   return;
      // }
      // let scrollTop = this.$refs[`a${i}`].offsetTop - 65
      // if (i == 2 && this.oldIndex < i) {
      //   scrollTop = scrollTop - 60
      // } 
      // if (i == 1) {
      //   document.documentElement.scrollTop = 0
      //   document.body.scrollTop = 0
      // } else {
      //   document.documentElement.scrollTop = scrollTop
      //   document.body.scrollTop = scrollTop
      // }
      // this.firstIn = false
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let small = 647;
      if (this.smFlag) {
        small = 200;
      }
      if (scrollTop >= small) {
        this.headFixed = true;
      } else {
        this.headFixed = false;
      }
      if (this.smFlag) {
        // let top1 = this.$refs.a1.offsetTop - 12
        // let top2 = this.$refs.a2.offsetTop - 80
        // let top3 = this.$refs.a3.offsetTop - 80
        // let top4 = this.$refs.a4.offsetTop

        // if (scrollTop >= top1 && scrollTop < top2) {
        //   this.activeIndex = 1;
        // }
        // if (scrollTop >= top2 && scrollTop < top3) {
        //   this.activeIndex = 2;
        // }
        // if (scrollTop >= top3 && scrollTop < top4) {
        //   this.activeIndex = 3;
        // }
        // if ((parseInt(document.documentElement.scrollHeight - document.documentElement.scrollTop) == parseInt(document.documentElement.clientHeight)) ||
        //   scrollTop > top4) {
        //   this.activeIndex = 4;
        // }
        if (scrollTop >= 200 && scrollTop < 790) {
          this.activeIndex = 1;
        }
        if (scrollTop >= 790 && scrollTop < 1410) {
          this.activeIndex = 2;
        }
        if (scrollTop >= 1410 && scrollTop < 1920) {
          this.activeIndex = 3;
        }
        if ((parseInt(document.documentElement.scrollHeight - document.documentElement.scrollTop) == parseInt(document.documentElement.clientHeight)) ||
          scrollTop > 1920) {
          this.activeIndex = 4;
        }
      } else {
        if (scrollTop >= 655 && scrollTop < 1850) {
          this.activeIndex = 1;
        }
        if (scrollTop >= 1850 && scrollTop < 2630) {
          this.activeIndex = 2;
        }
        if (scrollTop >= 2630 && scrollTop < 3460) {
          this.activeIndex = 3;
        }
        if (scrollTop > 3460) {
          this.activeIndex = 4;
        }
      }
      this.watchIndex = false
      console.log(this.activeIndex);
    },
    openDetail(item) {
      this.dialogTableVisible = true;
      this.dialogItem = item;
      setTimeout(() => {
        this.$refs.details.scrollTop = 0
      }, 1)
      console.log(item);
    },
  },
  created() {
    this.activeIndex = this.$route.query.index || 1;
    this.banner = this.tabs[this.activeIndex].banner;
    console.log(this.activeIndex);
    this.firstIn = true
  },
  mounted() {
    // 添加事件监听
    window.addEventListener("scroll", this.handleScroll);
    this.$nextTick(() => {
      if (this.$route.query.index) {
        let scroll = 480;
        if (this.smFlag) {
          scroll = 104;
        }
        this.$refs.productIndex.scrollTop = scroll;
        document.body.scrollTop = scroll;
        document.documentElement.scrollTop = scroll;
      }
    });
    this.$emit("changeTab", 3, "解决方案");
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    // 监听路由对象中的变化
    activeIndex: {
      handler(newVal, oldVal) {
        if (this.watchIndex) {
          console.log(newVal, oldVal)
          this.oldIndex = oldVal
          if (this.smFlag) {
            let i = newVal
            if (i == 0) {
              window.open("http://qidiantec.com/files/programme.pdf");
              return;
            }
            let scrollTop = this.$refs[`a${i}`].offsetTop - 65
            if (i == 2 && oldVal < i) {
              scrollTop = scrollTop - 70
            } 
            if (i == 1) {
              document.documentElement.scrollTop = 0
              document.body.scrollTop = 0
            } else {
              document.documentElement.scrollTop = scrollTop
              document.body.scrollTop = scrollTop
            }
            console.log(scrollTop)
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style scoped>
.qd-title {
  font-size: 36px;
  text-align: center;
  font-weight: 700;
}
.product {
  position: relative;
}
.product-index {
  justify-content: flex-start;
  align-items: flex-start;
  padding: 100px 0 0 12%;
}
.product-background {
  height: 365px;
  background-image: url(https://qidiantec.com/www/imgs/product.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.3) 2px 2px 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 75px;
}
.product-background1 {
  background-image: url(https://qidiantec.com/www/imgs/product-serve.png);
  padding: 0 !important;
  display: flex;
  align-items: center;
}
.product-background2 {
  background-image: url(https://qidiantec.com/www/imgs/product-business.png);
  padding: 0 !important;
  display: flex;
  align-items: center;
}
.product-background h1 {
  font-size: 60px;
  margin-bottom: 20px;
  letter-spacing: 5px;
}
.product-background h2 {
  font-size: 30px;
  font-weight: 100;
  letter-spacing: 3px;
}
.product-background-mini,
.solution-back.product-background-mini {
  height: 180px;
  background-position: 30%;
  display: flex;
  align-items: center;
  margin-top: 0;
}
.product-background-mini h1 {
  font-size: 18px;
  margin-bottom: 0;
  letter-spacing: 3px;
}
.product-background-mini h2 {
  font-size: 14px;
  margin-bottom: 0;
  letter-spacing: 0;
}
.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}
.tabs-item {
  height: 74px;
  font-size: 24px;
  width: 15%;
  color: #006feb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 3px solid #fff;
}
.tabs-item.active {
  color: #333;
  border-bottom: 3px solid #333;
}
.fixedhead.scroll-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  background: #fff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}
.tabs-mini {
  justify-content: space-around;
}
.tabs-mini .tabs-item {
  font-size: 14px;
}
.introduction {
  color: #404040;
  display: flex;
  justify-content: space-around;
  padding-top: 160px;
}
.introduction-left {
  width: 750px;
  height: 500px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 100% 0;
  position: relative;
  background-image: url(https://qidiantec.com/www/imgs/introduction-index.png);
}
.introduction-left2 {
  background-image: url(https://qidiantec.com/www/imgs/introduction-serve.png);
}
.introduction-left3 {
  background-image: url(https://qidiantec.com/www/imgs/introduction-business.png);
}
.introduction-right {
  width: 540px;
  padding-top: 20px;
}
.introduction-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #000;
}
.introduction-list {
  font-size: 18px;
}
.introduction-list-item {
  line-height: 27px;
  margin-bottom: 15px;
  word-wrap: break-word;
  letter-spacing: 2px;
}
.introduction .item-u {
  text-decoration: none;
  border-bottom: 4px solid #fdd27c;
  font-style: italic;
  color: #006feb;
}
.introduction-left-list {
  position: absolute;
  right: 150px;
  top: 190px;
  color: #fff;
}
.introduction-left-list .introduction-features-list:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  margin: 0 5px;
  display: inline-block;
}
.introduction-serve
  .introduction-left.introduction-left2
  .introduction-left-list {
  right: 570px;
  top: 190px;
}
.introduction-business .introduction-left .introduction-left-list {
  right: 62px;
  top: 185px;
}
.introduction-mini {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.introduction-mini .introduction-left {
  width: 100%;
  height: 300px;
  background-size: contain;
}
.introduction-mini .introduction-left-list {
  left: 20px;
  top: 7px !important;
  color: #333;
  font-size: 13px;
  line-height: 20px;
  right: 0 !important;
}
.introduction-serve.introduction-mini .introduction-left-list {
  right: 10px !important;
  left: auto;
}
.introduction-mini .introduction-features-list:before {
  background: #333;
}
.introduction-mini .introduction-right {
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  padding-top: 0;
}
.introduction-mini .introduction-title {
  font-size: 20px;
  margin-bottom: 20px;
}
.introduction-mini .introduction-list-item {
  line-height: 22px;
  font-size: 16px;
}
.introduction-features-title {
  padding-left: 22px;
  margin-bottom: 5px;
}
.index-features-work,
.index-function {
  color: #fff;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url(https://qidiantec.com/www/imgs/index-function.png);
}
.business-function {
  background-image: url(https://qidiantec.com/www/imgs/function-business.png);
}
.index-function.function {
  padding-top: 70px;
  padding-bottom: 115px;
}
.function-conent {
  color: #404040;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.function-conent-list {
  margin: 0 20px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  width: 560px;
  height: 560px;
}
.function-conent-list {
  margin: 0 20px;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  width: 560px;
  height: 560px;
}
.function-conent-list-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  height: 90px;
  margin: 10px auto auto;
}
.function-conent-list-item .item-conent-name {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.function-conent-list-item .item-conent-description {
  line-height: 24px;
  font-weight: 100;
}
.item-conent {
  width: calc(100% - 150px);
}
.index-function.index-function-mini {
  padding-top: 20px;
  padding-bottom: 20px;
}
.index-function.index-function-mini .qd-title {
  font-size: 20px;
}
.index-function.index-function-mini .function-conent {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.index-function.index-function-mini .function-conent-list {
  width: calc(100% - 50px);
  margin-top: 15px;
  padding: 15px;
  height: auto;
}
.index-function.index-function-mini .function-conent-list:first-child {
  margin-top: 0;
}
.index-function.index-function-mini .function-conent-list-item {
  align-items: center;
  height: auto;
}
.index-function.index-function-mini .function-conent-list-item > img {
  width: 30px;
}
.index-function.index-function-mini .item-conent {
  width: calc(100% - 80px);
}
.index-function.index-function-mini .item-conent .item-conent-name {
  font-size: 18px;
  margin-bottom: 5px;
}
.index-function.index-function-mini .item-conent .item-conent-description {
  font-size: 14px;
  line-height: 20px;
}
.worth {
  padding: 75px 0;
  height: 692px;
  background-image: url(https://qidiantec.com/www/imgs/bg-left.png), url(https://qidiantec.com/www/imgs/bg-right.png);
  background-repeat: no-repeat;
  background-position: 0 0, 100% 100%;
}
.worth-list {
  display: flex;
  flex-wrap: wrap;
  width: 1224px;
  margin: 59px auto auto;
  justify-content: center;
}
.worth-list .item-top {
  left: 51px;
}
.worth-list-item {
  width: 200px;
  height: 231px;
  display: flex;
  flex-direction: column;
  background-image: url(https://qidiantec.com/www/imgs/bg-no.png);
  text-align: center;
  padding: 58px 18px 0;
  margin-right: 4px;
  position: relative;
}
.worth-list-item:hover:not(.no-active) {
  background-image: url(https://qidiantec.com/www/imgs/bg-blue.png);
  color: #fff;
}
.worth-list-item .item-title {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 700;
}
.worth-list-item .item-description {
  color: #666;
  font-size: 15px;
  line-height: 24px;
}
.worth-list-item:hover:not(.no-active) .item-description {
  color: #fff;
}
.worth-list .no-active {
  background-image: url(https://qidiantec.com/www/imgs/bg.png);
  opacity: 0.6;
}
.worth-list .item-bottom {
  top: -54px;
  right: 51px;
}
.worth-mini {
  padding-top: 20px;
  height: auto;
}
.worth.worth-mini .qd-title {
  font-size: 20px;
}
.worth-mini .worth-list {
  margin-top: 20px;
  width: 100%;
}
.worth-mini .worth-list .item-top,
.worth-mini .worth-list .item-bottom {
  left: 0;
  width: 150px;
  height: 185px;
  background-size: contain;
  padding: 0;
  display: flex;
  align-items: center;
  padding-top: 30px;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
}
.worth-mini .worth-list-item .item-title {
  font-size: 18px;
}
.worth-mini .worth-list-item .item-description {
  font-size: 13px;
  padding: 0 10px;
  line-height: 20px;
}
.worth-mini .worth-list .no-active {
  display: none;
}
.features {
  padding-top: 75px;
}
.index-features-work {
  background-image: url(https://qidiantec.com/www/imgs/features-work.png);
}
.index-features-work,
.index-function {
  color: #fff;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.features-tab {
  position: relative;
}
.features-tab-head {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}
.features-tab-cen {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.features-tab-head .tab-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1000px;
  font-size: 18px;
}
.features-tab-head .tab-list .tab-item {
  margin: 5px 10px;
  padding: 8px 14px;
  cursor: pointer;
}
.features-tab-head .tab-list .active,
.features-tab-head .tab-list .tab-item:hover {
  background: #fb9776;
}
.features-tab-img {
  height: 550px;
  margin-right: 20px;
}
.features-tab-img + img {
  margin-right: 60px;
}
.features-tab-conent {
  width: 400px;
  padding: 20px;
  margin-top: 80px;
  height: 292px;
  background-image: url(https://qidiantec.com/www/imgs/features-work-title.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.features-tab-name {
  font-size: 24px;
  font-weight: 700;
  margin-top: 55px;
  margin-bottom: 6px;
}
.features-tab-description {
  height: 125px;
  overflow: hidden;
  font-size: 15px;
}
.is-control .active,
.is-control .tab-item:hover {
  color: #fff;
  background: #4ba0ff !important;
}
.is-control .features-tab-conent {
  color: #fff;
  height: 292px;
  background-image: url(https://qidiantec.com/www/imgs/features-tab-control.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.is-control .features-tab-img {
  margin-left: 60px;
  margin-right: 0;
  box-shadow: 0 0 10px 0 rgba(93, 93, 93, 0.3);
}
.is-control .features-tab-img + img {
  margin-left: 20px;
  margin-right: 0;
}
.index-features-work-mini {
  padding-top: 20px;
}
.index-features-work-mini .qd-title {
  font-size: 20px;
}
.index-features-work-mini .features-tab-head {
  margin-top: 10px;
}
.index-features-work-mini .tab-list {
  width: 100%;
  justify-content: flex-start;
  padding: 0 10px;
}
.index-features-work-mini .tab-list .tab-item {
  font-size: 14px;
  line-height: 26px;
  height: 26px;
  padding: 0 5px;
  margin: 5px;
  margin-top: 0;
}
.index-features-work-mini .features-tab-cen {
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
}
.index-features-work-mini .features-tab-img {
  margin: 0 !important;
  margin-bottom: 5px !important;
}
.index-features-work-mini .features-tab-conent {
  margin-top: 0;
  width: calc(100% - 30px);
  background-size: contain;
  background-position: top;
}
.index-features-work-mini .features-tab-conent .features-tab-name {
  margin-top: 40px;
  font-size: 20px;
}
.index-features-work-mini .features-tab-description {
  font-size: 14px;
}
.slogan {
  height: 230px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.is-index,
.is-serve {
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.is-index {
  background-image: url(https://qidiantec.com/www/imgs/slogan.png);
}
.slogan-title {
  font-size: 36px;
  letter-spacing: 10px;
  color: #fff;
  font-weight: 700;
}
.slogan .el-button {
  color: #815e00;
  font-size: 24px;
  font-weight: 700;
  border-radius: 24px;
  margin-top: 40px;
  border: 0;
  background: linear-gradient(180deg, #ffc780, #fc4);
}
.slogan-mini.slogan {
  height: 100px !important;
}
.slogan-mini.slogan .slogan-title {
  font-size: 18px;
  letter-spacing: 0;
}
.slogan-mini.slogan .el-button {
  margin-top: 5px;
  font-size: 13px;
  height: 30px;
  display: flex;
  align-items: center;
}
.advisory {
  height: 750px;
  padding: 75px 0;
}
.advisory-conent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 35px 0;
}
.advisory-conent .advisory-list {
  width: 40%;
  height: 277px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #404040;
  border-radius: 12px;
}
.advisory-conent .advisory-list-title {
  font-size: 20px;
  margin: 15px;
  font-weight: 700;
}
.advisory-conent .advisory-list-text {
  font-size: 16px;
  font-weight: 500;
}
.advisory-mini.advisory {
  padding-top: 20px;
  height: auto;
  padding-bottom: 0;
}
.advisory-mini.advisory .qd-title {
  font-size: 20px;
}
.advisory-mini.advisory .advisory-conent {
  padding-top: 10px;
}
.advisory-mini.advisory .advisory-list {
  height: auto;
  width: calc(50% - 10px);
}
.advisory-mini.advisory .advisory-list > img.advisory-list-icon {
  width: 60px;
}
.advisory-mini.advisory .advisory-list-title {
  margin: 8px 0;
  font-size: 14px;
}
.advisory-mini.advisory .advisory-list-text {
  font-size: 13px;
  min-height: 70px;
  padding: 0 10px;
}
.background {
  height: 260px;
  background-image: url(https://qidiantec.com/www/imgs/background.png);
  background-size: cover;
  background-position: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.background-title {
  font-size: 36px;
  letter-spacing: 10px;
  color: #fff;
  z-index: 1;
  font-weight: 700;
}
.background.background-mini {
  height: 80px;
}
.background.background-mini .background-title {
  font-size: 16px;
  letter-spacing: 0;
}
.platform {
  height: 1250px;
}
.platform-list-title {
  font-weight: 700;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}
@media only screen and (max-width: 1600px) {
  .platform-list-conent {
    width: 1200px;
  }
}
.platform-list-conent {
  width: 1200px;
  height: 360px;
  margin: auto;
  display: flex;
  justify-content: space-around;
}
.platform-list-conent .platform-item {
  width: 560px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.platform-item.platform-item1 {
  background-image: url(https://qidiantec.com/www/imgs/platform-0-0.png);
}
.platform-item.platform-item2 {
  background-image: url(https://qidiantec.com/www/imgs/platform-0-1.png);
}
.platform-item.platform-item3 {
  background-image: url(https://qidiantec.com/www/imgs/platform-1-0.png);
}
.platform-item.platform-item4 {
  background-image: url(https://qidiantec.com/www/imgs/platform-1-1.png);
}
.platform-list-conent .platform-item-title {
  color: #fff;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 3px;
}
.platform-list-conent .platform-item-text {
  color: #fff;
  text-align: center;
  font-size: 18px;
  width: 360px;
}
.platform.platform-mini {
  height: auto;
}
.platform.platform-mini .platform-list-title {
  padding: 20px;
  font-size: 18px;
}
.platform.platform-mini .platform-list-conent {
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
}
.platform.platform-mini .platform-item {
  width: 100%;
  min-height: 150px;
  height: auto;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
.platform.platform-mini .platform-item-title {
  font-size: 20px;
}
.platform.platform-mini .platform-item-text {
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
}
.slogan-title p {
  font-weight: 500;
  font-size: 18px;
  margin-top: 35px;
  letter-spacing: 3px;
}
.slogan-mini .slogan-title p {
  font-size: 13px;
  margin-top: 4px;
}

.solution-back {
  height: 650px;
  background-image: url(https://qidiantec.com/www/imgs/newsolution.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-top: 72px;
  position: relative;
  text-shadow: rgba(0, 0, 0, 0.3) 2px 2px 10px;
}
.production {
  width: 1160px;
  margin: auto;
  padding: 80px 0 60px;
}
.production-title {
  display: table;
  margin: auto;
  font-size: 36px;
  font-weight: 700;
}
.production-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
}
.production-content-div {
  width: 370px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 30px 0 0;
  margin-top: 20px;
  border: 1px solid #eee;
}
.production-content-div img {
  width: 90px;
  height: 90px;
}
.production-content-div-label {
  font-size: 18px;
  font-weight: 700;
  margin: 15px 0 30px;
}
.production-content-div-text {
  height: 120px;
  color: #999;
  font-size: 14px;
  padding: 20px;
  background: #f2f2f2;
  border-top: 1px solid #eee;
}
.production-content-div:hover .production-content-div-text {
  color: #fff;
  background: #4fadff;
}
.production-mini {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.production-mini .production-title {
  font-size: 20px;
  margin-bottom: 10px;
}
.production-mini .production-content {
  padding-top: 0;
}
.production-mini .production-content-div {
  width: calc(33.33% - 20px);
  margin: 0 10px;
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 10px;
}
.production-mini .production-content-div img {
  width: 30px;
  height: 30px;
}
.production-mini .production-content-div-label {
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
}
.production-mini .production-content-div-text {
  padding: 10px;
  height: 100px;
}
.production-mini .production-content-div-text > span {
  display: block;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* 定义显示的最大行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.details {
  padding: 0 75px;
}
.details-title {
  font-size: 30px;
  text-align: center;
  color: #606266;
  padding-top: 30px;
}
.details-top {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
}
.details-top div:first-child {
  border-left: 4px solid #fcc78d;
  padding-left: 10px;
  color: #fcc78d;
  font-size: 20px;
  line-height: 1.1;
}
.details-center {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
}
.details-center-label {
  border-left: 4px solid #f78b8b;
  padding-left: 10px;
  color: #f78b8b;
  font-size: 20px;
  line-height: 1.1;
}
.details-center-div {
  display: flex;
  flex-direction: column;
  color: #606060;
  font-size: 14px;
  padding: 20px 0 30px;
  border-bottom: 2px dashed #ccc;
}
.details-center-div span {
  margin-top: 2px;
}
.details-bottom {
  margin-top: 30px;
}
.details-bottom-label {
  display: flex;
  align-items: center;
}
.details-bottom-label img {
  width: 80px;
  height: 80px;
  margin-right: 54px;
  border-radius: 50%;
}
.details-bottom-label span {
  color: #11abe8;
  font-size: 20px;
}
.details-bottom-div {
  display: flex;
  font-size: 14px;
  flex-direction: column;
  color: #606060;
}
.details-bottom-div .div-top {
  margin-top: 20px;
}
.details-top-text {
  color: #606060;
  font-size: 14px;
  padding: 20px 0 0;
}
.details-bottom-div .div-center {
  padding-top: 30px;
}
.details-bottom-div .div-center-label {
  border-left: 4px solid #93d9a5;
  padding-left: 10px;
  color: #93d9a5;
  font-size: 20px;
  line-height: 1.1;
}
.details-bottom-div .div-center-div {
  display: flex;
  flex-direction: column;
  color: #606060;
  font-size: 14px;
  padding: 20px 0 0;
}
.details-bottom-div .div-bottom {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
}
.details-bottom-div .div-bottom div:first-child {
  border-left: 4px solid #b7a8ea;
  padding-left: 10px;
  color: #b7a8ea;
  font-size: 20px;
  line-height: 1.1;
}
.details-bottom-div .div-bottom div {
  margin-top: 0;
}
.details-bottom-div .div-bottom-text {
  color: #606060;
  font-size: 14px;
  padding: 20px 0;
}
.details-title-mini {
  font-size: 18px;
}
.details.details-mini {
  padding: 0 15px;
  height: 300px;
  overflow: auto;
}
.back {
  padding: 65px 0 0 !important;
  height: 790px;
  background-image: url(https://qidiantec.com/www/imgs/istration.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.back .istration-title {
  color: #fff;
}
.istration-title {
  display: table;
  margin: auto;
  font-size: 36px;
  font-weight: 700;
}
.istration-content {
  width: 1160px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
}
.back .istration-content-div {
  border: none;
}
.istration-content-div,
.istration-content-div-back {
  display: flex;
  align-items: center;
  justify-content: center;
}
.istration-content-div {
  width: 370px;
  flex-direction: column;
  cursor: pointer;
  margin-top: 20px;
  border: 1px solid #eee;
  background: #fff;
}
.istration-content-div-back {
  width: 100%;
  height: 170px;
  padding: 0 40px;
  background: #eee;
}
.istration-content-div-back div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.istration-content-div-back div span:first-child {
  color: #333;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 700;
}
.istration-content-div-back div span:last-child {
  color: #666;
  height: 70px;
  line-height: 25px;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
}
.istration-content-div-bottom {
  height: 100px;
  width: 100%;
  text-align: center;
}
.istration-content-div-bottom div:first-child {
  color: #f98e6c;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 13px 0;
}
.istration-content-div-bottom div:last-child {
  color: #4fadff;
  width: 108px;
  height: 35px;
  line-height: 33px;
  margin: auto;
  border: 1px solid #4fadff;
}
.istration-content {
  width: 1160px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
}
.istration-content-div-back:hover {
  background: #4fadff;
}
.istration-content-div-back:hover div span {
  color: #fff;
}
.istration {
  padding: 65px 0 100px;
}
.istration-content-div-bottom div:last-child:hover {
  color: #fff;
  background: #4fadff;
}
.istration-mini {
  padding: 20px 0 !important;
  height: auto;
  width: 100%;
}
.istration-mini .istration-title {
  font-size: 20px;
  margin-bottom: 10px;
}
.istration-mini .istration-content {
  padding: 0 6px;
  width: 100%;
}
.istration-mini .istration-content-div {
  margin: 5px 8px;
  width: calc(50% - 16px);
}
.istration-mini .istration-content-div-back {
  padding: 10px;
  height: 100px;
}
.istration-mini .istration-content-div-back div span:first-child {
  font-size: 16px;
  margin-bottom: 5px;
}
.istration-mini .istration-content-div-back div span:last-child {
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  height: auto;
}
.istration-mini .istration-content-div-bottom div:first-child {
  font-size: 13px;
  padding: 0 5px;
  margin: 5px 0;
}
.istration-mini .istration-content-div-bottom div:last-child {
  font-size: 13px;
  width: 60%;
  height: 30px;
  line-height: 30px;
}
.istration-mini .istration-content-div-bottom {
  height: auto;
  padding-bottom: 15px;
}
.culture {
  height: 1370px;
  background-image: url(https://qidiantec.com/www/imgs/bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  position: relative;
}
.culture-center {
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.culture-center-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 36px;
}
.culture-list {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.culture-list-item {
  position: absolute;
  background: #fff;
  height: 288px;
  width: 288px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}
.culture-list-item:hover {
  background: #4fadff;
  color: #fff;
}
.solution h1 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 55px;
}
.culture-list-item h1 {
  font-size: 24px;
  margin-top: 30px;
}
.culture-list-item .culture-text {
  width: 210px;
  height: 80px;
  overflow: hidden;
  margin: 14px auto 20px;
}
.culture-list-item:hover .culture-bottom-text {
  color: #fff;
}
.culture-list-item .culture-bottom-text {
  width: 224px;
  margin: auto;
  border-top: 1px dashed #d8d8d8;
  padding-top: 16px;
  color: #f98e6c;
}
.culture-list-item button:hover {
  opacity: 0.7;
}
.culture-list-item button {
  height: 30px;
  width: 100px;
  background: #fff;
  border: 1px solid #4fadff;
  border-radius: 15px;
  color: #4fadff;
  margin-top: 10px;
  cursor: pointer;
}
.solution-conent-mini {
  width: 100%;
}
.solution-conent-mini .culture {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
}
.solution-conent-mini .culture-center {
  position: static;
  transform: none;
}
.solution-conent-mini .culture-center .culture-center-title {
  font-size: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}
.solution-conent-mini .culture-list {
  position: static;
  transform: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.solution-conent-mini .culture-list .culture-list-item {
  position: static;
  height: 100px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 12px;
}
.solution-conent-mini .culture-list-item h1 {
  font-size: 16px;
  margin-top: 22px;
}
.solution-conent-mini .culture-list-item .culture-text,
.solution-conent-mini .culture-list-item .culture-bottom-text {
  display: none;
}
.solution-conent-mini .culture-list-item button {
  font-size: 12px;
  height: 25px;
  line-height: 25px;
  width: 75%;
}
@media screen and (min-width: 500px) and (max-width: 1000px) {
  .solution-back.product-background-mini{
    height: 400px;
    background-size: contain;
  }
}
</style>
<style>
/deep/ .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
}
.van-tabs--line .van-tabs__wrap {
  height: 74px !important;
}
</style>
