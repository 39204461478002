/**
 * 判断ie浏览器本
 */
export const IEVersion = () => {
  const userAgent = window.navigator.userAgent //取得浏览器的userAgent字符串
  const isIE =
    userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 //判断是否IE<11浏览器
  const isEdge = userAgent.indexOf('Edge') > -1 && !isIE //判断是否IE的Edge浏览器
  const isIE11 =
    userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
  if (isIE) {
    const reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    const fIEVersion = parseFloat(RegExp['$1'])
    if (fIEVersion == 7) {
      return 7
    } else if (fIEVersion == 8) {
      return 8
    } else if (fIEVersion == 9) {
      return 9
    } else if (fIEVersion == 10) {
      return 10
    } else {
      return 6 //IE版本<=7
    }
  } else if (isEdge) {
    return 'edge' //edge
  } else if (isIE11) {
    return 11 //IE11
  } else {
    return -1 //不是ie浏览器
  }
}

/**
 * 下载
 */
export const downloadFile = url => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  const evt = document.createEvent('MouseEvents')
  evt.initEvent('click', false, false)
  link.dispatchEvent(evt)
  document.body.removeChild(link)
}

/**
 * 判断安卓苹果
 */
export const isAndroid = () => {
  const u = navigator.userAgent
  const Android = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
  return Android
}

export const isiOS = () => {
  const u = navigator.userAgent
  const iOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  return iOS
}

/**
 * 判断微信环境
 */
export const isWeixin = () => {
  //判断是否是微信
  const ua = navigator.userAgent.toLowerCase()
  return ua.match(/MicroMessenger/i) == 'micromessenger'
}

/* 手机号 */
export function replacePhone(s) {
  const regu = /^1[34578]\d{9}$/
  return regu.test(s)
}

/**
 * 判断移动端
 */
export function isMoible(UA) {
  return /(Android|webOS|iPhone|iPod|tablet|BlackBerry|Mobile)/i.test(UA)
    ? true
    : false
}
