<template>
  <div :class="{'download-swipes': activeIndex === list.length-1}" class="download-swipe">
    <el-carousel :autoplay="false" indicator-position="none" arrow="always">
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <div class="content">
          <h1>{{ item.title }}</h1>
          <p>{{ item.subtitle }}</p>
          <!-- <img :src="`../../assets/download/mobile/qdt-${index}.png`"> -->
          <img :src="item.img" class="image">
        </div>
        </el-carousel-item>
      </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'QdtSwipe',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    const that = this
    return {
      activeIndex: 0,
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          slideChange() {
            that.activeIndex = this.activeIndex
          }
        }
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss">
.download-swipe {
  min-height: 100vh;
  color: #fff;
  text-align: center;
  background-image: url(@/assets/download/mobile/bg.png);
  background-size: 170% auto;
  background-repeat: no-repeat;
  background-position: center -7em;
  .el-carousel__container {
    height: 100vh;
}
.content{
  height: 100%;
}
.image{
  height: calc(100vh - 100px);
  padding-bottom: 5em;
}
  h1 {
    font-size: 2.2em;
    margin-top: 1em;
    margin-bottom: 0.2em;
    letter-spacing: 0.1em;
  }
  p {
    font-size: 1.5em;
    margin-bottom: 0.4em;
    letter-spacing: 0.1em;
  }
  img {
    width: 90%;
  }
}
.download-swipes {
  height: 50em;
  background-image: url(@/assets/download/mobile/bgs.png);
  background-size: 160% auto;
  background-repeat: no-repeat;
  background-position: center 2em;
  h1 {
    margin-top: 2em;
  }
  img {
    width: 80%;
    margin-top: 1.5em;
  }
}
</style>
