<template>
  <div class="download-app">
    <qdt-swipe :list="list"/>
    <download-button :app-id="1" class="download-button"/>
  </div>
</template>

<script>
import QdtSwipe from '@/components/download/QdtSwipe'
import DownloadButton from '@/components/download/DownloadButton'
import qdt_0 from "@/assets/download/mobile/qdt-0.png"
import qdt_1 from "@/assets/download/mobile/qdt-1.png"
import qdt_2 from "@/assets/download/mobile/qdt-2.png"
import qdt_3 from "@/assets/download/mobile/qdt-3.png"
import qdt_4 from "@/assets/download/mobile/qdt-4.png"
export default {
  layout: 'mobile',
  head() {
    return {
      title: '企点通-建筑管理专家',
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: '企点通下载'
        },
        {
          hid: 'description',
          name: 'description',
          content: '企点通app下载'
        }
      ]
    }
  },
  // eslint-disable-next-line
  name: 'Qdt',
  components: {
    QdtSwipe,
    DownloadButton
  },
  data() {
    return {
      list: [
        {
          title: '工程项目管理',
          subtitle: '显著提高项目利润',
          img: qdt_0
        },
        {
          title: '重新定义移动“办公”',
          subtitle: '内外协同 全新体验',
          img: qdt_1
        },
        {
          title: '建筑规范“标准库”',
          subtitle: '规范资料随手查',
          img: qdt_2
        },
        {
          title: '建筑资源共享平台',
          subtitle: '助力企业轻松走向全国',
          img: qdt_3
        },
        {
          title: '多端协同办公',
          subtitle: '手机、PC我们只想让你更方便',
          img: qdt_4
        }
      ]
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss">
.download-app {
  padding-bottom: 5em;
}
</style>
