<template>
  <section :class="smFlag ? 'servie-mini' : ''" class="service qd-cneter">
    <div data-v-088ecd1f="" class="service-banner">
      <div data-v-088ecd1f="" class="service-banner-content qd-conent">
        <div data-v-088ecd1f="">企点服务</div>
        <div data-v-088ecd1f="">
          使企业管控进入自动化时代、让老板没有时间和空间的边界
        </div>
        <button
          data-v-088ecd1f=""
          type="button"
          class="el-button el-button--primary"
          @click="openSubmit"
        >
          <!----><!----><span>立即预约</span>
        </button>
      </div>
    </div>
    <div data-v-088ecd1f="" class="service-content qd-conent">
      <div data-v-088ecd1f="" class="service-content-process">服务流程</div>
      <div data-v-088ecd1f="" class="service-content-list">
        <span data-v-088ecd1f="" class="conent-three"></span>
        <div data-v-088ecd1f="" class="content-list">
          <div data-v-088ecd1f="" class="list-line">
            <div data-v-088ecd1f="" class="list-line-img">
              <img
                data-v-088ecd1f=""
                data-src="/images/service/service-0.png"
                :src="`${$imgBaseUrl}service-0.png`"
                lazy="loading"
              />
            </div>
            <div data-v-088ecd1f="" class="list-line-conent">
              <img data-v-088ecd1f="" src="/www/imgs/dian.png" />
            </div>
            <div data-v-088ecd1f="" class="list-line-words">
              <div data-v-088ecd1f="" class="list-line-words-title">
                1. 开通服务
              </div>
              <div data-v-088ecd1f="" class="list-line-words-text">
                在线或电话预约咨询，我们收到后会第一时间联系您
              </div>
            </div>
          </div>
          <div data-v-088ecd1f="" class="list-line">
            <div data-v-088ecd1f="" class="list-line-img">
              <img
                data-v-088ecd1f=""
                data-src="/images/service/service-1.png"
                :src="`${$imgBaseUrl}service-1.png`"
                lazy="loading"
              />
            </div>
            <div data-v-088ecd1f="" class="list-line-conent">
              <img data-v-088ecd1f="" :src="`${$imgBaseUrl}dian.png`" />
            </div>
            <div data-v-088ecd1f="" class="list-line-words">
              <div data-v-088ecd1f="" class="list-line-words-title">
                2. 组建团队
              </div>
              <div data-v-088ecd1f="" class="list-line-words-text">
                组建专业服务团队，电话回访或上门对接具体开通流程
              </div>
            </div>
          </div>
          <div data-v-088ecd1f="" class="list-line">
            <div data-v-088ecd1f="" class="list-line-img">
              <img
                data-v-088ecd1f=""
                data-src="/images/service/service-2.png"
                :src="`${$imgBaseUrl}service-2.png`"
                lazy="loading"
              />
            </div>
            <div data-v-088ecd1f="" class="list-line-conent">
              <img data-v-088ecd1f="" :src="`${$imgBaseUrl}dian.png`" />
            </div>
            <div data-v-088ecd1f="" class="list-line-words">
              <div data-v-088ecd1f="" class="list-line-words-title">
                3. 确认开通
              </div>
              <div data-v-088ecd1f="" class="list-line-words-text">
                双方确认服务方案后，系统将开通并可立即使用
              </div>
            </div>
          </div>
          <div data-v-088ecd1f="" class="list-line">
            <div data-v-088ecd1f="" class="list-line-img">
              <img
                data-v-088ecd1f=""
                data-src="/images/service/service-3.png"
                :src="`${$imgBaseUrl}service-3.png`"
                lazy="loading"
              />
            </div>
            <div data-v-088ecd1f="" class="list-line-conent">
              <img data-v-088ecd1f="" :src="`${$imgBaseUrl}dian.png`" />
            </div>
            <div data-v-088ecd1f="" class="list-line-words">
              <div data-v-088ecd1f="" class="list-line-words-title">
                4. 专业培训
              </div>
              <div data-v-088ecd1f="" class="list-line-words-text">
                开通后，我们专业的售后培训团队将全程为您提供帮助和业务培训
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-v-088ecd1f="" class="service-tel">
      <div data-v-088ecd1f="" class="qd-conent service-tel-div">
        <div data-v-088ecd1f="" class="service-tel-div-title">
          了解更多，欢迎拨打热线电话
        </div>
        <div data-v-088ecd1f="" class="service-tel-div-text">
          陈经理：13905605938 &nbsp; 沈经理：13955194773
        </div>
        <button
          data-v-088ecd1f=""
          type="button"
          class="el-button el-button--primary"
          @click="openSubmit"
        >
          <!----><!----><span>立即预约</span>
        </button>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogFormVisible" :width="smFlag ? '80%' : '32%'">
      <template slot="title"> </template>
      <div class="service-form">
        <div data-v-848f0672="" class="service-form-title">
          请填写您的预约信息
        </div>
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item label="" prop="name">
            <el-input v-model="form.name" placeholder="姓名"></el-input>
          </el-form-item>
          <el-form-item label="" prop="phone">
            <el-input v-model="form.phone" placeholder="联系方式"></el-input>
          </el-form-item>
          <el-form-item label="" prop="address">
            <el-input v-model="form.address" placeholder="单位名称"></el-input>
          </el-form-item>
        </el-form>
        <div data-v-848f0672="" class="service-form-make">
          <button
            style="width: 100%"
            type="button"
            class="el-button el-button--primary"
            @click="submit"
          >
            <!----><!----><span>立即预约</span>
          </button>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button> -->
      </div>
    </el-dialog>
  </section>
</template>
<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        name: "",
        phone: "",
        address: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "change" }],
        phone: [
          { required: true, message: "请输入联系方式", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入单位名称", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.$emit("changeTab", 4, "服务支持");
  },
  methods: {
    openSubmit() {
      this.dialogFormVisible = true;
      setTimeout(() => {
        this.form = {
          name: "",
          phone: "",
          address: "",
        };
        this.$refs.form.clearValidate();
      }, 1);
    },
    submit() {
      console.log(1);
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 保存
          const param = {
            mobile: this.form.phone,
            companyName: this.form.address,
            userName: this.form.name,
          };
          this.submitReq(param);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitReq(param) {
      this.$axios
        .post(`/customerAppoint/addCustomerAppoint`, param)
        .then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.$message.success("预约成功");
            this.dialogFormVisible = false
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.service-banner {
  display: flex;
  height: 700px;
  background-image: url(https://qidiantec.com/www/imgs/banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
.service-banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-banner-content div:first-child {
  color: #fff;
  font-size: 60px;
}
.service-banner-content div:nth-child(2) {
  color: #fff;
  margin-top: 20px;
  line-height: 35px;
  font-size: 24px;
  letter-spacing: 5px;
}
.service-banner-content .el-button {
  width: 200px;
  height: 52px;
  margin-top: 65px;
  font-size: 24px;
  letter-spacing: 2px;
  border-radius: 26px;
  box-shadow: 0 0 15px #006feb;
}
.service-content {
  padding: 0 15px;
  padding-top: 15px;
}
.service-content-process {
  font-size: 40px;
  border-left: 4px solid #006feb;
  line-height: 1.1;
  padding-left: 20px;
}
.service-content-list {
  padding: 60px 0 80px;
  position: relative;
}

.service-content-list .content-list {
  display: flex;
  margin: auto;
  height: 500px;
  align-items: center;
  justify-content: center;
}
.service-content-list .content-list .list-line {
  width: 300px;
  margin: 0 20px;
  height: 500px;
  display: flex;
  align-items: center;
}

.service-content-list .content-list .list-line:nth-child(odd) {
  flex-direction: column;
}
.service-content-list .content-list .list-line:nth-child(2n + 2) {
  flex-direction: column-reverse;
}
.service-content-list .content-list .list-line:nth-child(odd) .list-line-img {
  display: flex;
  align-items: flex-end;
}
.service-content-list .content-list .list-line-img {
  width: 100%;
  height: 210px;
}
.service-content-list .content-list .list-line-img img {
  width: 100%;
  border-radius: 6px;
}
.service-content-list .content-list .list-line-conent {
  height: 80px;
  line-height: 89px;
  position: relative;
  z-index: 5;
}
.service-content-list .content-list .list-line-conent img {
  width: 18px;
  height: 18px;
}
.service-content-list .content-list .list-line-words {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}
.service-content-list .content-list .list-line-words-title {
  font-size: 30px;
  color: #404040;
}
.service-content-list .content-list .list-line-words-text {
  font-size: 16px;
  color: #999;
  margin-top: 10px;
}
.service-tel {
  background: #f7f7f7;
}
.service-tel-div {
  height: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.service-tel-div-title {
  color: #666;
  font-size: 36px;
}
.service-tel-div-text {
  color: #006feb;
  font-size: 30px;
  margin-top: 25px;
}
.service-tel-div .el-button {
  width: 200px;
  height: 52px;
  margin-top: 30px;
  font-size: 24px;
  letter-spacing: 2px;
  border-radius: 26px;
  box-shadow: 0 0 15px #006feb;
}
.service-content-list .content-list {
  display: flex;
  margin: auto;
  height: 500px;
  align-items: center;
  justify-content: center;
}
.service-content-list .conent-three {
  position: absolute;
  top: 310px;
  left: 0;
  right: 0;
  border-bottom: 1px solid #e0e0e0;
}
@media only screen and (max-width: 1600px) {
  .qd-conent {
    width: 1400px;
  }
  .service-content-list .content-list {
    height: 420px;
  }
  .service-content-list .conent-three {
    top: 270px;
  }
  .service-content-list .content-list .list-line {
    width: 240px;
  }
}
.service-form-title {
  color: #9a9a9a;
  font-size: 26px;
  text-align: center;
  padding-bottom: 40px;
}
.service-form {
  padding: 0 50px;
  text-align: center;
}
.service-form-make {
  margin-top: 50px;
}
.servie-mini {
  width: 100%;
  height: auto;
}
.servie-mini .service-banner {
  width: 100%;
  height: 300px;
}
.servie-mini .service-banner-content {
  width: 100%;
}
.servie-mini .service-banner-content div {
  font-size: 20px;
  margin-top: 30px;
}
.servie-mini .service-banner-content div:nth-child(2) {
  font-size: 18px;
  letter-spacing: 0;
  width: calc(100% - 40px);
  text-align: center;
  margin-top: 15px;
  line-height: 26px;
}
.servie-mini .service-banner-content .el-button {

  font-size: 15px;
  height: 30px;
  /* line-height: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  margin-top: 15px;
}
.servie-mini .service-content {
  width: 100%;
}
.servie-mini .service-content-process {
  font-size: 20px;
}
.servie-mini .service-content-list {
  padding: 20px 0 20px;
}
.servie-mini .content-list {
  width: 100%;
  height: auto;
  flex-direction: column;
}
.servie-mini .service-content-list .content-list .list-line {
  width: calc(100% - 40px);
  flex-direction: column !important;
  height: auto;
}
.servie-mini .service-content-list .content-list .list-line-img {
  width: 200px;
  height: auto;
}
.servie-mini .list-line-conent,
.servie-mini .conent-three {
  display: none;
}
.servie-mini .service-content-list .content-list .list-line-words-title {
  font-size: 16px;
}
.servie-mini .service-content-list .content-list .list-line-words-text {
  font-size: 14px;
}
.servie-mini .service-tel-div {
  width: 100%;
  height: auto;
  padding: 20px 0;
}
.servie-mini .service-tel-div-title {
  font-size: 16px;
}
.servie-mini .service-tel-div-text {
  font-size: 14px;
  margin-top: 10px;
}
.servie-mini .service-tel-div .el-button {
  margin-top: 20px;
  font-size: 14px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}
.servie-mini .service-form {
  padding: 0;
}
.servie-mini .service-form-title {
  font-size: 20px;
  /* padding: 20px; */
  padding-bottom: 10px;
}
.servie-mini .service-content-list .content-list .list-line-words {
  max-width: 100%;
  width: 100%;
}
</style>
