<template>
  <div>
    <el-row class="main-header">
      <el-col
        :xs="16"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="header-logo"
        data-v-4e1163ad=""
      >
        <img
          :src="`${$imgBaseUrl}logo.png`"
          :style="smFlag ? 'width: 100px' : 'margin-left: 20%'"
          alt="企点通"
          data-v-4e1163ad=""
        />
      </el-col>
      <el-col
        :xs="8"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        class="header-nav"
        :class="smFlag ? 'header-nav-mini' : ''"
        data-v-4e1163ad=""
      >
        <template v-if="smFlag">
          <el-dropdown trigger="click" class="dropdown-box">
            <span class="el-dropdown-link">
              {{ currentPage }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" :style="isLandscape ? dropdown_style : ''">
              <el-dropdown-item @click.native="toPath('/', 1, '首页')"
                >首页</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="toPath('/productPage', 2, '产品')"
                >产品</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="toPath('/solutionPage', 3, '解决方案')"
                >解决方案</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="toPath('/servicePage', 4, '服务支持')"
                >服务支持</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="toPath('/downloadPage', 5, '下载')"
                >下载</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="toPath('/aboutPage', 6, '关于我们')"
                >关于我们</el-dropdown-item
              >
              <el-dropdown-item
                @click.native="toPath('/loginWebPage', 7, '登录网页版')"
                >登录网页版</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-else>
          <a
            href="javascript:void(0)"
            :class="
              navBarIndex == 1 ? 'nuxt-link-exact-active nuxt-link-active' : ''
            "
            @click="toPath('/', 1)"
            >首页</a
          >
          <a
            href="javascript:void(0)"
            :class="
              navBarIndex == 2 ? 'nuxt-link-exact-active nuxt-link-active' : ''
            "
            @click="toPath('/productPage', 2)"
            >产品</a
          >
          <a
            href="javascript:void(0)"
            :class="
              navBarIndex == 3 ? 'nuxt-link-exact-active nuxt-link-active' : ''
            "
            @click="toPath('/solutionPage', 3)"
            >解决方案</a
          >
          <a
            href="javascript:void(0)"
            :class="
              navBarIndex == 4 ? 'nuxt-link-exact-active nuxt-link-active' : ''
            "
            @click="toPath('/servicePage', 4)"
            >服务支持</a
          >
          <a
            href="javascript:void(0)"
            :class="
              navBarIndex == 5 ? 'nuxt-link-exact-active nuxt-link-active' : ''
            "
            @click="toPath('/downloadPage', 5)"
            >下载</a
          >
          <a
            href="javascript:void(0)"
            :class="
              navBarIndex == 6 ? 'nuxt-link-exact-active nuxt-link-active' : ''
            "
            @click="toPath('/aboutPage', 6)"
            >关于我们</a
          >
          <a
            href="javascript:void(0)"
            :class="
              navBarIndex == 7 ? 'nuxt-link-exact-active nuxt-link-active' : ''
            "
            @click="toPath('/loginWebPage', 7)"
            >登录网页版</a
          >
        </template>
      </el-col>
    </el-row>
    <div :class="smFlag ? 'mini-content' : ''">
      <router-view @changeTab="changeTab" />
    </div>
    <footer class="footer" :class="smFlag ? 'mini-footer' : ''">
      <div data-v-70c654b4="">
        <div v-if="smFlag" class="footer-line-box">
          <p><span>服务热线: </span>陈经理 13905605938</p>
          <p><span style="color: #fff">服务热线: </span>沈经理 13955194773</p>
        </div>
        <template v-else>
          服务热线：陈经理 13905605938 &nbsp; 沈经理 13955194773
        </template>
      </div>
      <div data-v-70c654b4="">企业邮箱：qidian@qidiantec.com</div>
      <div data-v-70c654b4="">企点科技有限公司 皖ICP备16019493号-1</div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "homePage",
  props: {},
  data() {
    return {
      pages: [
        "首页",
        "产品",
        "解决方案",
        "服务支持",
        "下载",
        "关于我们",
        "登录网页版",
      ],
      currentPage2: '',
      isLandscape: window.matchMedia("(orientation: landscape)").matches,
      addressBarHeight: 0,
      dropdown_style: {}
    };
  },
  created() {
     // 监听orientation变化
     window.addEventListener("orientationchange", this.handleOrientationChange)
  },
  destroyed() {
    // 移除监听
    window.removeEventListener("orientationchange", this.handleOrientationChange);
  },
  mounted() {
    this.navBarIndex = 1;
    this.currentPage2 = localStorage.getItem('currentPage')
    console.log(localStorage.getItem('currentPage'))
  },
  methods: {
    handleOrientationChange() {
      this.isLandscape = window.matchMedia("(orientation: landscape)").matches;
      this.addressBarHeight = window.innerHeight - document.documentElement.clientHeight;
    },
    toPath(url, index, currentPage) {
      this.currentPage = currentPage;
      this.$router.push(url);
      this.navBarIndex = index;
      localStorage.setItem('currentPage', currentPage)
    },
    changeTab(index, currentPage) {
      this.navBarIndex = index;
      if (currentPage) {
        this.currentPage = currentPage;
        localStorage.setItem('currentPage', currentPage)
      }
    },
  },
  watch: {
    // 监听路由对象中的变化
    '$route': {
      handler(to, from) {
        // 路由发生变化时的处理逻辑
        console.log(to.name, from.name);
        this.currentPage = to.name
      },
      // 设置为深度监听
      deep: true,
      immediate: true
    }
  }
};
</script>
<style scoped>
.main-header {
  display: flex;
  align-items: center;
  height: 74px;
  justify-content: space-around;
  z-index: 10;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  padding: 0 15px;
  z-index: 200;
}
.header-logo {
  display: flex;
  align-items: center;
}
.header-nav a {
  color: #333;
  font-size: 14px;
  text-align: center;
  padding: 20px;
}
.nuxt-link-active,
.nuxt-link-active:hover,
.header-nav a:hover {
  color: #006feb !important;
}
.qd-cneter {
  min-height: calc(100vh - 200px);
}
.footer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #fff;
}
.footer div {
  color: #404040;
  font-size: 14px;
}
.mini-footer {
  flex-direction: column;
  margin-top: 60px;
  padding-bottom: 20px;
  height: auto;
}
.mini-footer div {
  border-bottom: 1px solid #eee;
  padding: 10px 20px;
  width: 100%;
  text-align: center;
  /* padding: 10px; */
  box-sizing: border-box;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-nav-mini {
  text-align: right;
}
.mini-content {
  padding-top: 74px;
}
.footer-line-box {
  display: flex;
  flex-direction: column;
}
.footer-line-box > p:last-child {
  margin-top: 4px;
  margin-bottom: 10px;
}
</style>
