<template>
  <div class="download-button">
    <img
      v-if="appId === 1"
      class="download-button-logo"
      src="@/assets/download/mobile/qdt-logo.png"
    >
    <img
      v-if="appId === 2"
      class="download-button-logo"
      src="@/assets/download/mobile/qdb-logo.png"
    >
    <div v-if="appId === 1" class="download-button-conent">
      <div class="conent-title">企点通</div>
      <div class="conent-subtitle">建筑管理专家</div>
    </div>
    <div v-if="appId === 2" class="download-button-conent">
      <div class="conent-title">企点帮帮</div>
      <div class="conent-subtitle">建筑从业好帮手</div>
    </div>
    <el-button :loading="loading" type="primary" size="mini" round @click="download">立即下载</el-button>
    <qd-cover ref="cover">
      <div class="download-weixin"/>
    </qd-cover>
  </div>
</template>

<script>
import { downloadFile, isAndroid, isiOS, isWeixin } from '@/utils'
import QdCover from '@/components/tools/Cover'
import { downLoadApk } from '@/api/download'
export default {
  name: 'DownloadButton',
  components: {
    QdCover
  },
  props: {
    appId: {
      type: Number,
      default: 1 // 企点通1 企点帮帮 2
    }
  },
  data() {
    return {
      url: '',
      loading: false
    }
  },
  mounted() {
    if (isAndroid() && !isWeixin()) {
      this.downLoadApk()
    }
  },
  methods: {
    download() {
      if (isAndroid()) {
        console.log(this.url)
        // Android
        if (isWeixin()) {
          // 微信
          this.$refs.cover.show()
        } else {
          if (!this.url) {
            alert('下载异常，请稍后再试')
            return false
          }
          downloadFile(this.url)
        }
      } else if (isiOS()) {
        // iOS
        if (this.appId === 1) {
          // 企点通
          location.href = 'https://itunes.apple.com/cn/app/id1132842378'
        } else {
          // 帮帮
          location.href = 'https://itunes.apple.com/cn/app/id1446873551'
        }
      }
    },
    downLoadApk() {
      this.loading = true
      downLoadApk(1, this.appId).then(res => {
        this.url = res.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.download-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5em;
  z-index: 100;
  background: #fff;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0 -2px 2px;
  &-logo {
    width: 4em;
    height: 4em;
    margin-left: 2em;
    margin-right: 0.5em;
  }
  &-conent {
    flex-grow: 1;
    .conent-title {
      font-size: 1em;
    }
    .conent-subtitle {
      font-size: 0.8em;
      color: rgb(102, 102, 102);
    }
  }
  .el-button--primary {
    width: 8em;
    height: 2.5em;
    background: #1b95fe;
    box-shadow: #999 0.1em 0.1em 0.2em;
    margin-right: 3em;
  }
  .download-weixin {
    width: 100%;
    height: 20em;
    background-image: url(@/assets/download/mobile/open.png);
    background-size: 80% auto;
    background-position: right 10px top 10px;
    background-repeat: no-repeat;
  }
}
</style>
