import Vue from "vue";
import Router from "vue-router";
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);
// 引入需要跳转的页面
import home from "@/components/homePage";
import indexPage from "@/components/indexPage";
import productPage from "@/components/productPage";
import solutionPage from "@/components/solutionPage";
import servicePage from "@/components/servicePage";
import downloadPage from "@/components/downloadPage";
import aboutPage from "@/components/aboutPage";
import loginWebPage from "@/components/loginWebPage";
import qdt from "@/components/qdt";
import qdb from "@/components/qdb";
// 路由配置
var router = new Router({
  mode: 'history',
  // 在routes里配置跳转路径已经对应的页面
  routes: [
    {
      path: "/",
      component: home,
      name: "主页",
      redirect: "/index",
      children: [
        { path: "/index", component: indexPage, name: "首页", meta: {
          index: 1
        } },
        { path: "/productPage", component: productPage, name: "产品", meta: {
          index: 2
        }},
        { path: "/solutionPage", component: solutionPage, name: "解决方案",meta: {
          index: 3
        } },
        { path: "/servicePage", component: servicePage, name: "服务支持", meta: {
          index: 4
        } },
        { path: "/downloadPage", component: downloadPage, name: "下载", meta: {
          index: 5
        } },
        { path: "/aboutPage", component: aboutPage, name: "关于我们", meta: {
          index: 6
        } },
        { path: "/loginWebPage", component: loginWebPage, name: "登录网页版", meta: {
          index: 7
        } },
      ],
    },
    { path: "/download/qdt", component: qdt, name: "工程项目管理"},
    { path: "/download/qdb", component: qdb, name: "工程项目管理"}
  ],
});
export default router;
