import service from '@/utils/service'
/**
 * 下载
 */
export function downLoadApk(proType, appId) {
  return service({
    url: '/omp/downLoadApk',
    method: 'post',
    params: {
      proType,
      appId
    }
  })
}
