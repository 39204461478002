<template>
  <div>
    <section
      data-v-6b8bc87e=""
      class="home qd-cneter"
      :class="smFlag ? 'qd-cneter-mini' : ''"
    >
      <div data-v-2889d41a="" data-v-6b8bc87e="" class="banner">
        <template v-if="smFlag">
          <div class="mini-banner-text">
            <img
              data-v-2889d41a=""
              :src="`${$imgBaseUrl}qdt-logo.png`"
              alt="Banner Image"
              class="banner-img-mini"
            />
            <div class="mini-banner-text-text">
              <h2
                data-v-2889d41a=""
                class="banner-h2 banner-h-mini"
              >
                企点通平台全新上线
              </h2>
              <h1
                data-v-2889d41a=""
                class="banner-h1 banner-h-mini2"
                style="display: flex; justify-content: center; align-items: center"
              >
                <!-- <hr
                  data-v-2889d41a=""
                  class="center-line"
                  style="
                    width: 65px;
                    background-color: rgb(255, 255, 255);
                    height: 3px;
                    border: none;
                  "
                /> -->
                <span
                  data-v-2889d41a=""
                  style="margin-left: 10px; letter-spacing: 6px; font-weight: bold"
                ></span>
                实现建筑施工企业数字化转型升级
              </h1>
            </div>
          </div>
        </template>
        <template v-else>
          <img
            data-v-2889d41a=""
            :src="`${$imgBaseUrl}qdt-logo.png`"
            alt="Banner Image"
            class="banner-img"
            :class="smFlag ? 'banner-img-mini' : ''"
          />
          <h2
            data-v-2889d41a=""
            class="banner-h2"
            :class="smFlag ? 'banner-h-mini' : ''"
          >
            企点通平台全新上线
          </h2>
          <h1
            data-v-2889d41a=""
            class="banner-h1"
            style="display: flex; justify-content: center; align-items: center"
            :class="smFlag ? 'banner-h-mini2' : ''"
          >
            <hr
              v-if="!smFlag"
              data-v-2889d41a=""
              class="center-line"
              style="
                width: 65px;
                background-color: rgb(255, 255, 255);
                height: 3px;
                border: none;
              "
            />
            <span
              data-v-2889d41a=""
              style="margin-left: 10px; letter-spacing: 6px; font-weight: bold"
            ></span>
            实现建筑施工企业数字化转型升级
          </h1>
        </template>
        <div data-v-2889d41a="" class="box" :class="smFlag ? 'mini-box' : ''">
          <p data-v-2889d41a="">
            <button
              data-v-2889d41a=""
              type="primary"
              class="banner-otherbutton"
            >
              管理自动化
            </button>
            <button
              data-v-2889d41a=""
              type="primary"
              class="banner-otherbutton"
            >
              运营数字化
            </button>
          </p>
          <p data-v-2889d41a="" class="btns">
            <button
              data-v-2889d41a=""
              type="primary"
              round=""
              class="banner-buttons"
              @click="toPath2('/downloadPage')"
            >
              立即体验
            </button>
          </p>
          <p data-v-2889d41a="">
            <button
              data-v-2889d41a=""
              type="primary"
              class="banner-otherbutton"
            >
              业务在线化
            </button>
            <button
              data-v-2889d41a=""
              type="primary"
              class="banner-otherbutton"
            >
              风控智能化
            </button>
          </p>
        </div>
      </div>
      <div
        data-v-7185fa81=""
        data-v-6b8bc87e=""
        class="product-list"
        :class="smFlag ? 'product-list-mini' : ''"
      >
        <a
          data-v-7185fa81=""
          href="javascript:void(0)"
          class="product-list-link img1"
          @click="toPath('/productPage?index=0#a1')"
        >
          <div data-v-7185fa81="" class="product-box">
            <span data-v-7185fa81="" class="product-name">企点通</span>
            <span data-v-7185fa81="" class="product-title">赋能建企一点通</span>
          </div>
        </a>
        <a
          data-v-7185fa81=""
          href="javascript:void(0)"
          class="product-list-link img2"
          @click="toPath('/productPage?index=1#a2')"
        >
          <div data-v-7185fa81="" class="product-box">
            <span data-v-7185fa81="" class="product-name">企点帮帮</span>
            <span data-v-7185fa81="" class="product-title">建筑从业好帮手</span>
          </div></a
        >
        <a
          data-v-7185fa81=""
          href="javascript:void(0)"
          class="product-list-link img3"
          @click="toPath('/productPage?index=2#a3')"
        >
          <div data-v-7185fa81="" class="product-box">
            <span data-v-7185fa81="" class="product-name">企点市场</span>
            <span data-v-7185fa81="" class="product-title">建筑产业互联网</span>
          </div>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "indexPage",
  props: {},
  data() {
    return {};
  },
  methods: {
    toPath(url) {
      this.navBarIndex = 2;
      this.$emit("changeTab", 2, "产品");
      this.$router.push(url);
    },
    toPath2(url) {
      this.navBarIndex = 5;
      this.$emit("changeTab", 5, "下载");
      this.$router.push(url);
    }
  },
};
</script>
<style scoped>
.banner {
  position: relative;
  background-image: url(https://qidiantec.com/www/imgs/newbannerpro.jpg);
  background-size: cover;
  background-position: bottom;
  color: #fff;
  height: 720px;
}
.banner-h1,
.banner-h2 {
  position: absolute;
  left: 15%;
  margin: 0;
  text-shadow: rgba(0, 0, 0, 0.3) 2px 2px 10px;
}
.banner-h2 {
  top: 146px;
  font-size: 60px;
}
.banner-h1 {
  top: 246px;
  font-size: 31px;
  font-weight: 400;
}
.banner .box {
  width: 352px;
  position: absolute;
  top: 47%;
  left: 19%;
  flex-direction: column;
  padding: 20px 15px;
  border-radius: 24px;
  background-color: rgba(21, 112, 223, 0.3);
}

.banner .box,
.banner .box p {
  display: flex;
  justify-content: space-between;
}
.banner .box .banner-otherbutton,
.banner .box .banner-otherbutton:active {
  background: linear-gradient(180deg, #fff, #fff);
}
.banner .box .banner-otherbutton {
  width: 45%;
  border-radius: 10px;
  border: none;
  color: #ff9c08;
  font-size: 23px;
  cursor: pointer;
  padding: 8px;
  border-radius: 6px;
  text-shadow: 0 1px 1px rgba(14, 26, 34, 0.33);
}
.product-list {
  display: flex;
  padding: 6px;
}
.product-list-link {
  height: 285px;
  color: #fff;
  font-size: 36px;
  text-shadow: rgba(0, 0, 0, 0.3) 2px 2px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin-right: 6px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
}
.product-list-link .product-box {
  position: absolute;
  width: 50%;
  padding: 30px;
  background-color: rgba(0, 161, 254, 0.2);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-list-link .product-name {
  z-index: 10;
  font-size: 36px;
}

.product-list-link span {
  z-index: 10;
}
.product-list-link .product-title {
  z-index: 10;
  font-size: 20px;
  margin-top: 8px;
  color: #fffb00;
}
.banner .box .banner-buttons {
  width: 180px;
  padding: 8px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  text-shadow: 0 1px 1px rgba(14, 26, 34, 0.33);
  background: linear-gradient(180deg, #0078db, #0078db);
}
.banner .box .btns {
  justify-content: center !important;
  margin: 20px 0;
}
.banner-img {
  position: absolute;
  top: 155px;
  left: 4%;
  width: 130px;
  height: 130px;
}
.banner-img-mini {
  top: 10%;
  left: 10%;
  width: 70px;
  height: 70px;
}
.product-list-link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.img1 {
  background-image: url(https://qidiantec.com/www/imgs/qd-through.png);
}
.img2 {
  background-image: url(https://qidiantec.com/www/imgs/qd-gang.png);
}
.img3 {
  background-image: url(https://qidiantec.com/www/imgs/qd-trading.png);
}
.qd-cneter-mini {
  width: 100%;
}
.banner-h-mini {
  font-size: 20px;
  left: 120px;
  top: 12%;
}
.banner-h-mini2 {
  top: 20%;
  font-size: 16px;
  left: 29%;
}
.box.mini-box {
  height: 150px;
  top: 35%;
  left: calc(50% - 120px);
  width: 240px;
}
.box.mini-box .banner-otherbutton {
  font-size: 13px;
  height: 34px;
}
.box.mini-box .banner-buttons {
  width: 90%;
  font-size: 14px;
}
.box.mini-box .btns {
  margin: 10px 0;
}
.qd-cneter-mini .banner {
  height: 420px;
}
.product-list-mini {
  display: flex;
  flex-direction: column;
}
.product-list-mini .product-list-link {
  margin: 0;
  margin-bottom: 6px;
}
.product-list-mini .product-box {
  width: 230px;
}
.product-list-mini .product-name {
  font-size: 24px;
}
.mini-banner-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 40px; */
  padding-top: 40px;
}
.mini-banner-text .mini-banner-text-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mini-banner-text .mini-banner-text-text .banner-h-mini {
  position: static;
}
.mini-banner-text .mini-banner-text-text .banner-h-mini2 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  padding: 0;
  margin: 0;
}
</style>
