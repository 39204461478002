<template>
  <div class="download-app">
    <qdb-swipe :list="list"/>
    <download-button :app-id="2" class="download-button"/>
  </div>
</template>

<script>
import QdbSwipe from '@/components/download/QdbSwipe'
import DownloadButton from '@/components/download/DownloadButton'
import qdb_0 from "@/assets/download/mobile/qdb-0.png"
import qdb_1 from "@/assets/download/mobile/qdb-1.png"
import qdb_2 from "@/assets/download/mobile/qdb-2.png"
import qdb_3 from "@/assets/download/mobile/qdb-3.png"
import qdb_4 from "@/assets/download/mobile/qdb-4.png"
export default {
  layout: 'mobile',
  head() {
    return {
      title: '企点帮帮-建筑从业好帮手',
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: '企点帮帮下载'
        },
        {
          hid: 'description',
          name: 'description',
          content: '企点帮帮app下载'
        }
      ]
    }
  },
  // eslint-disable-next-line
  name: 'Qdb',
  components: {
    QdbSwipe,
    DownloadButton
  },
  data() {
    return {
      list: [
        {
          title: '业务助手',
          subtitle: '开启无纸化业务模式',
          img: qdb_0
        },
        {
          title: '办公协同',
          subtitle: '和业务伙伴一起办公',
          img: qdb_1
        },
        {
          title: '业务文件箱',
          subtitle: '业务文件集中存储，随时查阅',
          img: qdb_2
        },
        {
          title: '建筑资源共享平台',
          subtitle: '助力企业轻松走向全国',
          img: qdb_3
        },
        {
          title: '中国建设规范标准库',
          subtitle: '海量资料免费查',
          img: qdb_4
        }
      ]
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.download-app {
  padding-bottom: 5em;
}
</style>
